import { Button } from 'antd';
import React from 'react';
import colors from '../colors';

interface Props {
  id: string;
  selected: boolean;
  disabled: boolean;
  onClick: () => void;
  children: React.ReactNode;
}

export const ButtonSelectable = ({ id, selected, disabled, onClick, children }) => {
  const buttonStyle = selected ? { borderColor: colors.timeline.blue, marginRight: '1px' } : {};
  return (
    <Button id={id} disabled={disabled} onClick={onClick} style={buttonStyle}>
      {children}
    </Button>
  );
};
