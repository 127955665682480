import { all, call, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import userActions from '../user/actions';
import { selectCompanyIDSaga } from '../../common/saga/selectors';
import PusherClient from '../../components/shared/pusherClient';
import { createPusherReduxAction, PusherActionType } from './PusherActions';
import { eventChannel } from 'redux-saga';
import { getCompanyID } from '../selectors';
import { scheduleActions } from '../../pages/data/organization/crew-scheduling-v3/redux-saga/actions';

function createPusherChannel(companyID: string, onReconnectedCallback: () => void) {
  return eventChannel(emitter => {
    const pusherInstance = PusherClient.getInstance(onReconnectedCallback);
    const realtimeBroadcast = pusherInstance.subscribe(`${companyID}`);

    const eventHandler = (action: PusherActionType) => (data: any) => {
      emitter({ action, data });
    };

    for (const action of Object.values(PusherActionType)) {
      realtimeBroadcast.bind(action, eventHandler(action));
    }

    return () => {
      realtimeBroadcast.unsubscribe();
    };
  });
}

function* watchCompanyLoaded() {
  const { isCompanyLoaded } = yield select(({ user }) => user);

  if (!isCompanyLoaded) {
    yield take(userActions.SET_COMPANY);
  }

  const companyID = yield* selectCompanyIDSaga();

  function* onReconnectedCallback() {
    yield put(scheduleActions.reFetchAllEntries());
  }

  const pusherChannel = yield call(createPusherChannel, companyID, onReconnectedCallback);

  while (true) {
    const payload = yield take(pusherChannel);
    const action = createPusherReduxAction(payload.action, payload.data);
    yield put(action);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(userActions.SET_COMPANY, function*() {
      yield fork(watchCompanyLoaded);
    }),
    takeEvery(userActions.LOGOUT, function*() {
      const pusher = PusherClient.getInstance();
      const companyID = yield select(getCompanyID);
      pusher.unsubscribe(companyID);
    }),
  ]);
}
