import { routerMiddleware } from 'connected-react-router';
import { createHashHistory } from 'history';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/en-gb';
import * as Sentry from '@sentry/react';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import reducers from 'redux/reducers';
import sagas from 'redux/sagas';
import Router from 'router';
import { setDefaultConfig } from 'antd-mobile';
import enUS from 'antd-mobile/es/locales/en-US';
import './global.scss';
import * as serviceWorker from './serviceWorker';
import FeatureFlagsProvider from './utils/feature-flags/FeatureFlagsProvider';
import { ENVIRONMENT, getEnableServiceWorker } from './config';

setDefaultConfig({
  locale: enUS,
});

const history = createHashHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, sagaMiddleware, routeMiddleware];

// Allows us to use Redux DevTools
const composeEnhancers =
  process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const store = createStore(reducers(history), composeEnhancers(applyMiddleware(...middlewares)));
sagaMiddleware.run(sagas);

if (ENVIRONMENT === 'prod') {
  Sentry.init({
    dsn: 'https://169899bf7573461c8c70f8196cd748e3@o264107.ingest.sentry.io/1524393',
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", /^https:\/\/dashboard\.airtrail\.ca\//],
    integrations: [Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    })],
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 0.0,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'en-gb'}>
      <FeatureFlagsProvider>
        <Router history={history} />
      </FeatureFlagsProvider>
    </LocalizationProvider>
  </Provider>,
  document.getElementById('root'),
);

if (getEnableServiceWorker()) {
  serviceWorker.register();
} else {
  serviceWorker.unregister();
}

export { store, history };
