import React, { useEffect, useState } from 'react';
import { Button, Cascader, Form, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CascaderOption, CascaderOptions, SingleValueType } from '../types';
import { CommonInitState } from '../../../../../../redux/common/reducers';
import HasAccess from '../../../../../permissions/guard-access-component';
import Guard from '../../../../../permissions/crew-schedule-v2-guard';
import LocalCache from '../../../../../local-storage/local-storage';
import { AircraftSelectorType } from '../../../../../../redux/common/types';
import { getIsDrawerVisible, getSelectedAircraftIdentifiers } from '../../redux-saga/selectors';
import { getCommon, getCompany } from '../../../../../../redux/selectors';
import { scheduleActions } from '../../redux-saga/actions';
import { IDs } from '../../../../../../constants';
import { CrewScheduleFilter } from '../../../../../../common/constants/filters';
import "../CascaderSelector.css"

const { Option } = Select;

interface Props {
  aircraftTimelineVisibility: boolean;
}

export const AircraftTypeSelector: React.FC<Props> = ({ aircraftTimelineVisibility }) => {
  const dispatch = useDispatch();
  const guard = new Guard('crewScheduleV2');
  const localCache = new LocalCache('crewSchedulingV2');

  const {
    isAircraftListLoaded,
    commonDesignatorsByCertificationType,
    aircraftList,
    aircraftIdentifiers,
    aircraftBases,
    aircraftSelectorType,
  } = useSelector(getCommon) as CommonInitState;
  const isDrawerVisible = useSelector(getIsDrawerVisible)
  const selectedIdentifiers = useSelector(getSelectedAircraftIdentifiers);

  const [selectedAircraftTypes, setSelectedAircraftTypes] = useState(
    localCache.getCached('selectedAircraftTypes', []),
  );

  const [selectedAircraftBases, setSelectedAircraftBases] = useState(
    localCache.getCached('selectedAircraftBases', []),
  );

  const [selectedTailsOpts, setSelectedTailsOpts] = useState([]);
  const [unselectedTailsOpts, setUnselectedTailsOpts] = useState([]);

  useEffect(() => {
    // Update selected and unselected options when allOptions or selectedValues changes
    const newSelectedPilots = aircraftIdentifiers.filter(t => selectedIdentifiers.includes(t));
    const newUnselectedPilots = aircraftIdentifiers.filter(t => !selectedIdentifiers.includes(t));

    setSelectedTailsOpts(newSelectedPilots);
    setUnselectedTailsOpts(newUnselectedPilots);
  }, [aircraftIdentifiers, selectedIdentifiers]);

  const company = useSelector(getCompany);
  const companySettings = company.Settings;

  const buildOptions = (): CascaderOptions[] => {
    if (!isAircraftListLoaded) {
      return [];
    }

    const options: CascaderOptions[] = [];

    for (const [certType, commonDesignators] of Object.entries(
      commonDesignatorsByCertificationType,
    )) {
      const children: CascaderOption[] = commonDesignators.map(commonDesignator => ({
        label: commonDesignator,
        value: commonDesignator,
      }));
      options.push({
        label: certType,
        value: certType,
        children,
      });
    }
    return options;
  };

  const onSelectAircraftType = (aircraftTypes: SingleValueType[]) => {
    const newTypes = new Set(
      aircraftTypes.map(c => {
        if (c.length === 1) {
          // Cert Type
          return c[0];
        } else {
          // Cert Type + Common Designator
          return `${c[0]}-${c[1]}`;
        }
      }),
    );

    const selected = new Set<string>(selectedIdentifiers);

    for (const aircraft of aircraftList) {
      let hasMatchingType = false;

      if (newTypes.has(aircraft.CertificationType)) {
        hasMatchingType = true;
      }

      if (newTypes.has(`${aircraft.CertificationType}-${aircraft.CommonDesignator}`)) {
        hasMatchingType = true;
      }

      if (hasMatchingType) {
        if (aircraftSelectorType === AircraftSelectorType.Registration) {
          selected.add(aircraft.Registration);
        } else {
          selected.add(aircraft.TailNumber);
        }
      } else {
        let hasAnySelectedType = false;
        if (
          newTypes.has(aircraft.CertificationType) ||
          newTypes.has(`${aircraft.CertificationType}-${aircraft.CommonDesignator}`)
        ) {
          hasAnySelectedType = true;
        }

        if (!hasAnySelectedType) {
          if (aircraftSelectorType === AircraftSelectorType.Registration) {
            selected.delete(aircraft.Registration);
          } else {
            selected.delete(aircraft.TailNumber);
          }
        }
      }
    }

    onSelectIdentifiers([...selected]);
    selectAircraftTypes(aircraftTypes);
    selectAircraftBases([]);
  };

  const selectAircraftTypes = (aircraftTypes: SingleValueType[]) => {
    setSelectedAircraftTypes(aircraftTypes);
    localCache.setCached('selectedAircraftTypes', aircraftTypes);
  };

  const onSelectIdentifiers = (identifiersArray: string[]) => {
    dispatch(scheduleActions.setSelectedAircraftIdentifiers(identifiersArray));
    selectAircraftTypes([]);
    localCache.setCached('selectedAircraftIdentifiers', identifiersArray || [])
  };

  const selectAircraftBases = (baseAirportArray: string[]) => {
    setSelectedAircraftBases(baseAirportArray);
    localCache.setCached('selectedAircraftBases', baseAirportArray);
  };

  const onSelectAircraftBase = (baseAirportArray: string[]) => {
    selectAircraftBases(baseAirportArray);

    if (baseAirportArray.length === 0) {
      onSelectIdentifiers([]);
      selectAircraftTypes([]);
      return;
    }

    const filteredAircraftIdentifiers = aircraftList
      .filter(aircraft => {
        return baseAirportArray.includes(aircraft.BaseAirportCode);
      })
      .map(aircraft => {
        if (aircraftSelectorType === AircraftSelectorType.Registration) {
          return aircraft.Registration;
        }
        return aircraft.TailNumber;
      });

    onSelectIdentifiers(filteredAircraftIdentifiers);
    selectAircraftTypes([]);
  };

  if (!aircraftTimelineVisibility) {
    return null;
  }

  return (
    <HasAccess access={guard.canAccessFilter(CrewScheduleFilter.RESOURCE_TYPE)}>
      <Form.Item
        label={companySettings?.CompanyFieldNameMappings?.CrewScheduleAircraft || 'Aircraft'}
        style={{ marginRight: !isDrawerVisible ? '20px' : '', flexGrow: 1, flexBasis: 0 }}
      >
        <Select
          id={IDs.filters.aircraftSelector}
          filterOption={(input, option) => {
            if (Array.isArray(option.children)) {
              return (
                option.children
                  .join('')
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) !== -1
              );
            }
            return (option.children as string).toLowerCase().indexOf(input.toLowerCase()) !== -1;
          }}
          mode="multiple"
          onChange={onSelectIdentifiers}
          maxTagCount="responsive"
          placeholder={`Select ${companySettings?.CompanyFieldNameMappings?.CrewScheduleAircraft ||
            'Aircraft'}`}
          allowClear
          value={selectedIdentifiers}
          listHeight={320}
          dropdownRender={menu => (
            <div>
              <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <Button
                  onClick={() => {
                    onSelectIdentifiers(aircraftIdentifiers);
                  }}
                >
                  Select all
                </Button>
              </div>
              {menu}
            </div>
          )}
        >
          {[...selectedTailsOpts, ...unselectedTailsOpts].map(t => (
            <Option key={t} value={t}>
              {t}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label={`${companySettings?.CompanyFieldNameMappings?.CrewScheduleAircraft ||
          'Aircraft'} Type`}
        style={{ marginRight: !isDrawerVisible ? '20px' : '', flexGrow: 1, flexBasis: 0 }}
      >
        <Cascader
          id={IDs.filters.aircraftTypeSelector}
          options={buildOptions() as any[]}
          expandTrigger="hover"
          showSearch
          maxTagCount="responsive"
          placeholder="Select Aircraft Type"
          multiple
          value={selectedAircraftTypes}
          onChange={onSelectAircraftType}
          popupClassName={buildOptions().length >= 10 ? "custom-cascader-dropdown" : "custom-cascader-dropdown-auto-height" }
        />
      </Form.Item>

      <HasAccess access={guard.canAccessFilter(CrewScheduleFilter.RESOURCE_BASE)}>
        <Form.Item
          label={`${companySettings?.CompanyFieldNameMappings?.CrewScheduleAircraft ||
            'Aircraft'} Base`}
          style={{ marginRight: !isDrawerVisible ? '20px' : '', flexGrow: 1, flexBasis: 0 }}
        >
          <Select
            filterOption={(input, option) => {
              if (Array.isArray(option.children)) {
                return (
                  option.children
                    .join('')
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) !== -1
                );
              }
              return (option.children as string).toLowerCase().indexOf(input.toLowerCase()) !== -1;
            }}
            mode="multiple"
            onChange={baseAirportArray => {
              onSelectAircraftBase(baseAirportArray);
            }}
            maxTagCount="responsive"
            allowClear
            placeholder={`Select ${companySettings?.CompanyFieldNameMappings
              ?.CrewScheduleAircraft || 'Aircraft'} Base`}
            value={selectedAircraftBases}
            listHeight={320}
            dropdownRender={menu => (
              <div>
                <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                  <Button
                    onClick={() => {
                      onSelectAircraftBase(aircraftBases);
                    }}
                  >
                    Select all
                  </Button>
                </div>
                {menu}
              </div>
            )}
          >
            {aircraftBases &&
              aircraftBases.map(airport => (
                <Option key={airport} value={airport}>
                  {airport}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </HasAccess>
    </HasAccess>
  );
};
