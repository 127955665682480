import { GenericAction } from '../../../common/types/redux/actions';
import { TrainingItemOverviewInitialState } from './reducers';

const actions = {
  SET_STATE: 'pages/training-items-overview/SET_STATE',
};

export interface SetStateAction extends GenericAction {
  payload: Partial<TrainingItemOverviewInitialState>;
}

export const setState = (payload: Partial<TrainingItemOverviewInitialState>): SetStateAction => ({
  type: actions.SET_STATE,
  payload,
});

export default actions;
