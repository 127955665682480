import 'rc-drawer/assets/index.css';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LogoutOutlined, MenuOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Row } from 'antd';
import MenuLeft from './MenuLeft';
import MobileDropdownMenu from './MenuDropdown/MenuDropdown.tsx';
import TZTagUTC from '../../../pages/settings/timezone/TZTagUtc';
import TimezoneSetting from '../../../pages/settings/timezone/timezoneSetting';

const mapStateToProps = ({ settings, user }) => ({
  isMobileMenuOpen: settings.isMobileMenuOpen,
  isMobileProfileMenuOpen: settings.isMobileProfileMenuOpen,
  isMobileView: settings.isMobileView,
  isHideMobileMenu: settings.isHideMobileMenu,
  isJustLoggedIn: settings.isJustLoggedIn,
  user,
});

@withRouter
@connect(mapStateToProps)
class AppMenu extends React.Component {

  componentDidMount() {
    const { dispatch, isJustLoggedIn } = this.props;

    // needed to allow scrolling right after logging in
    if (isJustLoggedIn) {
      document.querySelector('#root').setAttribute('style', '');
    }

    // Reset the flag after the initial render
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isJustLoggedIn',
        value: false,
      },
    });
  }

  toggleOpen = () => {
    const { dispatch, isMobileMenuOpen } = this.props;
    document
      .querySelector('#root')
      .setAttribute(
        'style',
        !isMobileMenuOpen ? 'overflow: hidden; width: 100%; height: 99%;' : '',
      );
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMobileMenuOpen',
        value: !isMobileMenuOpen,
      },
    });
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMobileProfileMenuOpen',
        value: false,
      },
    });
  };

  handleLogout = () => {
    const { dispatch } = this.props;
    dispatch({
      type: 'user/LOGOUT',
    });
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMobileMenuOpen',
        value: false,
      },
    });
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMobileProfileMenuOpen',
        value: false,
      },
    });
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isJustLoggedIn',
        value: true,
      },
    });
  };

  toggleProfileOpen = () => {
    const { dispatch, isMobileProfileMenuOpen } = this.props;
    document
      .querySelector('#root')
      .setAttribute(
        'style',
        !isMobileProfileMenuOpen ? 'overflow: hidden; width: 100%; height: 99%;' : '',
      );
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMobileProfileMenuOpen',
        value: !isMobileProfileMenuOpen,
      },
    });
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMobileMenuOpen',
        value: false,
      },
    });
  };

  render() {
    const {
      isMobileMenuOpen,
      isMobileProfileMenuOpen,
      isMobileView,
      isHideMobileMenu,
      user,
    } = this.props;
    const getInitials = companyName => {
      if (companyName && companyName.length > 0) {
        return companyName
          .split(' ')
          .map(word => word[0])
          .join('');
      }
      return '';
    };

    const BootstrappedMenu = () => {
      if (isMobileView && !isHideMobileMenu) {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              position: 'sticky',
              top: '0',
              zIndex: '1000',
              width: '100%',
              backgroundColor: '#001529',
              borderBottom: '1px solid #ddd',
            }}
          >
            <button
              type="button"
              onClick={this.toggleOpen}
              style={{
                padding: '10px',
                fontSize: '16px',
                backgroundColor: 'transparent',
                border: 'none',
                color: '#FFFFFFA6',
              }}
            >
              <MenuOutlined size={30} />
              <span style={{ marginLeft: '5px' }}>Menu</span>
            </button>
            <button
              type="button"
              onClick={this.toggleProfileOpen}
              style={{
                padding: '10px',
                fontSize: '16px',
                backgroundColor: 'transparent',
                border: 'none',
                color: '#FFFFFFA6',
              }}
            >
              <Avatar
                style={{
                  backgroundColor: '#0e306b',
                }}
                size={25}
              >
                {getInitials(user.name)}
              </Avatar>
              <TZTagUTC isMenuCollapsed />
            </button>
            {isMobileProfileMenuOpen && (
              <div
                style={{
                  position: 'absolute',
                  top: '100%',
                  left: '0',
                  width: '100%',
                  background: 'rgba(0, 0, 0, .85)',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                  zIndex: 1000,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    background: 'rgba(0, 0, 0, .85)',

                    alignItems: 'center',
                  }}
                >
                  <Row>
                    <Col span={24}>
                      <p
                        style={{
                          color: '#FFFFFFA6',
                          fontSize: '14px',
                          marginBottom: '2px',
                          marginTop: '2px',
                          textAlign: 'center',
                        }}
                      >
                        {user.name}
                      </p>
                      <p style={{ color: 'gray', fontSize: '11px', textAlign: 'center' }}>
                        {user.email}
                      </p>
                    </Col>
                  </Row>
                  <Row
                    justify="center"
                    align="middle"
                    style={{ width: '210px', alignItems: 'center' }}
                  >
                    <Col span={24}>
                      <TimezoneSetting
                        config={{
                          editable: true,
                          labelOnly: false,
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Button
                      danger
                      type="primary"
                      icon={<LogoutOutlined />}
                      size="large"
                      onClick={this.handleLogout}
                      style={{
                        backgroundColor: 'none',
                        margin: '10px auto',
                        display: 'block',
                        width: '100%',
                      }}
                    >
                      Logout
                    </Button>
                  </Row>
                </div>
              </div>
            )}
            {isMobileMenuOpen && (
              <div
                style={{
                  position: 'absolute',
                  width: '100%',
                  top: '100%',
                  left: '0',
                  backgroundColor: '#fff',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                  zIndex: 1000,
                }}
              >
                <MobileDropdownMenu closeOnSelect={this.toggleOpen} />
              </div>
            )}
          </div>
        );
      }
      return <MenuLeft />;
    };

    return BootstrappedMenu();
  }
}

export default AppMenu;
