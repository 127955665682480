import React from 'react';
import type { MenuProps } from 'antd';
import { Button, Dropdown } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getCommon, getCompanySettings, getUserRole } from '../../../../redux/selectors';
import { FeatureFlag } from '../../../../utils/feature-flags/FeatureFlagsProvider';
import { TimelineType } from './types/timeline';
import { getIsDrawerVisible, getIsFilterDrawerPinned, getVisibleTimelines } from './redux-saga/selectors';
import Guard from '../../../permissions/crew-schedule-v2-guard';
import { setModalOpen } from './components/GroupSchedule/redux-saga/actions';
import { scheduleActions } from './redux-saga/actions';
import { bulkModalEntryActions } from './components/Modals/BulkScheduleModal/redux-saga/actions';
import MODAL_MODE from '../../common/constants';
import {
  setCurrentDutyScheduleEntryAction,
  setCurrentMaintenanceEntryAction,
  setCurrentScheduleEntryAction,
} from '../../../../redux/pages/crew-scheduling-v3/actions';
import {
  openCrewScheduleModalAction,
} from '../../../../redux/pages/crew-scheduling-v3/modals/ScheduleEntryModal/actions';
import {
  openDutyScheduleModalAction,
} from '../../../../redux/pages/crew-scheduling-v3/modals/DutyScheduleEntryModal/actions';
import dutyTimeModalActions, {
  setCurrentDutyTimeEntry,
} from '../../../../redux/pages/crew-scheduling-v3/modals/DutyTimeEntryModal/actions';
import { DutyTimeEntry } from '../../../../redux/pages/crew-scheduling-v3/scheduleEntries/types/DutyTimeEntry';
import {
  openMaintenanceScheduleModalAction,
} from '../../../../redux/pages/crew-scheduling-v3/modals/MaintenanceEntryModal/actions';
import { openMTSModal } from './components/MTSSchedule/redux-saga/actions';

const ActionsDropdown: React.FC = () => {
  const dispatch = useDispatch();
  const isDrawerVisible = useSelector(getIsDrawerVisible);
  const { featureFlags } = useSelector(getCommon);
  const companySettings = useSelector(getCompanySettings);
  const visibleTimelines = useSelector(getVisibleTimelines);
  const userRoles = useSelector(getUserRole);
  const guard = new Guard('crewScheduleV2');
  const isFilterPinned = useSelector(getIsFilterDrawerPinned);

  const isAircraftTimelineVisible = visibleTimelines.includes(TimelineType.Aircraft);

  const items: MenuProps['items'] = [
    featureFlags[FeatureFlag.AircraftPaneScheduling] &&
    userRoles.includes('admin') && {
      key: 'generate-schedule',
      label: 'Generate Schedule',
      onClick: () => {
        dispatch(
          bulkModalEntryActions.setAircraftModalMode({ open: true, mode: MODAL_MODE.create }),
        );
        if (isDrawerVisible && !isFilterPinned) {
          dispatch(scheduleActions.setIsDrawerVisible(false));
        }
      },
    },
    companySettings?.AllowGroupSchedule && {
      key: 'generate-group-schedule',
      label: 'Generate Group Schedule',
      onClick: () => {
        dispatch(setModalOpen(true));
        if (isDrawerVisible && !isFilterPinned) {
          dispatch(scheduleActions.setIsDrawerVisible(false));
        }
      },
    },
    isAircraftTimelineVisible &&
    guard.canAccess('actions.addAircraftContract') &&
    userRoles.includes('admin') && {
      key: 'add-aircraft-contract',
      label: 'Add Aircraft Contract',
      onClick: () => {
        dispatch(
          scheduleActions.setAircraftContractModalMode({ open: true, mode: MODAL_MODE.create }),
        );
        dispatch(scheduleActions.setEditAircraftContractEntry({}));
        if (isDrawerVisible && !isFilterPinned) {
          dispatch(scheduleActions.setIsDrawerVisible(false));
        }
      },
    },
    guard.canAccess('actions.addPilotContract') &&
    userRoles.includes('admin') && {
      key: 'addPilotContract',
      label: 'Add Pilot Contract',
      onClick: () => {
        dispatch(
          scheduleActions.setPilotContractModalMode({ open: true, mode: MODAL_MODE.create }),
        );
        dispatch(scheduleActions.setPilotContractEntryForModal({}));
        if (isDrawerVisible && !isFilterPinned) {
          dispatch(scheduleActions.setIsDrawerVisible(false));
        }
      },
    },
    guard.canAccess('actions.addScheduleEntry') &&
    userRoles.includes('admin') && {
      key: 'addScheduleEntry',
      label: 'Add Schedule Entry',
      onClick: () => {
        dispatch(setCurrentScheduleEntryAction({}));
        dispatch(openCrewScheduleModalAction(MODAL_MODE.create));
        if (isDrawerVisible && !isFilterPinned) {
          dispatch(scheduleActions.setIsDrawerVisible(false));
        }
      },
    },
    guard.canAccess('actions.addDutyEntry') &&
    (userRoles.includes('admin') || companySettings?.AllowPilotPlannedDutyManagement) && {
      key: 'addPlannedDuty',
      label: 'Add Planned Duty',
      onClick: () => {
        dispatch(setCurrentDutyScheduleEntryAction({ isNewEntry: true }));
        dispatch(openDutyScheduleModalAction(MODAL_MODE.create));
        if (isDrawerVisible && !isFilterPinned) {
          dispatch(scheduleActions.setIsDrawerVisible(false));
        }
      },
    },
    guard.canAccess('actions.addDutyTime') &&
    (userRoles.includes('admin') || companySettings?.AllowPilotDutyManagement) && {
      key: 'addActualDuty',
      label: 'Add Actual Duty',
      onClick: () => {
        dispatch(dutyTimeModalActions.openModalAction(MODAL_MODE.create));
        dispatch(setCurrentDutyTimeEntry({} as DutyTimeEntry));
        if (isDrawerVisible && !isFilterPinned) {
          dispatch(scheduleActions.setIsDrawerVisible(false));
        }
      },
    },
    guard.canAccess('actions.addMaintenanceEntry') &&
    userRoles.includes('admin') &&
    companySettings?.MaintenanceSchedule && {
      key: 'addMaintenanceEntry',
      label: 'Add Maintenance Entry',
      onClick: () => {
        dispatch(setCurrentMaintenanceEntryAction({}));
        dispatch(openMaintenanceScheduleModalAction(MODAL_MODE.create));
      },
    },
    companySettings?.MTSSchedule && {
      key: 'generate-mts-schedule',
      label: 'Generate MTS Schedule',
      onClick: () => {
        dispatch(openMTSModal(MODAL_MODE.create));
        if (isDrawerVisible) {
          dispatch(scheduleActions.setIsDrawerVisible(false));
        }
      },
    },
  ].filter(Boolean);

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <Button type="primary" style={{ width: "100%" }}>Actions</Button>
    </Dropdown>
  );
};

export default ActionsDropdown;
