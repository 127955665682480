import { FileStruct, Folder, RelatedEntity, RelatedEntityType } from '../types/file';
import { CompanyLibraryState } from './reducers';

const actions = {
  SET_STATE: 'pages/company-library/SET_STATE',
  OPEN_NEW_FOLDER_MODAL: 'pages/company-library/OPEN_NEW_FOLDER_MODAL',
  CLOSE_NEW_FOLDER_MODAL: 'pages/company-library/CLOSE_NEW_FOLDER_MODAL',
  OPEN_DETAILS_MODAL: 'pages/company-library/OPEN_NEW_DETAILS_MODAL',
  CLOSE_DETAILS_MODAL: 'pages/company-library/CLOSE_NEW_DETAILS_MODAL',
  LOAD_FILE_LIST: 'pages/company-library/LOAD_FILE_LIST',
  SET_FILE_LIST: 'pages/company-library/SET_FILE_LIST',
  SET_FOLDER_LIST: 'pages/company-library/SET_FOLDER_LIST',
  PUSHER_FILE_UPDATED: 'pages/company-library/PUSHER_FILE_UPDATED',
  PUSHER_FILE_CREATED: 'pages/company-library/PUSHER_FILE_CREATED',
  PUSHER_FILE_DELETED: 'pages/company-library/PUSHER_FILE_DELETED',
  SET_ALL_RELATIONS: 'pages/company-library/SET_ALL_RELATIONS',
  LOAD_AIRCRAFT_LOGS_FOR_SELECTOR: 'pages/company-library/LOAD_AIRCRAFT_LOGS_FOR_SELECTOR',
  HANDLE_FILTERS_CHANGE: 'pages/company-library/HANDLE_FILTERS_CHANGE',
  FILE_NAME_CHANGE_FILTER: 'pages/company-library/FILE_NAME_CHANGE_FILTER',
  RELATED_ENTITY_TYPES_CHANGE_FILTER: 'pages/company-library/RELATED_ENTITY_TYPES_CHANGE_FILTER',
  RELATED_ENTITIES_CHANGE_FILTER: 'pages/company-library/RELATED_ENTITIES_CHANGE_FILTER',
  RELATED_ENTITIES_CHANGE_UPLOAD_MODAL_FILTER: 'pages/company-library/RELATED_ENTITIES_CHANGE_UPLOAD_MODAL_FILTER',
  DOWNLOAD_FILE: 'pages/company-library/DOWNLOAD_FILE',
  UPLOAD_FILE: 'pages/company-library/UPLOAD_FILE',
  DELETE_FILE: 'pages/company-library/DELETE_FILE',
  SET_SELECTED_FILE: 'pages/company-library/SET_SELECTED_FILE',
  LOAD_MORE: 'pages/company-library/LOAD_MORE',
  RESET_STATE: 'pages/company-library/RESET_STATE',
  SET_CURRENT_FOLDER_ID:  'pages/company-library/SET_CURRENT_FOLDER_ID',
  SET_CURRENT_DETAILS_FOLDER_ID:  'pages/company-library/SET_CURRENT_DETAILS_FOLDER_ID',
  SET_DETAILS_FOLDER_LIST:  'pages/company-library/SET_DETAILS_FOLDER_LIST',
};

export default actions;

export type UploadFileAction = {
  type: typeof actions.UPLOAD_FILE;
  payload: {
    currentFileID: string | null;
    file: any;
    fileClientName: string;
    notes: string;
    relatedEntities: RelatedEntity[];
    expirationDate: string;
    effectiveDate: string;
    currentFolderID: string;
  };
};

export type LoadFileListAction = {
  type: typeof actions.LOAD_FILE_LIST;
  payload: {
    isInitialRender?: boolean
  }
}

export type DeleteFileAction = {
  type: typeof actions.DELETE_FILE;
  payload: {
    fileID: string;
  };
};

export type FileNameChangeFilter = {
  type: typeof actions.FILE_NAME_CHANGE_FILTER;
  payload: {
    fileName: string;
  };
};

export type RelatedEntityTypesChangeFilter = {
  type: typeof actions.RELATED_ENTITY_TYPES_CHANGE_FILTER;
  payload: {
    relatedEntityTypes: RelatedEntityType[];
  };
};

export type RelatedEntitiesChangeFilter = {
  type: typeof actions.RELATED_ENTITIES_CHANGE_FILTER;
  payload: {
    relatedEntities: RelatedEntity[];
  };
};

export type PusherFileUpdate = {
  type: typeof actions.PUSHER_FILE_UPDATED;
  payload: {
    file: FileStruct;
    actionTriggeredBuUsedID: string;
  };
};

export type PusherFileDeleted = {
  type: typeof actions.PUSHER_FILE_DELETED;
  payload: {
    fileID: string;
  };
};

export type SetFileList = {
  type: typeof actions.SET_FILE_LIST;
  payload: {
    list: FileStruct[];
    loadMoreAvailable: boolean;
  };
};

export type SetFolderList = {
  type: typeof actions.SET_FOLDER_LIST;
  payload: {
    list: Folder[];
    loadMoreAvailable: boolean;
  };
};

export type SetAllRelations = {
  type: typeof actions.SET_ALL_RELATIONS;
  payload: {
    allRelations: RelatedEntity[];
  };
};

export type LoadAircraftLogsForSelector = {
  type: typeof actions.LOAD_AIRCRAFT_LOGS_FOR_SELECTOR;
  payload: {
    logIDs: string[];
  };
};

export type SetSelectedFile = {
  type: typeof actions.SET_SELECTED_FILE;
  payload: {
    selectedFile: FileStruct;
  };
};

export type HandleFiltersChange = {
  type: typeof actions.HANDLE_FILTERS_CHANGE;
  payload: {
    fileName?: string;
    relatedEntityTypes?: RelatedEntityType[];
    relatedEntities?: RelatedEntity[];
  };
};

export const setFileList = (list: FileStruct[], loadMoreAvailable: boolean): SetFileList => {
  return {
    type: actions.SET_FILE_LIST,
    payload: {
      list,
      loadMoreAvailable,
    },
  };
};

export const setFolderList = (list: Folder[], loadMoreAvailable: boolean): SetFolderList => {
  return {
    type: actions.SET_FOLDER_LIST,
    payload: {
      list,
      loadMoreAvailable,
    },
  };
};

export const loadFileList = (isInitialRender?: boolean): LoadFileListAction => {
  return {
    type: actions.LOAD_FILE_LIST,
    payload: {
      isInitialRender
    }
  };
};

export const openAddUpdateModal = (isNewEntry: boolean) => {
  // todo, not implemented
  return {
    type: actions.SET_STATE,
    payload: {},
  };
};

export const setState = (payload: Partial<CompanyLibraryState>) => {
  return {
    type: actions.SET_STATE,
    payload,
  };
};

export const pusherFileUpdated = (
  file: FileStruct,
  actionTriggeredBuUsedID: string,
): PusherFileUpdate => {
  return {
    type: actions.PUSHER_FILE_UPDATED,
    payload: {
      file,
      actionTriggeredBuUsedID,
    },
  };
};

export const pusherFileCreated = (
  file: FileStruct,
  actionTriggeredBuUsedID: string,
): PusherFileUpdate => {
  return {
    type: actions.PUSHER_FILE_CREATED,
    payload: {
      file,
      actionTriggeredBuUsedID,
    },
  };
};

export const setAllRelations = (allRelations: RelatedEntity[]): SetAllRelations => {
  return {
    type: actions.SET_ALL_RELATIONS,
    payload: {
      allRelations,
    },
  };
};

export const loadAircraftLogsForSelector = (logIDs: string[]): LoadAircraftLogsForSelector => {
  return {
    type: actions.LOAD_AIRCRAFT_LOGS_FOR_SELECTOR,
    payload: {
      logIDs,
    },
  };
};

export const handleFiltersChange = (
  payload: HandleFiltersChange['payload'],
): HandleFiltersChange => {
  return {
    type: actions.HANDLE_FILTERS_CHANGE,
    payload,
  };
};

export const fileNameChangeFilter = (fileName: string): FileNameChangeFilter => {
  return {
    type: actions.FILE_NAME_CHANGE_FILTER,
    payload: {
      fileName,
    },
  };
};

export const relatedEntityTypesChangeFilter = (
  relatedEntityTypes: RelatedEntityType[],
): RelatedEntityTypesChangeFilter => {
  return {
    type: actions.RELATED_ENTITY_TYPES_CHANGE_FILTER,
    payload: {
      relatedEntityTypes,
    },
  };
};

export const relatedEntitiesChangeFilter = (
  relatedEntities: RelatedEntity[],
): RelatedEntitiesChangeFilter => {
  return {
    type: actions.RELATED_ENTITIES_CHANGE_FILTER,
    payload: {
      relatedEntities,
    },
  };
};

export const uploadFile = (
  currentFileID: string | null,
  file: any,
  name: string,
  notes: string,
  relatedEntities: RelatedEntity[],
  expirationDate: string,
  effectiveDate: string,
  currentFolderID: string
): UploadFileAction => {
  return {
    type: actions.UPLOAD_FILE,
    payload: {
      currentFileID: currentFileID,
      file,
      fileClientName: name,
      notes: notes,
      relatedEntities,
      expirationDate,
      effectiveDate,
      currentFolderID
    },
  };
};

export const deleteFile = (fileID: string): DeleteFileAction => {
  return {
    type: actions.DELETE_FILE,
    payload: {
      fileID,
    },
  };
};

export const pusherFileDeleted = (fileID: string): PusherFileDeleted => {
  return {
    type: actions.PUSHER_FILE_DELETED,
    payload: {
      fileID,
    },
  };
};

export const setSelectedFile = (selectedFile: FileStruct | null): SetSelectedFile => {
  return {
    type: actions.SET_SELECTED_FILE,
    payload: {
      selectedFile,
    },
  };
};

export const loadMoreAction = () => {
  return {
    type: actions.LOAD_MORE,
  };
};

export const resetCompanyLibraryState = () => {
  return {
    type: actions.RESET_STATE,
  };
};

export const openNewFolderModal = () => {
  return {
    type: actions.OPEN_NEW_FOLDER_MODAL,
  };
};

export const closeNewFolderModal = () => {
  return {
    type: actions.CLOSE_NEW_FOLDER_MODAL,
  };
};

export const openDetailsModal = () => {
  return {
    type: actions.OPEN_DETAILS_MODAL,
  };
};

export const closeDetailsModal = () => {
  return {
    type: actions.CLOSE_DETAILS_MODAL,
  };
};

export const setCurrentFolderID = (currentFolderID: string) => {
  return {
    type: actions.SET_CURRENT_FOLDER_ID,
    payload: {
      currentFolderID
    }
  };
};

export const setCurrentDetailsFolderID = (currentDetailsFolderID: string) => {
  return {
    type: actions.SET_CURRENT_DETAILS_FOLDER_ID,
    payload: {
      currentDetailsFolderID
    }
  };
};

export const setDetailsFolderList = (detailsFolderList: Folder[]) => {
  return {
    type: actions.SET_DETAILS_FOLDER_LIST,
    payload: {
      detailsFolderList
    }
  };
};


