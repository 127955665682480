import {
  PusherActionType,
  PusherReduxAction,
} from '../../../../../../../../redux/pusher/PusherActions';
import { fetchEntry } from '../../timelineEntries/fetchEntry';
import { scheduleActions } from '../../../actions';
import { put, select } from 'redux-saga/effects';
import {
  selectMaintenanceScheduleTimelineEntriesSaga,
  selectPreferredVersionSaga,
} from '../../../selectors-saga';
import { CrewScheduleTimelineEntry } from '../../../../../../../../common/types/timeline/CrewScheduleTimelineEntry';
import { getRouter } from '../../../../../../../../redux/selectors';
import { RouterState } from 'connected-react-router';
import { isIgnoreUpdate } from '../helpers';
import { MaintenanceTimelineEntryType } from '../../../../../../../../common/types/timeline/MaintenanceTimelineEntryType';

export function* handleMaintenanceScheduleEntryUpdate(
  action: PusherReduxAction<PusherActionType.MAINTENANCE_SCHEDULE_ENTRY_UPDATED>,
) {
  const { location } = (yield select(getRouter)) as RouterState;
  if (!location.pathname.includes('crew-scheduling-v3')) {
    return;
  }

  try {
    const { payload } = action;

    const { CrewScheduleSandboxID } = payload;
    const preferredVersion = yield* selectPreferredVersionSaga();
    if (isIgnoreUpdate(preferredVersion, CrewScheduleSandboxID)) {
      return;
    }

    const entry = (yield fetchEntry(
      MaintenanceTimelineEntryType.MaintenanceCrew,
      payload.ID,
      true,
    )) as CrewScheduleTimelineEntry;

    const existedEntries = yield* selectMaintenanceScheduleTimelineEntriesSaga();
    const entryToDelete = existedEntries.find(e => e.ID === payload.ID)
    let updatedAircraftIDs: string[] = [];
    const updatedEntries = existedEntries.map(maintenanceScheduleEntry => {
      if (maintenanceScheduleEntry.ID === entry.ID) {
        updatedAircraftIDs = updatedAircraftIDs.concat(entry.AssignedAircraftIDs);
        return entry;
      }
      return maintenanceScheduleEntry;
    });

    yield put(scheduleActions.setState({ maintenanceTimelineEntries: updatedEntries }));
    yield put(scheduleActions.deleteMXFromAircraft({ assignedAircraftIDs: entryToDelete?.AssignedAircraftIDs, deletedMXEntry: entryToDelete }))
    yield put(scheduleActions.reFetchMXAircraftScheduleEntries(updatedAircraftIDs));
  } finally {
  }
}
