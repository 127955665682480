import MODAL_MODE from '../../../pages/data/common/constants';
import { CertificateGroup } from '../../../pages/data/organization/tracked-items/types';
import { GenericAction } from '../../../common/types/redux/actions';
import { CertificateGroupsModalInitialState } from './reducers';

const actions = {
  SET_STATE: "pages/certificate-groups/SET_STATE",
  OPEN_CERTIFICATE_GROUPS_MODAL: "pages/certificate-groups/OPEN_CERTIFICATE_GROUP_MODAL",
  CLOSE_CERTIFICATE_GROUPS_MODAL: "pages/certificate-groups/CLOSE_CERTIFICATE_GROUP_MODAL",
  SET_CERTIFICATE_GROUPS: "pages/certificate-groups/SET_CERTIFICATE_GROUPS",
  SET_FILTER_NAME: "pages/certificate-groups/SET_FILTER_NAME"
}

export interface SetStateAction extends GenericAction {
  payload: Partial<CertificateGroupsModalInitialState>;
}

export interface OpenCertificateGroupsModalAction {
  type: typeof actions.OPEN_CERTIFICATE_GROUPS_MODAL;
  payload: { mode: MODAL_MODE };
}

export interface CloseCertificateGroupsModalAction {
  type: typeof actions.CLOSE_CERTIFICATE_GROUPS_MODAL;
}

export interface SetCertificateGroupsAction {
  type: typeof actions.SET_CERTIFICATE_GROUPS;
  payload: {
    certificateGroups: CertificateGroup[]
  }
}

export interface SetFilterNameAction {
  type: typeof actions.SET_FILTER_NAME;
  payload: {
    filterName: string
  }
}

export const openCertificateGroupsModalAction = (mode: MODAL_MODE): OpenCertificateGroupsModalAction => ({
  type: actions.OPEN_CERTIFICATE_GROUPS_MODAL,
  payload: { mode },
});

export const closeCertificateGroupsModalAction = (): CloseCertificateGroupsModalAction => ({
  type: actions.CLOSE_CERTIFICATE_GROUPS_MODAL,
});

export const setCertificateGroupsAction = (certificateGroups: CertificateGroup[]): SetCertificateGroupsAction => ({
  type: actions.SET_CERTIFICATE_GROUPS,
  payload: { certificateGroups },
});

export const setFilterNameAction = (filterName: string): SetFilterNameAction => ({
  type: actions.SET_CERTIFICATE_GROUPS,
  payload: { filterName },
});

export const setCertificateGroupsState = (payload: Partial<CertificateGroupsModalInitialState>): SetStateAction => ({
  type: actions.SET_STATE,
  payload,
});



export default actions
