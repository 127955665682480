export type AircraftData = {
  selectedPilots: string[];
  selectedInstructors: string[];
  mtsAircraftType: string[] | null;
};

export type MTSDataType = {
  scenario: string
  notes: string;
  aircraftData: {
    [aircraftID: string]: AircraftData;
  };
};

export const MTSAircraftTypes = {
  FEDERATED: 'FEDERATED',
  STANDALONE: 'STANDALONE',
  FIREBOSS: 'FIREBOSS',
  WHEELED: 'WHEELED',
};

export const MTS_SCHEDULE_ENTRY_TYPE = 'MTS - Training';
export const INSTRUCTOR_ROLE_NAME = 'Instructor';
export const STUDENT_ROLE_NAME = 'Student';
