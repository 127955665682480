import { call, put } from 'redux-saga/effects';
import api from '../../../../services/api';
import {
  fetchReviews,
  setReviewList,
} from '../../../../pages/data/aircraft-log/Review/ReviewModal/actions';

export function* handleFetchReviews(action: fetchReviews){
  const { aircraftLogID } = action.payload;

  let reviewItems = [];

  try {
    const {
      data: { Data },
    } = yield call(
      api.get,
      `/v1/aircraft-logs/${aircraftLogID}/reviews`,
      {
        params: {
          aircraft_log_id: aircraftLogID,
          order: 'updated_at desc',
          limit: 10
        },
      },
    );
    reviewItems = Data;

  } catch (err) {
    console.log(`Error fetching reviews`, err);
  } finally{
    yield put(setReviewList(reviewItems));
  }
}
