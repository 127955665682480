import MODAL_MODE from '../../../../../pages/data/common/constants';
import { ModalMode } from '../../../../../redux/pages/crew-scheduling-v3/modals/interfaces';
import { actions } from './actions';

export type ReviewModalInitialState = {
  reviewModalMode: ModalMode;
};

export const initialState: ReviewModalInitialState = {
  reviewModalMode: {
    mode: MODAL_MODE.create,
    open: false,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
    case actions.GET_REVIEW_LIST:
      return {
        ...state,
        logReviewList: action.logReviewList,
      };
    case actions.GET_REVIEW_TYPES:
    return {
      ...state,
      reviewTypes: action.reviewTypes,
    };
    case actions.SET_REVIEW_LIST:
      return { ...state, logReviewList: action.payload.logReviewList };
    case actions.SET_REVIEW_TYPES:
      return { ...state, reviewTypes: action.payload.reviewTypes };
    case actions.SET_CURRENT_REVIEW:
      return { ...state, currentReview: action.payload.currentReview };
    case actions.OPEN_MODAL:
      return { ...state, reviewModalMode: { open: true, mode: action.payload.mode } };
    case actions.CLOSE_MODAL:
      return { ...state, reviewModalMode: { open: false, mode: MODAL_MODE.create } };
    default:
      return state;
  }
}
