import { all, takeEvery } from 'redux-saga/effects';
import { PusherActionType } from '../../pusher/PusherActions';
import { qualificationsValidatedWorker, qualificationValidatedWorker } from './workers/qualificationValidatedWorker';

export default function* rootSaga() {
  yield all([
    takeEvery(PusherActionType.QUALIFICATION_VALIDATED, qualificationValidatedWorker),
    takeEvery(PusherActionType.QUALIFICATIONS_VALIDATED, qualificationsValidatedWorker)
  ])
}
