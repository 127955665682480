import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import aircraftLogSelector from '../components/shared/AircraftLogSelector/redux-saga/reducers';
import combinedMaintenance from '../pages/data/aircraft-details/CombinedMaintenanceItems/redux-saga/reducers.ts';
import dutyFileImport from '../pages/data/organization/crew-scheduling-v3/components/Integrations/FileImport/redux-saga/reducers.ts';
import breadcrumbs from './breadcrumbs/reducers';
import common from './common/reducers';
import menu from './menu/reducers';
import pages from './pages';
import settings from './settings/reducers';
import user from './user/reducers';
import numinputmobile from '../components/shared/mobile/ATNumInput/redux-saga/reducers';
import integrations from './integrations/reducers';
import bulkScheduleModal from '../pages/data/organization/crew-scheduling-v3/components/Modals/BulkScheduleModal/redux-saga/reducers';
import bulkMaintenanceScheduleModal from '../pages/data/organization/crew-scheduling-v3/components/Modals/BulkMaintenanceScheduleModal/redux-saga/reducers';
import clientContractPeriodModal from '../pages/data/organization/crew-scheduling-v3/components/Modals/ClientContractPeriodModal/redux-saga/reducers';
import scheduleTemplateModal from '../pages/data/organization/crew-scheduling-v3/components/Modals/ScheduleTemplateModal/redux-saga/reducers.ts';

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    breadcrumbs,
    pages,
    common,
    numinputmobile,
    aircraftLogSelector,
    dutyFileImport,
    combinedMaintenance,
    integrations,
    modals: combineReducers({
      bulkScheduleModal,
      bulkMaintenanceScheduleModal,
      clientContractPeriodModal,
      scheduleTemplateModal,
    }),
  });
