import { PusherActionType, PusherReduxAction } from 'redux/pusher/PusherActions';
import { call, put, select } from 'redux-saga/effects';
import { getTrainingItemsOverviewState } from '../../tracked-items-overview/selectors';
import { Pilot } from '../../../../common/types/pilot';
import { setState } from '../../tracked-items-overview/actions';
import api from '../../../../services/api';
import { TrainingItem } from '../../../../pages/data/organization/tracked-items/types';

export function* trackedItemUpdated(
  action: PusherReduxAction<PusherActionType.TRACKED_ITEM_UPDATED>,
) {
  const { ID, UserID } = action.payload;

  const { users } = yield select(getTrainingItemsOverviewState);
  const response = yield call(api.get, `/v1/users/${UserID}/training/${ID}`);
  if (response.status !== 200 || !response?.data?.Data) {
    console.log('Failed to fetch updated tracked item');
    return;
  }
  const fullEntry: TrainingItem = response.data.Data;

  const updatedUsers: Pilot[] = users?.map((user: Pilot) => {
    if (user.ID === UserID) {
      const updatedTrackedItems = user.TrackedItems?.map(item =>
        item.ID === ID ? { ...fullEntry } : item,
      ).filter(item => item.ID !== fullEntry.PreviousVersionID && item.DueAtDate);

      updatedTrackedItems.sort(
        (a, b) => new Date(a.DueAtDate).getTime() - new Date(b.DueAtDate).getTime(),
      );

      return { ...user, TrackedItems: updatedTrackedItems };
    }
    return user;
  });

  yield put(setState({ users: updatedUsers }));
}
