import { actionTypes } from './actions';
import { AnyAction } from 'redux';

const initialState = {
  aircraftLoading: false,
  aircraftLog: {},
  isLogAttachmentAdded: false,
  isLogAttachmentDeleted: false,
  isLogAttachmentEdited: false,
  noChangesInRemoteSession: true,
  displayRemoteSessionAlert: false,
};

export default function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case actionTypes.SET_STATE:
      return { ...state, ...action.payload };
    case actionTypes.ADD_LOG_ATTACHMENT_SUCCESS:
      return {
        ...state,
        ...{
          isLogAttachmentAdded: action.payload,
        },
      };
    case actionTypes.DELETE_LOG_ATTACHMENT_SUCCESS:
      return {
        ...state,
        ...{
          isLogAttachmentDeleted: action.payload,
        },
      };
    case actionTypes.EDIT_LOG_ATTACHMENT_SUCCESS:
      return {
        ...state,
        ...{
          isLogAttachmentEdited: action.payload,
        },
      };
    case actionTypes.NO_CHANGES_IN_REMOTE_SESSION:
      return {
        ...state,
        ...{
          noChangesInRemoteSession: action.payload,
        },
      };
    case actionTypes.DISPLAY_REMOTE_SESSION_ALERT:
      return {
        ...state,
        ...{
          displayRemoteSessionAlert: action.payload,
        },
      };
    default:
      return state;
  }
}
