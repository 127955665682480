import { PusherActionType, PusherReduxAction } from '../../../pusher/PusherActions';
import { call, put, select } from 'redux-saga/effects';
import { getTrainingItemsOverviewState } from '../../tracked-items-overview/selectors';
import { setState } from '../../tracked-items-overview/actions';
import { Pilot } from '../../../../common/types/pilot';
import api from '../../../../services/api';
import { TrainingItem } from '../../../../pages/data/organization/tracked-items/types';

export function* trackedItemCreated(
  action: PusherReduxAction<PusherActionType.TRACKED_ITEM_CREATED>,
) {
  const { ID, UserID } = action.payload;

  const { users } = yield select(getTrainingItemsOverviewState);
  const response = yield call(api.get, `/v1/users/${UserID}/training/${ID}`);
  if (response.status !== 200 || !response?.data?.Data) {
    console.log('Failed to fetch created tracked item');
    return;
  }
  const fullEntry: TrainingItem = response.data.Data;

  const updatedUsers = users?.map((user: Pilot) => {
    if (user.ID === UserID) {
      let updatedTrackedItems = [];
      if (Array.isArray(user?.TrackedItems) && fullEntry) {
        updatedTrackedItems = [...user.TrackedItems, fullEntry];
      } else if (fullEntry) {
        updatedTrackedItems = [fullEntry];
      }

      const filteredTrackedItems = updatedTrackedItems.filter(
        item => item.ID !== fullEntry.PreviousVersionID && item.DueAtDate,
      );

      filteredTrackedItems.sort(
        (a, b) => new Date(a.DueAtDate).getTime() - new Date(b.DueAtDate).getTime(),
      );

      return { ...user, TrackedItems: filteredTrackedItems };
    }
    return user;
  });

  yield put(setState({ users: updatedUsers }));
}
