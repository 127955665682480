import { BookScheduleRequestAction, scheduleTemplateModalActions } from '../actions';
import { put } from 'redux-saga/effects';
import api from '../../../../../../../../../services/api';
import MODAL_MODE from '../../../../../../../common/constants';
import { ScheduleRequestForm } from '../types';
import { scheduleActions } from '../../../../../redux-saga/actions';

type BookingRequest = {
  ScheduleTemplateID: string;
  BookingRequestData: BookingRequestData;
};
type BookingRequestData = {
  Pilot1: string;
  Pilot1Phone?: string;
  Pilot2: string;
  Pilot2Phone?: string;
  Instructor: string;
  InstructorPhone?: string;
  Notes: string;
};

export function* bookSchedule(action: BookScheduleRequestAction) {
  try {
    const { requestForm, scheduleTemplateID } = action.payload;
    const request: BookingRequest = {
      ScheduleTemplateID: scheduleTemplateID,
      BookingRequestData: {
        Pilot1: requestForm.Pilot1,
        Pilot1Phone: requestForm.Pilot1Phone,
        Pilot2: requestForm.Pilot2,
        Pilot2Phone: requestForm.Pilot2Phone,
        Instructor: requestForm.Instructor,
        InstructorPhone: requestForm.InstructorPhone,
        Notes: requestForm.Notes,
      },
    };

    yield api.post(`/v1/schedule-template/booking`, request);

    yield put(
      scheduleTemplateModalActions.setScheduleTemplateModalMode({
        mode: MODAL_MODE.create,
        open: false,
      }),
    );

    yield put(scheduleActions.reFetchAllEntries());
  } catch (e) {
    console.error('book schedule err', e);
  }
}
