import { Company, CompanySettings, CompanyStatements } from '../types/Company';
import { CompanyFieldNames, CompanyStatement } from '../constants/company';

export function getFieldLabel(
  companySettings: CompanySettings,
  fieldName: CompanyFieldNames,
  defaultVal: string,
): string {
  return companySettings?.CompanyFieldNameMappings?.[fieldName] ?? defaultVal;
}

export function getStatementText(
  companyStatements: CompanyStatements,
  fieldName: CompanyStatement,
  defaultVal: string,
): string {
  const val = companyStatements?.[fieldName];
  return val != undefined ? val : defaultVal;
}

export function getFieldLabels(
  companySettings: CompanySettings,
  defaults: Partial<Record<CompanyFieldNames, string>> = {},
): Record<CompanyFieldNames, string> {
  const TotalLegsLabel = getFieldLabel(companySettings, CompanyFieldNames.TotalLegs, 'Total Legs');
  const HoursLabel = getFieldLabel(companySettings, CompanyFieldNames.Hours, 'Hours');
  const IFRHoursLabel = getFieldLabel(
    companySettings,
    CompanyFieldNames.IFRHours,
    defaults[CompanyFieldNames.IFRHours] || 'IFR Hours',
  );
  const VFRHoursLabel = getFieldLabel(companySettings, CompanyFieldNames.VFRHours, 'VFR Hours');
  const IFRApproachesLabel = getFieldLabel(
    companySettings,
    CompanyFieldNames.IFRApproaches,
    'IFR Approaches',
  );
  const NightTakeOffsLabel = getFieldLabel(
    companySettings,
    CompanyFieldNames.NightTakeOffs,
    'Night Take-Offs',
  );
  const NightLandingsLabel = getFieldLabel(
    companySettings,
    CompanyFieldNames.NightLandings,
    'Night Landings',
  );
  const DayTakeOffsLabel = getFieldLabel(
    companySettings,
    CompanyFieldNames.DayTakeOffs,
    'Day Take-Offs',
  );
  const DayLandingsLabel = getFieldLabel(
    companySettings,
    CompanyFieldNames.DayLandings,
    'Day Landings',
  );
  const FlyingDutyLabel = getFieldLabel(
    companySettings,
    CompanyFieldNames.FlyingDuty,
    'Flying Duty Day?',
  );
  const HoldsLabel = getFieldLabel(companySettings, CompanyFieldNames.Holds, '');
  const AircraftLogTZLabel = getFieldLabel(companySettings, CompanyFieldNames.AircraftLogTZ, '');
  const AircraftTypeLabel = getFieldLabel(companySettings, CompanyFieldNames.AircraftType, '');
  const PilotInCommandLabel = getFieldLabel(companySettings, CompanyFieldNames.FlightPilotInCommand, '');
  const SecondInCommandLabel = getFieldLabel(companySettings, CompanyFieldNames.FlightSecondInCommand, '');
  const StartAirportLabel = getFieldLabel(companySettings, CompanyFieldNames.StartAirport, '');
  const EndAirportLabel = getFieldLabel(companySettings, CompanyFieldNames.EndAirport, '');
  const AircraftLogDateLabel = getFieldLabel(companySettings, CompanyFieldNames.AircraftLogDate, '');
  const TailNumberLabel = getFieldLabel(companySettings, CompanyFieldNames.TailNumber, 'Tail Number');
  const RegistrationLabel = getFieldLabel(companySettings, CompanyFieldNames.Registration, 'Registration');
  const InitialAttackLabel = getFieldLabel(companySettings, CompanyFieldNames.InitialAttack, 'Initial Attack');
  const HobbsTimeLabel = getFieldLabel(companySettings, CompanyFieldNames.HobbsTime, 'Hobbs Time');
  const TimeOutLabel = getFieldLabel(companySettings, CompanyFieldNames.TimeOut, 'Time Out');
  const TimeOffLabel = getFieldLabel(companySettings, CompanyFieldNames.TimeOff, 'Time Off');
  const TimeOnLabel = getFieldLabel(companySettings, CompanyFieldNames.TimeOn, 'Time On');
  const TimeInLabel = getFieldLabel(companySettings, CompanyFieldNames.TimeIn, 'Time In');
  const FlightTimeLabel = getFieldLabel(companySettings, CompanyFieldNames.FlightTime, 'Flight Time');
  const RevenueFlightHoursLabel = getFieldLabel(companySettings, CompanyFieldNames.RevenueFlightHours, 'Revenue Flight Hours');
  const ContractDatesLabel = getFieldLabel(companySettings, CompanyFieldNames.ContractDates, 'Contract Dates');
  const RetdH2OLabel = getFieldLabel(companySettings, CompanyFieldNames.RetdH2O, 'Retd + H2O');
  const ExtendedStandbyLabel = getFieldLabel(companySettings, CompanyFieldNames.ExtendedStandby, 'Extended Standby');
  const SecondMechLabel = getFieldLabel(companySettings, CompanyFieldNames.SecondMech, 'Second Mech');
  const FuelAddedLabel = getFieldLabel(companySettings, CompanyFieldNames.FuelAdded, 'Fuel Added');
  const TRMLabel = getFieldLabel(companySettings, CompanyFieldNames.TRM, 'T/R/M');

  return {
    AirTime: '',
    ConfigurationChangeSectionTitle: '',
    CrewScheduleAircraft: '',
    FlightActualsNotesField: '',
    [CompanyFieldNames.FlightTime]: FlightTimeLabel,
    FlightWatchOnCall: '',
    SinglePilot: '',
    [CompanyFieldNames.AircraftLogDate]: AircraftLogDateLabel,
    [CompanyFieldNames.AircraftLogTZ]: AircraftLogTZLabel,
    [CompanyFieldNames.TimeOut]: TimeOutLabel,
    [CompanyFieldNames.TimeOff]: TimeOffLabel,
    [CompanyFieldNames.TimeOn]: TimeOnLabel,
    [CompanyFieldNames.TimeIn]: TimeInLabel,
    [CompanyFieldNames.TotalLegs]: TotalLegsLabel,
    [CompanyFieldNames.Hours]: HoursLabel,
    [CompanyFieldNames.IFRHours]: IFRHoursLabel,
    [CompanyFieldNames.VFRHours]: VFRHoursLabel,
    [CompanyFieldNames.IFRApproaches]: IFRApproachesLabel,
    [CompanyFieldNames.NightTakeOffs]: NightTakeOffsLabel,
    [CompanyFieldNames.NightLandings]: NightLandingsLabel,
    [CompanyFieldNames.DayTakeOffs]: DayTakeOffsLabel,
    [CompanyFieldNames.DayLandings]: DayLandingsLabel,
    [CompanyFieldNames.ClientContract]: '',
    [CompanyFieldNames.CompanyClient]: '',
    [CompanyFieldNames.FlyingDuty]: FlyingDutyLabel,
    [CompanyFieldNames.AsAKTP]: '',
    [CompanyFieldNames.NonrevenueFlightHours]: '',
    [CompanyFieldNames.RevenueFlightHours]: RevenueFlightHoursLabel,
    [CompanyFieldNames.Holds]: HoldsLabel,
    [CompanyFieldNames.ContractDates]: ContractDatesLabel,
    [CompanyFieldNames.AircraftType]: AircraftTypeLabel,
    [CompanyFieldNames.FlightPilotInCommand]: PilotInCommandLabel,
    [CompanyFieldNames.FlightSecondInCommand]: SecondInCommandLabel,
    [CompanyFieldNames.StartAirport]: StartAirportLabel,
    [CompanyFieldNames.EndAirport]: EndAirportLabel,
    [CompanyFieldNames.TailNumber]: TailNumberLabel,
    [CompanyFieldNames.Registration]: RegistrationLabel,
    [CompanyFieldNames.InitialAttack]: InitialAttackLabel,
    [CompanyFieldNames.HobbsTime]: HobbsTimeLabel,
    [CompanyFieldNames.RetdH2O]: RetdH2OLabel,
    [CompanyFieldNames.ExtendedStandby]: ExtendedStandbyLabel,
    [CompanyFieldNames.SecondMech]: SecondMechLabel,
    [CompanyFieldNames.FuelAdded]: FuelAddedLabel,
    [CompanyFieldNames.TRM]: TRMLabel,
  };
}

export const checkIfFileImportEnabled = (company: Company) => {
  const integrations = company?.Integrations || [];
  if (integrations.length > 0) {
    const fileImportIntegration = integrations.find(
      integration => integration.Type === 'File-Import',
    );
    if (fileImportIntegration && fileImportIntegration.Enabled) {
      return true;
    }
  }

  return false;
};
