import _ from 'lodash';
import { Pilot } from '../../../../../../../../common/types/pilot';
import { BulkScheduleModalValues, BulkScheduleModalValuesForm } from '../../../types';
import { CrewScheduleEntry } from '../../../../../../../../redux/pages/crew-scheduling-v3/scheduleEntries/types/CrewScheduleEntry';
import { AircraftSchedule } from '../../../../../../../../common/types/aircraftSchedule';
import moment from 'moment-timezone';
import { getPositionLabel } from '../../../Timeline/helper';
import { CompanySettings } from '../../../../../../../../common/types/Company';

export const getAircraftCertificationsString = (
  companySettings: CompanySettings,
  user: Pilot,
): string => {
  const positions = _.uniq(
    user?.Profile?.PilotAircraftCertifications?.map(cert =>
      getPositionLabel(companySettings, cert.PilotPosition),
    ),
  );
  return positions.join(', ') || '';
};

export const convertToBulkScheduleModalValues = (
  entry: AircraftSchedule | CrewScheduleEntry,
  defaultScheduleTypeID: string | null,
): BulkScheduleModalValues => {
  let userIDs: string[];
  let crewScheduleEntryIDs: string[];
  let schedulePeriod: [moment.Moment, moment.Moment];
  if ('Shifts' in entry) {
    const aircraftScheduleEntry = entry as AircraftSchedule;
    userIDs = aircraftScheduleEntry.UserID;
    schedulePeriod = [
      moment(aircraftScheduleEntry.StartTime),
      moment(aircraftScheduleEntry.EndTime),
    ];
    crewScheduleEntryIDs = aircraftScheduleEntry.RelatedCrewScheduleEntries.map(
      crewScheduleEntry => crewScheduleEntry.ID,
    );
  } else {
    const crewScheduleEntry = entry as CrewScheduleEntry;
    if (crewScheduleEntry.UserID) {
      userIDs = [crewScheduleEntry.UserID];
    }
    schedulePeriod = [moment(crewScheduleEntry.StartTime), moment(crewScheduleEntry.EndTime)];
    if (crewScheduleEntry.ID) {
      crewScheduleEntryIDs = [crewScheduleEntry.ID];
    }
  }

  return {
    CrewScheduleEntryIDs: crewScheduleEntryIDs,
    UserIDs: userIDs,
    CompanyScheduleEntryTypeID: entry.CompanyScheduleTypeID || defaultScheduleTypeID,
    AssignedAircraftID: entry.AssignedAircraft?.[0],
    ClientContractID: entry.ClientContractID,
    SchedulePeriod: schedulePeriod,
    StartAirportID: entry.StartAirportID,
    EndAirportID: entry.EndAirportID,
    SubPart: entry.Subparts?.[0],
    Notes: entry.Notes,
    ScheduleRoleIDs: entry.ScheduleRoleIDs
  };
};

export const deserializeAircraftScheduleData = (
  data: AircraftSchedule,
  defaultScheduleTypeID: string | null,
): BulkScheduleModalValuesForm => {
  const shifts: BulkScheduleModalValues[] = [];
  const shiftKeys = Object.keys(data.Shifts || {});
  if (shiftKeys.length > 0) {
    for (const shiftKey of shiftKeys) {
      const shiftsList = data.Shifts[shiftKey];
      const shiftData = shiftsList[0];
      // TODO: we take the data only from one entry, the more smart way is to merge all entries
      shifts.push(convertToBulkScheduleModalValues(shiftData, defaultScheduleTypeID));
    }
  } else {
    shifts.push(convertToBulkScheduleModalValues(data, defaultScheduleTypeID));
  }

  return { Shifts: shifts };
};
