import { all, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import { handleSaveMXGroupedEntry } from './workers/handleSaveMXGroupedEntry';
import { handleDeleteMXGroupedEntry } from './workers/handleDeleteMXGroupedEntry';

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SAVE_GROUPED_MX_ENTRY, handleSaveMXGroupedEntry),
    takeEvery(actions.DELETE_GROUPED_MX_ENTRY, handleDeleteMXGroupedEntry),
  ]);
}
