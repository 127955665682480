import { CrewSchedulingState } from './reducers';
import {
  ContextMenuClickPosition,
  TimelinePeriodSwitch,
  TimelineType,
  TimelineView,
} from '../types/timeline';
import { Pilot } from '../../../../../common/types/pilot';
import { PreferredVersion } from '../../../../../common/types/preferredVersoon';
import { CrewTimelineItem } from '../components/Timeline/CrewTimeline/generator';
import { MenuItemType } from '../types/MenuItemTypes';
import moment from 'moment-timezone';
import { GenericAction } from '../../../../../common/types/redux/actions';
import { AircraftTimelineItem } from '../components/Timeline/AircraftTimeline/generator';
import { TimelineItemResizeProps } from '../components/Timeline/types';
import { ContractTimelineItem } from '../components/Timeline/ContractTimeline/generator';
import { VirtualAircraftContractModalForm } from '../components/types';
import MODAL_MODE from '../../../common/constants';
import { CrewScheduleTimelineEntry } from 'common/types/timeline/CrewScheduleTimelineEntry';
import { MaintenanceTimelineItem } from '../components/Timeline/MaintenanceTimeline/generator';
import { MaintenanceUser } from '../../../../../common/types/maintenance';
import { MaintenanceTimelineEntry } from '../../../../../common/types/timeline/MaintenanceEntryType';

const actions = {
  SET_STATE: 'pages/company/crew-scheduling/SET_STATE',
  ON_PAGE_LOADED: 'pages/company/crew-scheduling/ON_PAGE_LOADED',
  ON_PAGE_UNLOADED: 'pages/company/crew-scheduling/ON_PAGE_UNLOADED',
  SET_SELECTED_TAGS: 'pages/company/crew-scheduling/SET_SELECTED_TAGS',
  SET_SELECTED_TIMELINES: 'pages/company/crew-scheduling/SET_SELECTED_TIMELINES',
  SET_SELECTED_CREW_MEMBER_IDS: 'pages/company/crew-scheduling/SET_SELECTED_CREW_MEMBER_IDS',
  SET_SELECTED_MAINTENANCE_MEMBER_IDS:
    'pages/company/crew-scheduling/SET_SELECTED_MAINTENANCE_MEMBER_IDS',
  SET_CREW_SORT_OPTION: 'pages/company/crew-scheduling/SET_CREW_SORT_OPTION',
  SET_MAINTENANCE_SORT_OPTION: 'pages/company/crew-scheduling/SET_MAINTENANCE_SORT_OPTION',
  LOAD_SELECTED_PILOTS_SUCCESS: 'pages/company/crew-scheduling/LOAD_SELECTED_PILOTS_SUCCESS',
  LOAD_SELECTED_MAINTENANCE_SUCCESS:
    'pages/company/crew-scheduling/LOAD_SELECTED_MAINTENANCE_SUCCESS',
  SET_COMPANY_SCHEDULE_VERSION: 'pages/company/crew-scheduling/SET_COMPANY_SCHEDULE_VERSION',
  SET_PREFERRED_VERSION: 'pages/company/crew-scheduling/SET_PREFERRED_VERSION',
  SET_COMPANY_TAGS: 'pages/company/crew-scheduling/SET_COMPANY_TAGS',
  SET_SELECTED_CLIENTS: 'pages/company/crew-scheduling/SET_SELECTED_CLIENTS',
  SET_SELECTED_CLIENT_CONTRACTS: 'pages/company/crew-scheduling/SET_SELECTED_CLIENT_CONTRACTS',
  SET_SELECTED_AIRCRAFT_IDENTIFIERS:
    'pages/company/crew-scheduling/SET_SELECTED_AIRCRAFT_IDENTIFIERS',
  SWITCH_TO_VIEW: 'pages/company/crew-scheduling/SWITCH_TO_VIEW',
  SWITCH_TIMELINE_PERIOD: 'pages/company/crew-scheduling/SWITCH_TIMELINE_PERIOD',
  SET_VISIBLE_TIMELINES: 'pages/company/crew-scheduling/SET_VISIBLE_TIMELINES',
  HANDLE_TIMELINE_ITEM_DOUBLE_CLICK:
    'pages/company/crew-scheduling/HANDLE_TIMELINE_ITEM_DOUBLE_CLICK',
  HANDLE_TIMELINE_MENU_ITEM_CLICK: 'pages/company/crew-scheduling/HANDLE_TIMELINE_MENU_ITEM_CLICK',
  SET_PILOT_CONTRACT_ENTRY_FOR_MODAL:
    'pages/company/crew-scheduling/SET_PILOT_CONTRACT_ENTRY_FOR_MODAL',
  SET_PILOT_CONTRACT_MODAL_MODE: 'pages/company/crew-scheduling/SET_PILOT_CONTRACT_MODAL_MODE',
  SET_AIRCRAFT_CONTRACT_MODAL_MODE:
    'pages/company/crew-scheduling/SET_AIRCRAFT_CONTRACT_MODAL_MODE',
  SET_GROUPED_AIRCRAFT_CONTRACT_MODAL_MODE:
    'pages/company/crew-scheduling/SET_GROUPED_AIRCRAFT_CONTRACT_MODAL_MODE',
  SET_REMOTE_PRINTER_MODAL_MODE: 'pages/company/crew-scheduling/SET_REMOTE_PRINTER_MODAL_MODE',
  SET_VISIBLE_TIMELINE_PERIOD: 'pages/company/crew-scheduling/SET_VISIBLE_TIMELINE_PERIOD',
  SET_TIMELINE_PERIOD: 'pages/company/crew-scheduling/SET_TIMELINE_PERIOD',
  JUMP_TO_DATE: 'pages/company/crew-scheduling/JUMP_TO_DATE',
  SET_EDIT_AIRCRAFT_SCHEDULE_ENTRY:
    'pages/company/crew-scheduling/SET_EDIT_AIRCRAFT_SCHEDULE_ENTRY',
  SET_EDIT_MX_AIRCRAFT_SCHEDULE_ENTRY:
    'pages/company/crew-scheduling/SET_EDIT_MX_AIRCRAFT_SCHEDULE_ENTRY',
  SET_EDIT_AIRCRAFT_CONTRACT_ENTRY:
    'pages/company/crew-scheduling/SET_EDIT_AIRCRAFT_CONTRACT_ENTRY',
  SET_ENTRY_DISPLAY_OPTIONS: 'pages/company/crew-scheduling/SET_ENTRY_DISPLAY_OPTIONS',
  SET_SWITCHED_TIMELINE_PERIOD: 'pages/company/crew-scheduling/SET_SWITCHED_TIMELINE_PERIOD',
  SET_TIMELINE_LOADING: 'pages/company/crew-scheduling/SET_TIMELINE_LOADING',
  SET_CUT_ITEM: 'pages/company/crew-scheduling/SET_CUT_ITEM',
  SET_COPY_ITEM: 'pages/company/crew-scheduling/SET_COPY_ITEM',
  SET_BULK_CUT_ITEMS: 'pages/company/crew-scheduling/SET_BULK_CUT_ITEMS',
  SET_BULK_COPY_ITEMS: 'pages/company/crew-scheduling/SET_BULK_COPY_ITEMS',
  SET_BULK_DELETE_ITEMS: 'pages/company/crew-scheduling/SET_BULK_DELETE_ITEMS',
  SET_CONTEXT_MENU_CLICK_POSITION: 'pages/company/crew-scheduling/SET_CONTEXT_MENU_CLICK_POSITION',
  HANDLE_PASTE_TIMELINE_ITEM: 'pages/company/crew-scheduling/HANDLE_PASTE_TIMELINE_ITEM',
  HANDLE_PASTE_TIMELINE_ITEMS: 'pages/company/crew-scheduling/HANDLE_PASTE_TIMELINE_ITEMS',
  HANDLE_RESIZE_TIMELINE_ITEM: 'pages/company/crew-scheduling/HANDLE_RESIZE_TIMELINE_ITEM',
  RE_FETCH_AIRCRAFT_SCHEDULE_ENTRIES:
    'pages/company/crew-scheduling/RE_FETCH_AIRCRAFT_SCHEDULE_ENTRIES',
  RE_FETCH_MX_AIRCRAFT_SCHEDULE_ENTRIES:
    'pages/company/crew-scheduling/RE_FETCH_MX_AIRCRAFT_SCHEDULE_ENTRIES',
  RE_FETCH_ALL_ENTRIES: 'pages/company/crew-scheduling/RE_FETCH_ALL_ENTRIES',
  HANDLE_MOVE_TIMELINE_ITEM: 'pages/company/crew-scheduling/HANDLE_MOVE_TIMELINE_ITEM',
  SAVE_VIRTUAL_AIRCRAFT_CONTRACT: 'pages/company/crew-scheduling/SAVE_VIRTUAL_AIRCRAFT_CONTRACT',
  SAVE_VIRTUAL_CREW_SCHEDULE_CONTRACT:
    'pages/company/crew-scheduling/SAVE_VIRTUAL_CREW_SCHEDULE_CONTRACT',
  DELETE_VIRTUAL_AIRCRAFT_CONTRACT:
    'pages/company/crew-scheduling/DELETE_VIRTUAL_AIRCRAFT_CONTRACT',
  HANDLE_DELETE_CREW_FROM_AIRCRAFT:
    'pages/company/crew-scheduling/HANDLE_DELETE_CREW_FROM_AIRCRAFT',
  HANDLE_DELETE_MX_FROM_AIRCRAFT:
    'pages/company/crew-scheduling/HANDLE_DELETE_MX_FROM_AIRCRAFT',
  SET_IS_DRAWER_VISIBLE: 'pages/company/crew-scheduling/IS_DRAWER_VISIBLE',
  SET_IS_CORRECTIONS_VISIBLE: 'pages/company/crew-scheduling/IS_CORRECTIONS_VISIBLE',
  SET_SELECTED_ITEM_ID: 'pages/company/crew-scheduling/SET_SELECTED_ITEM_ID',
  SET_IS_MEASURING_TAPE_ENABLED: 'pages/company/crew-scheduling/SET_IS_MEASURING_TAPE_ENABLED',
  SET_SWITCH_COMPACT_VIEW: 'pages/company/crew-scheduling/SET_SWITCH_COMPACT_VIEW',
  SET_NO_FILTER_DRAWER: 'pages/company/crew-scheduling/SET_NO_FILTER_DRAWER',
  SET_SELECTED_ITEMS:
    'pages/company/crew-scheduling/SET_SELECTED_ITEMS',
  SET_SELECTED_AIRCRAFT_ITEMS: 'pages/company/crew-scheduling/SET_SELECTED_AIRCRAFT_ITEMS',
  SET_SELECTED_MX_AIRCRAFT_ITEMS: 'pages/company/crew-scheduling/SET_SELECTED_MX_AIRCRAFT_ITEMS',
  SET_IS_FILTER_DRAWER_PINNED: 'pages/company/crew-scheduling/SET_IS_FILTER_DRAWER_PINNED',
  SET_TIMELINE_BUFFER_PERIODS: 'pages/company/crew-scheduling/SET_TIMELINE_BUFFER_PERIODS',
};

export default actions;

export interface SetStateAction extends GenericAction {
  payload: Partial<CrewSchedulingState>;
}

const setState = (payload: Partial<CrewSchedulingState>): SetStateAction => {
  return {
    type: actions.SET_STATE,
    payload,
  };
};

const onPageLoaded = (): { type: typeof actions.ON_PAGE_LOADED } => {
  return {
    type: actions.ON_PAGE_LOADED,
  };
};

const onPageUnloaded = (): { type: typeof actions.ON_PAGE_UNLOADED } => {
  return {
    type: actions.ON_PAGE_UNLOADED,
  };
};

export interface SetSelectedTagsAction extends GenericAction {
  payload: {
    selectedTags: CrewSchedulingState['selectedTags'];
  };
}

const setSelectedTags = (selectedTags: string[]): SetSelectedTagsAction => {
  return {
    type: actions.SET_SELECTED_TAGS,
    payload: { selectedTags },
  };
};

export interface SetSelectedTimelinesAction extends GenericAction {
  payload: { selectedTimelines: CrewSchedulingState['selectedTimelines'] };
}

const setSelectedTimelines = (timelines: TimelineType[]): SetSelectedTimelinesAction => {
  return {
    type: actions.SET_SELECTED_TIMELINES,
    payload: { selectedTimelines: timelines },
  };
};

export interface SetSwitchCompactView extends GenericAction {
  payload: { isCompactView: boolean };
}

const setSwitchCompactView = (isCompactView: boolean): SetSwitchCompactView => {
  return {
    type: actions.SET_SWITCH_COMPACT_VIEW,
    payload: { isCompactView },
  };
};

export interface SetSelectedCrewMemberIdsAction extends GenericAction {
  payload: { selectedCrewMemberIds: CrewSchedulingState['selectedCrewMemberIds'] };
}

const setSelectedCrewMemberIds = (
  selectedCrewMemberIds: string[],
): SetSelectedCrewMemberIdsAction => {
  return {
    type: actions.SET_SELECTED_CREW_MEMBER_IDS,
    payload: { selectedCrewMemberIds },
  };
};

export interface SetSelectedMaintenanceMemberIdsAction extends GenericAction {
  payload: { selectedMaintenanceMemberIds: CrewSchedulingState['selectedMaintenanceMemberIds'] };
}

const setSelectedMaintenanceMemberIds = (
  selectedMaintenanceMemberIds: string[],
): SetSelectedMaintenanceMemberIdsAction => {
  return {
    type: actions.SET_SELECTED_MAINTENANCE_MEMBER_IDS,
    payload: { selectedMaintenanceMemberIds },
  };
};

export interface SetCrewSortOptionAction extends GenericAction {
  payload: { crewSortOption: CrewSchedulingState['crewSortOption'] };
}

export interface SetMaintenanceSortOptionAction extends GenericAction {
  payload: { maintenanceSortOption: CrewSchedulingState['maintenanceSortOption'] };
}

const setCrewSortOption = (
  option: CrewSchedulingState['crewSortOption'],
): SetCrewSortOptionAction => ({
  type: actions.SET_CREW_SORT_OPTION,
  payload: { crewSortOption: option },
});

const setMaintenanceSortOption = (
  option: CrewSchedulingState['maintenanceSortOption'],
): SetMaintenanceSortOptionAction => ({
  type: actions.SET_MAINTENANCE_SORT_OPTION,
  payload: { maintenanceSortOption: option },
});

export interface LoadSelectedPilotsSuccessAction extends GenericAction {
  payload: {
    selectedPilots: CrewSchedulingState['selectedPilots'];
  };
}

export interface LoadSelectedMaintenanceSuccessAction extends GenericAction {
  payload: {
    selectedMaintenance: CrewSchedulingState['selectedMaintenance'];
  };
}

const loadSelectedPilotsSuccess = (selectedPilots: Pilot[]): LoadSelectedPilotsSuccessAction => {
  return {
    type: actions.LOAD_SELECTED_PILOTS_SUCCESS,
    payload: { selectedPilots },
  };
};
const loadSelectedMaintenanceSuccess = (
  selectedMaintenance: MaintenanceUser[],
): LoadSelectedMaintenanceSuccessAction => {
  return {
    type: actions.LOAD_SELECTED_MAINTENANCE_SUCCESS,
    payload: { selectedMaintenance },
  };
};

export interface SetPreferredVersionAction extends GenericAction {
  payload: {
    preferredVersion: CrewSchedulingState['preferredVersion'];
  };
}

const setPreferredVersion = (preferredVersion: PreferredVersion): SetPreferredVersionAction => ({
  type: actions.SET_PREFERRED_VERSION,
  payload: { preferredVersion },
});

export interface SetCompanyTagsAction extends GenericAction {
  payload: {
    companyTags: CrewSchedulingState['companyTags'];
  };
}

const setCompanyTags = (companyTags: CrewSchedulingState['companyTags']): SetCompanyTagsAction => {
  return {
    type: actions.SET_COMPANY_TAGS,
    payload: { companyTags },
  };
};

export interface SetSelectedClientsAction extends GenericAction {
  payload: {
    selectedClients: CrewSchedulingState['selectedClients'];
  };
}

const setSelectedClients = (selectedClients: string[]): SetSelectedClientsAction => {
  return {
    type: actions.SET_SELECTED_CLIENTS,
    payload: { selectedClients },
  };
};

export interface SetSelectedClientContractsAction extends GenericAction {
  payload: {
    selectedClientContracts: CrewSchedulingState['selectedClientContracts'];
  };
}

const setSelectedClientContracts = (
  selectedClientContracts: string[],
): SetSelectedClientContractsAction => {
  return {
    type: actions.SET_SELECTED_CLIENT_CONTRACTS,
    payload: { selectedClientContracts },
  };
};

export interface SetSelectedAircraftIdentifiersAction extends GenericAction {
  payload: {
    selectedAircraftIdentifiers: CrewSchedulingState['selectedAircraftIdentifiers'];
  };
}

const setSelectedAircraftIdentifiers = (
  selectedAircraftIdentifiers: string[],
): SetSelectedAircraftIdentifiersAction => {
  return {
    type: actions.SET_SELECTED_AIRCRAFT_IDENTIFIERS,
    payload: { selectedAircraftIdentifiers },
  };
};

export interface SwitchToViewAction extends GenericAction {
  payload: { currentView: TimelineView };
}

const switchToView = (view: TimelineView): SwitchToViewAction => {
  return {
    type: actions.SWITCH_TO_VIEW,
    payload: { currentView: view },
  };
};

export interface SwitchTimelinePeriodAction extends GenericAction {
  payload: { periodSwitch: TimelinePeriodSwitch };
}

const switchTimelinePeriod = (periodSwitch: TimelinePeriodSwitch): SwitchTimelinePeriodAction => {
  return {
    type: actions.SWITCH_TIMELINE_PERIOD,
    payload: { periodSwitch },
  };
};

export interface SetVisibleTimelinesAction extends GenericAction {
  payload: { visibleTimelines: TimelineType[] };
}

const setVisibleTimelines = (visibleTimelines: TimelineType[]): SetVisibleTimelinesAction => {
  return {
    type: actions.SET_VISIBLE_TIMELINES,
    payload: { visibleTimelines },
  };
};

export interface HandleItemDoubleClickAction extends GenericAction {
  payload: {
    item: CrewTimelineItem | AircraftTimelineItem | ContractTimelineItem | MaintenanceTimelineItem;
    timelineType: TimelineType;
  };
}

const handleTimelineItemDoubleClick = (
  item: HandleItemDoubleClickAction['payload']['item'],
  timelineType: TimelineType,
): HandleItemDoubleClickAction => {
  return {
    type: actions.HANDLE_TIMELINE_ITEM_DOUBLE_CLICK,
    payload: { item, timelineType },
  };
};

export interface HandleMenuItemClickAction extends GenericAction {
  payload: {
    item: CrewTimelineItem | AircraftTimelineItem | ContractTimelineItem | MaintenanceTimelineItem;
    menuItem: MenuItemType;
    timelineType: TimelineType;
    selectedItems?: (CrewTimelineItem | AircraftTimelineItem | MaintenanceTimelineItem)[]
  };
}

const handleMenuItemClick = (
  item: HandleMenuItemClickAction['payload']['item'],
  menuItem: MenuItemType,
  timelineType: HandleMenuItemClickAction['payload']['timelineType'],
  selectedItems?: HandleMenuItemClickAction['payload']['selectedItems'],
): HandleMenuItemClickAction => {
  return {
    type: actions.HANDLE_TIMELINE_MENU_ITEM_CLICK,
    payload: { item, selectedItems, menuItem, timelineType },
  };
};

export interface SetPilotContractEntryForModalAction extends GenericAction {
  payload: { pilotContractEntryForModal: CrewSchedulingState['pilotContractEntryForModal'] };
}

const setPilotContractEntryForModal = (
  pilotContractEntryForModal: Partial<CrewSchedulingState['pilotContractEntryForModal']>,
): SetPilotContractEntryForModalAction => {
  return {
    type: actions.SET_PILOT_CONTRACT_ENTRY_FOR_MODAL,
    payload: { pilotContractEntryForModal },
  };
};

export interface SetPilotContractModalModeAction extends GenericAction {
  payload: { pilotContractModalMode: CrewSchedulingState['pilotContractModalMode'] };
}

const setPilotContractModalMode = (
  mode: CrewSchedulingState['pilotContractModalMode'],
): SetPilotContractModalModeAction => {
  return {
    type: actions.SET_PILOT_CONTRACT_MODAL_MODE,
    payload: { pilotContractModalMode: mode },
  };
};

export interface SetVisibleTimelinePeriodAction extends GenericAction {
  payload: { visibleTimelinePeriod: CrewSchedulingState['visibleTimelinePeriod'] };
}

const setVisibleTimelinePeriod = (
  visibleTimelinePeriod: CrewSchedulingState['visibleTimelinePeriod'],
): SetVisibleTimelinePeriodAction => {
  return {
    type: actions.SET_VISIBLE_TIMELINE_PERIOD,
    payload: { visibleTimelinePeriod },
  };
};

export interface SetTimelinePeriodAction extends GenericAction {
  payload: { timelinePeriod: CrewSchedulingState['timelinePeriod'] };
}

const setTimelinePeriod = (
  timelinePeriod: CrewSchedulingState['timelinePeriod'],
): SetTimelinePeriodAction => {
  return {
    type: actions.SET_TIMELINE_PERIOD,
    payload: { timelinePeriod },
  };
};

export interface JumpToDateAction extends GenericAction {
  payload: { jumpToDate: moment.Moment };
}

const jumpToDate = (date: moment.Moment): JumpToDateAction => {
  return {
    type: actions.JUMP_TO_DATE,
    payload: { jumpToDate: date },
  };
};

export interface SetEditAircraftScheduleEntryAction extends GenericAction {
  payload: { editAircraftScheduleEntry: CrewSchedulingState['editAircraftScheduleEntry'] };
}

export interface SetEditMXScheduleEntryAction extends GenericAction {
  payload: { editMXAircraftScheduleEntry: CrewSchedulingState['editMXAircraftScheduleEntry'] };
}

const setEditAircraftScheduleEntry = (
  editAircraftScheduleEntry: CrewSchedulingState['editAircraftScheduleEntry'],
): SetEditAircraftScheduleEntryAction => {
  return {
    type: actions.SET_EDIT_AIRCRAFT_SCHEDULE_ENTRY,
    payload: { editAircraftScheduleEntry },
  };
};

const setEditMXAircraftScheduleEntry = (
  editMXAircraftScheduleEntry: CrewSchedulingState['editMXAircraftScheduleEntry'],
): SetEditMXScheduleEntryAction => {
  return {
    type: actions.SET_EDIT_MX_AIRCRAFT_SCHEDULE_ENTRY,
    payload: { editMXAircraftScheduleEntry },
  };
};

export interface SetEntryDisplayOptionsAction extends GenericAction {
  payload: { entryDisplayOptions: CrewSchedulingState['entryDisplayOptions'] };
}

const setEntryDisplayOptions = (
  entryDisplayOptions: CrewSchedulingState['entryDisplayOptions'],
): SetEntryDisplayOptionsAction => {
  return {
    type: actions.SET_ENTRY_DISPLAY_OPTIONS,
    payload: { entryDisplayOptions },
  };
};

export interface SetEditAircraftContractEntryAction extends GenericAction {
  payload: { editAircraftContractEntry: CrewSchedulingState['editAircraftContractEntry'] };
}

const setEditAircraftContractEntry = (
  editAircraftContractEntry: CrewSchedulingState['editAircraftContractEntry'],
): SetEditAircraftContractEntryAction => {
  return {
    type: actions.SET_EDIT_AIRCRAFT_CONTRACT_ENTRY,
    payload: { editAircraftContractEntry },
  };
};

export interface SetAircraftContractModalModeAction extends GenericAction {
  payload: { aircraftContractModalMode: CrewSchedulingState['aircraftContractModalMode'] };
}

const setAircraftContractModalMode = (
  mode: CrewSchedulingState['aircraftContractModalMode'],
): SetAircraftContractModalModeAction => {
  return {
    type: actions.SET_AIRCRAFT_CONTRACT_MODAL_MODE,
    payload: { aircraftContractModalMode: mode },
  };
};

export interface SetGroupedAircraftContractModalModeAction extends GenericAction {
  payload: {
    groupedAircraftContractModalMode: CrewSchedulingState['groupedAircraftContractModalMode'];
  };
}

const setGroupedAircraftContractModalMode = (
  mode: CrewSchedulingState['groupedAircraftContractModalMode'],
): SetGroupedAircraftContractModalModeAction => {
  return {
    type: actions.SET_GROUPED_AIRCRAFT_CONTRACT_MODAL_MODE,
    payload: { groupedAircraftContractModalMode: mode },
  };
};

export interface SetRemotePrinterModalModeAction extends GenericAction {
  payload: {
    remotePrinterModalMode: CrewSchedulingState['remotePrinterModalMode'];
  };
}

const setRemotePrinterModalMode = (
  mode: CrewSchedulingState['remotePrinterModalMode'],
): SetRemotePrinterModalModeAction => {
  return {
    type: actions.SET_REMOTE_PRINTER_MODAL_MODE,
    payload: { remotePrinterModalMode: mode },
  };
};

export interface SetSwitchedTimelinePeriodAction extends GenericAction {
  payload: { switchedTimelinePeriod: CrewSchedulingState['switchedTimelinePeriod'] };
}

const setSwitchedTimelinePeriod = (
  switchedTimelinePeriod: CrewSchedulingState['switchedTimelinePeriod'],
): SetSwitchedTimelinePeriodAction => {
  return {
    type: actions.SET_SWITCHED_TIMELINE_PERIOD,
    payload: { switchedTimelinePeriod },
  };
};

export interface SetTimelineLoadingAction extends GenericAction {
  payload: { isTimelineLoading: boolean };
}

const setTimelineLoading = (isTimelineLoading: boolean): SetTimelineLoadingAction => {
  return {
    type: actions.SET_TIMELINE_LOADING,
    payload: { isTimelineLoading },
  };
};

export interface SetCutItemAction extends GenericAction {
  payload: { cutItem: CrewTimelineItem | AircraftTimelineItem | MaintenanceTimelineItem };
}

const setCutItem = (
  cutItem: CrewTimelineItem | AircraftTimelineItem | MaintenanceTimelineItem,
): SetCutItemAction => {
  return {
    type: actions.SET_CUT_ITEM,
    payload: { cutItem },
  };
};

export interface SetCopyItemAction extends GenericAction {
  payload: { copyItem: CrewTimelineItem | AircraftTimelineItem | MaintenanceTimelineItem };
}

const setCopyItem = (
  copyItem: CrewTimelineItem | AircraftTimelineItem | MaintenanceTimelineItem,
): SetCopyItemAction => {
  return {
    type: actions.SET_COPY_ITEM,
    payload: { copyItem },
  };
};

export interface SetBulkCutItemsAction extends GenericAction {
  payload: { cutItems: (CrewTimelineItem | AircraftTimelineItem)[] };
}

const setBulkCutItems = (cutItems: (CrewTimelineItem | AircraftTimelineItem)[]): SetBulkCutItemsAction => {
  return {
    type: actions.SET_BULK_CUT_ITEMS,
    payload: { cutItems },
  };
};

export interface SetBulkCopyItemAction extends GenericAction {
  payload: { copyItems: (CrewTimelineItem | AircraftTimelineItem)[] };
}

const setBulkCopyItems = (copyItems: (CrewTimelineItem | AircraftTimelineItem)[]): SetBulkCopyItemAction => {
  return {
    type: actions.SET_BULK_COPY_ITEMS,
    payload: { copyItems },
  };
};

export interface SetBulkDeleteItemAction extends GenericAction {
  payload: { deleteItems: (CrewTimelineItem | AircraftTimelineItem)[] };
}

const setBulkDeleteItems = (deleteItems: (CrewTimelineItem | AircraftTimelineItem)[]): SetBulkDeleteItemAction => {
  return {
    type: actions.SET_BULK_COPY_ITEMS,
    payload: { deleteItems },
  };
};

export interface SetContextMenuClickPositionAction extends GenericAction {
  payload: { contextMenuClickPosition: ContextMenuClickPosition };
}

const setContextMenuClickPosition = (
  contextMenuClickPosition: ContextMenuClickPosition,
): SetContextMenuClickPositionAction => {
  return {
    type: actions.SET_CONTEXT_MENU_CLICK_POSITION,
    payload: { contextMenuClickPosition },
  };
};

const handlePasteTimelineItem = () => {
  return {
    type: actions.HANDLE_PASTE_TIMELINE_ITEM,
  };
};

export interface HandlePasteTimelineItemsAction extends GenericAction {
  payload: { timelineType: TimelineType };
}

const handlePasteTimelineItems = (timelineType: TimelineType): HandlePasteTimelineItemsAction => {
  return {
    type: actions.HANDLE_PASTE_TIMELINE_ITEMS,
    payload: {
      timelineType
    }
  };
};

export interface HandleResizeTimelineItemAction extends GenericAction {
  payload: { props: TimelineItemResizeProps };
}

const handleResizeTimelineItem = (
  props: TimelineItemResizeProps,
): HandleResizeTimelineItemAction => {
  return {
    type: actions.HANDLE_RESIZE_TIMELINE_ITEM,
    payload: { props },
  };
};

export interface ReFetchAircraftScheduleEntries extends GenericAction {
  payload: { aircraftIDs: string[] };
}

export interface ReFetchMXAircraftScheduleEntries extends GenericAction {
  payload: { aircraftIDs: string[] };
}

const reFetchAircraftScheduleEntries = (aircraftIDs: string[]): ReFetchAircraftScheduleEntries => {
  return {
    type: actions.RE_FETCH_AIRCRAFT_SCHEDULE_ENTRIES,
    payload: { aircraftIDs },
  };
};

const reFetchMXAircraftScheduleEntries = (aircraftIDs: string[]): ReFetchMXAircraftScheduleEntries => {
  return {
    type: actions.RE_FETCH_MX_AIRCRAFT_SCHEDULE_ENTRIES,
    payload: { aircraftIDs },
  };
};

const reFetchAllEntries = () => {
  return {
    type: actions.RE_FETCH_ALL_ENTRIES,
  };
};

export interface HandleMoveTimelineItemAction extends GenericAction {
  payload: {
    item: CrewTimelineItem | AircraftTimelineItem | MaintenanceTimelineItem;
    timelineType: TimelineType;
    newStartTime: number;
  };
}

const handleMoveTimelineItem = (
  item: CrewTimelineItem | AircraftTimelineItem | MaintenanceTimelineItem,
  timelineType: TimelineType,
  newStartTime: number,
): HandleMoveTimelineItemAction => {
  return {
    type: actions.HANDLE_MOVE_TIMELINE_ITEM,
    payload: { item, timelineType, newStartTime },
  };
};

export interface SaveVirtualAircraftContractEntryAction extends GenericAction {
  payload: {
    form: VirtualAircraftContractModalForm;
    mode: MODAL_MODE;
  };
}

const saveVirtualAircraftContractEntry = (
  form: VirtualAircraftContractModalForm,
  mode: MODAL_MODE,
): SaveVirtualAircraftContractEntryAction => {
  return {
    type: actions.SAVE_VIRTUAL_AIRCRAFT_CONTRACT,
    payload: { form, mode },
  };
};

export interface DeleteVirtualAircraftContractEntryAction extends GenericAction {
  payload: {
    aircraftContractIDs: string[];
  };
}

const deleteVirtualAircraftContractEntry = (
  aircraftContractIDs: string[],
): DeleteVirtualAircraftContractEntryAction => {
  return {
    type: actions.SAVE_VIRTUAL_AIRCRAFT_CONTRACT,
    payload: { aircraftContractIDs },
  };
};

export interface SetSelectedItemsAction extends GenericAction {
  payload: {
    selectedItems: (CrewTimelineItem | AircraftTimelineItem | MaintenanceTimelineItem)[];
  };
}

const setSelectedItems = (
  selectedItems: (CrewTimelineItem | AircraftTimelineItem | MaintenanceTimelineItem)[],
): SetSelectedItemsAction => {
  return {
    type: actions.SET_SELECTED_ITEMS,
    payload: { selectedItems },
  };
};

export interface SetSelectedAircraftItemsAction extends GenericAction {
  payload: {
    selectedAircraftItems: AircraftTimelineItem[];
  };
}

const setSelectedAircraftItems = (
  selectedAircraftItems: AircraftTimelineItem[],
): SetSelectedAircraftItemsAction => {
  return {
    type: actions.SET_SELECTED_AIRCRAFT_ITEMS,
    payload: { selectedAircraftItems },
  };
};

export interface SetSelectedMXAircraftItemsAction extends GenericAction {
  payload: {
    selectedMXAircraftItems: AircraftTimelineItem[];
  };
}

const setSelectedMXAircraftItems = (
  selectedMXAircraftItems: AircraftTimelineItem[],
): SetSelectedMXAircraftItemsAction => {
  return {
    type: actions.SET_SELECTED_MX_AIRCRAFT_ITEMS,
    payload: { selectedMXAircraftItems },
  };
};

export interface SetSelectedItemIdAction extends GenericAction {
  payload: {
    selectedItemID: string;
  };
}

const setSelectedItemId = (selectedItemID: string): SetSelectedItemIdAction => {
  return {
    type: actions.SET_SELECTED_ITEM_ID,
    payload: { selectedItemID },
  };
};

interface DeleteCrewFromAircraftPayload {
  assignedAircraftIDs: string[];
  deletedCrewEntry: CrewScheduleTimelineEntry;
}

interface DeleteMXFromAircraftPayload {
  assignedAircraftIDs: string[],
  deletedMXEntry: MaintenanceTimelineEntry,
}

export interface DeleteCrewFromAircraftAction extends GenericAction {
  payload: DeleteCrewFromAircraftPayload;
}

export interface DeleteMXFromAircraftAction extends GenericAction {
  payload: DeleteMXFromAircraftPayload
}

const deleteCrewFromAircraft = (
  payload: DeleteCrewFromAircraftPayload,
): DeleteCrewFromAircraftAction => {
  return {
    type: actions.HANDLE_DELETE_CREW_FROM_AIRCRAFT,
    payload,
  };
};

const deleteMXFromAircraft = (payload: DeleteMXFromAircraftPayload): DeleteMXFromAircraftAction => {
  return {
    type: actions.HANDLE_DELETE_MX_FROM_AIRCRAFT,
    payload,
  };
};

export interface SetIsMeasuringTapeEnabledAction extends GenericAction {
  payload: {
    isMeasuringTapeEnabled: boolean;
  };
}

const setIsMeasuringTapeEnabled = (
  isMeasuringTapeEnabled: boolean,
): SetIsMeasuringTapeEnabledAction => {
  return {
    type: actions.SET_IS_MEASURING_TAPE_ENABLED,
    payload: { isMeasuringTapeEnabled },
  };
};

export interface SetIsDrawerVisibleAction extends GenericAction {
  payload: {
    isDrawerVisible: boolean;
  };
}

export interface SetIsCorrectionsVisibleAction extends GenericAction {
  payload: {
    isCorrectionsVisible: boolean;
  };
}

const setIsDrawerVisible = (isDrawerVisible: boolean): SetIsDrawerVisibleAction => {
  return {
    type: actions.SET_IS_DRAWER_VISIBLE,
    payload: { isDrawerVisible },
  };
};

const setIsCorrectionsVisible = (isCorrectionsVisible: boolean): SetIsCorrectionsVisibleAction => {
  return {
    type: actions.SET_IS_CORRECTIONS_VISIBLE,
    payload: { isCorrectionsVisible },
  };
};

export interface NoFilterDrawerAction extends GenericAction {
  payload: {
    noFilterDrawer: boolean;
  };
}

const setNoFilterDrawer = (noFilterDrawer: boolean): NoFilterDrawerAction => {
  return {
    type: actions.SET_NO_FILTER_DRAWER,
    payload: { noFilterDrawer },
  };
};

export interface SetIsFilterDrawerPinned extends GenericAction {
  payload: {
    isFilterDrawerPinned: boolean;
  };
}

const setIsFilterDrawerPinned = (isFilterDrawerPinned: boolean): SetIsFilterDrawerPinned => {
  return {
    type: actions.SET_IS_FILTER_DRAWER_PINNED,
    payload: { isFilterDrawerPinned },
  };
};

export interface SetTimelineBufferPeriodAction extends GenericAction {
  payload: { timelineBufferPeriod: CrewSchedulingState['timelineBufferPeriod'] };
}

const setTimelineBufferPeriod = (timelineBufferPeriod: CrewSchedulingState['timelineBufferPeriod']): SetTimelineBufferPeriodAction => {
  return {
    type: actions.SET_TIMELINE_BUFFER_PERIODS,
    payload: { timelineBufferPeriod },
  };
};

export const scheduleActions = {
  setState,
  onPageLoaded,
  onPageUnloaded,
  setSelectedTags,
  setSelectedTimelines,
  setSelectedCrewMemberIds,
  setSelectedMaintenanceMemberIds,
  setCrewSortOption,
  setMaintenanceSortOption,
  loadSelectedPilotsSuccess,
  loadSelectedMaintenanceSuccess,
  setPreferredVersion,
  setCompanyTags,
  setSelectedClients,
  setSelectedClientContracts,
  setSelectedAircraftIdentifiers,
  switchToView,
  switchTimelinePeriod,
  setVisibleTimelines,
  handleTimelineItemDoubleClick,
  handleMenuItemClick,
  setPilotContractEntryForModal,
  setPilotContractModalMode,
  setVisibleTimelinePeriod,
  jumpToDate,
  setEditAircraftScheduleEntry,
  setEditMXAircraftScheduleEntry,
  setEntryDisplayOptions,
  setTimelinePeriod,
  setEditAircraftContractEntry,
  setAircraftContractModalMode,
  setSwitchedTimelinePeriod,
  setTimelineLoading,
  setCutItem,
  setCopyItem,
  setContextMenuClickPosition,
  handlePasteTimelineItem,
  handlePasteTimelineItems,
  handleResizeTimelineItem,
  reFetchAircraftScheduleEntries,
  reFetchMXAircraftScheduleEntries,
  reFetchAllEntries,
  handleMoveTimelineItem,
  setGroupedAircraftContractModalMode,
  saveVirtualAircraftContractEntry,
  deleteVirtualAircraftContractEntry,
  setRemotePrinterModalMode,
  deleteCrewFromAircraft,
  deleteMXFromAircraft,
  setSelectedItems,
  setBulkCutItems,
  setBulkCopyItems,
  setBulkDeleteItems,
  setSelectedAircraftItems,
  setSelectedMXAircraftItems,
  setSelectedItemId,
  setIsMeasuringTapeEnabled,
  setSwitchCompactView,
  setIsDrawerVisible,
  setIsCorrectionsVisible,
  setNoFilterDrawer,
  setIsFilterDrawerPinned,
  setTimelineBufferPeriod
};
