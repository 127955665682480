import {
  PusherActionType,
  PusherReduxAction,
} from '../../../../../../../../redux/pusher/PusherActions';
import { scheduleActions } from '../../../actions';
import { put, select } from 'redux-saga/effects';
import {
  selectMaintenanceScheduleTimelineEntriesSaga,
  selectPreferredVersionSaga,
} from '../../../selectors-saga';
import { getRouter } from '../../../../../../../../redux/selectors';
import { RouterState } from 'connected-react-router';
import { isIgnoreUpdate } from '../helpers';

export function* handleMaintenanceScheduleEntryDelete(
  action: PusherReduxAction<PusherActionType.MAINTENANCE_SCHEDULE_ENTRY_DELETED>,
) {
  const { location } = (yield select(getRouter)) as RouterState;
  if (!location.pathname.includes('crew-scheduling-v3')) {
    return;
  }

  try {
    const { payload } = action;

    const { CrewScheduleSandboxID } = payload;
    const preferredVersion = yield* selectPreferredVersionSaga();
    if (isIgnoreUpdate(preferredVersion, CrewScheduleSandboxID)) {
      return;
    }

    const existedEntries = yield* selectMaintenanceScheduleTimelineEntriesSaga();
    const entryToDelete = existedEntries.find(
      maintenanceScheduleEntry => maintenanceScheduleEntry.ID === payload.ID,
    );
    const updatedEntries = existedEntries.filter(
      maintenanceScheduleEntry => maintenanceScheduleEntry.ID !== payload.ID,
    );

    yield put(scheduleActions.setState({ maintenanceTimelineEntries: updatedEntries }));
    yield put(scheduleActions.deleteMXFromAircraft({ assignedAircraftIDs: entryToDelete?.AssignedAircraftIDs, deletedMXEntry: entryToDelete }));
  } finally {
  }
}
