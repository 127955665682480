import {
  openMTSModal,
  setCurrentMTSEntry,
  setCurrentRelatedCSEntryIDs,
  SetEditEntryAction,
} from '../actions';
import { call, put, select } from 'redux-saga/effects';
import api from '../../../../../../../../services/api';
import { getCompanyID } from '../../../../../../../../redux/selectors';
import MODAL_MODE from '../../../../../../common/constants';
import { INSTRUCTOR_ROLE_NAME, MTSDataType, STUDENT_ROLE_NAME } from '../../type';

export function* handleEditEntry(action: SetEditEntryAction) {
  const { crewScheduleGroupID } = action.payload;
  const companyID = yield select(getCompanyID);

  try {
    const allCrewScheduleEntries = yield call(api.get, `/v1/companies/${companyID}/crewschedule`, {
      params: {
        crew_schedule_group_id: crewScheduleGroupID,
      },
    });

    const resultData: MTSDataType = {
      notes: allCrewScheduleEntries?.data?.Data?.[0]?.Notes || '',
      scenario: allCrewScheduleEntries?.data?.Data?.[0]?.GroupNotes || '',
      aircraftData: {},
    };
    const entryIds = {};
    for (const entry of allCrewScheduleEntries?.data?.Data) {
      const assignedAircraft: string[] = entry.AssignedAircraft || [];

      assignedAircraft.forEach((aircraftID: string) => {
        if (!resultData.aircraftData[aircraftID]) {
          resultData.aircraftData[aircraftID] = {
            selectedPilots: [],
            selectedInstructors: [],
            mtsAircraftType: null,
          };
        }
      });
      if (entry.UserID && entry.ScheduleRole?.Name === STUDENT_ROLE_NAME) {
        resultData.aircraftData[entry.AssignedAircraft[0]].selectedPilots.push(entry.UserID);
      } else if (entry.UserID && entry.ScheduleRole?.Name === INSTRUCTOR_ROLE_NAME) {
        resultData.aircraftData[entry.AssignedAircraft[0]].selectedInstructors.push(entry.UserID);
      }

      if (entry.MTSAircraftType) {
        resultData.aircraftData[entry.AssignedAircraft[0]].mtsAircraftType = entry.MTSAircraftType;
      }

      if (!entryIds[entry.AssignedAircraft[0]]) {
        entryIds[entry.AssignedAircraft[0]] = [entry.ID];
      } else {
        entryIds[entry.AssignedAircraft[0]].push(entry.ID);
      }
    }
    yield put(setCurrentRelatedCSEntryIDs(entryIds));
    yield put(setCurrentMTSEntry(resultData));
    yield put(openMTSModal(MODAL_MODE.update));
  } catch (e) {
    console.log(e);
  } finally {
  }
}
