export enum AircraftTimelineEntryType {
  AircraftSchedule = 'aircraft-schedule',
  MaintenanceAircraftSchedule = 'maintenance-aircraft-schedule',
  AircraftContract = 'aircraftcontract',
  MeasuringTape = 'aircraft-measuring-tape',
  ScheduleTemplate = 'schedule-template',
}

const sortOrder = [
  AircraftTimelineEntryType.AircraftSchedule,
  AircraftTimelineEntryType.MaintenanceAircraftSchedule,
  // AircraftTimelineEntryType.AircraftContract,
];
