import actions from './actions';
import { Profile } from '../../common/types/user';
import { Company } from '../../common/types/Company';
import { CompanyUser } from '../../common/types/pilot';
import { actionTypes } from '../common/actions';

const companyUsers = sessionStorage.getItem('ATcompanyUsers');
const authenticated = sessionStorage.getItem('ATauthenticated');

export interface UserState {
  id: string;
  name: string;
  role: string;
  email: string;
  avatar: string;
  loading: boolean;
  Profile: Profile;
  Company: Company;
  authenticated: boolean;
  CompanyUsers: CompanyUser[];
  isCompanyLoaded: boolean;
  isUserLoaded: boolean;
}

const initialState = {
  id: '',
  name: '',
  role: '',
  email: '',
  avatar: '',
  loading: false,
  Profile: {},
  Company: {},
  authenticated: authenticated ? JSON.parse(authenticated) : false,
  CompanyUsers: companyUsers ? JSON.parse(companyUsers) : [],
  isCompanyLoaded: false,
  isUserLoaded: false,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.SET_COMPANY:
      return { ...state, Company: action.payload.Company, isCompanyLoaded: true };
    case actions.SET_USER:
      return { ...state, ...action.payload, isUserLoaded: true };
    case actions.SET_USER_REVIEWER_TYPES:
      let updatedCompanyUsers = []
      if (state.CompanyUsers && state.CompanyUsers.length > 0) {
         updatedCompanyUsers = [
          {
            ...state.CompanyUsers[0],
            ReviewerType: action.payload
          },
          ...state.CompanyUsers.slice(1)
        ];
      }
      return {
        ...state,
        CompanyUsers: updatedCompanyUsers
      };
    default:
      return state;
  }
}
