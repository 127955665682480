import React, { useEffect, useState } from 'react';
import { Button, Cascader, Form, Select } from 'antd';
import HasAccess from '../../../../../permissions/guard-access-component';
import Guard from '../../../../../permissions/crew-schedule-v2-guard';
import { useDispatch, useSelector } from 'react-redux';
import LocalCache from '../../../../../local-storage/local-storage';
import { CascaderOptions, SingleValueType } from '../types';
import {
  getCrewCertifications,
  getCrewMemberBases, getIsDrawerVisible,
  getPilots,
  getSelectedCrewMemberIDs,
} from '../../redux-saga/selectors';
import { scheduleActions } from '../../redux-saga/actions';
import { IDs } from '../../../../../../constants';
import { Pilot } from '../../../../../../common/types/pilot';
import { CrewScheduleFilter } from '../../../../../../common/constants/filters';
import '../CascaderSelector.css';

const { Option } = Select;

interface Props {}

export const CrewSelector: React.FC<Props> = ({}) => {
  const crewCertifications = useSelector(getCrewCertifications);
  const selectedCrewMemberIds = useSelector(getSelectedCrewMemberIDs);
  const crewMemberBases = useSelector(getCrewMemberBases);
  const pilots = useSelector(getPilots);
  const isDrawerVisible = useSelector(getIsDrawerVisible)

  const localCache = new LocalCache('crewSchedulingV2');

  const [selectedCrewCertification, setSelectedCrewCertification] = useState(
    localCache.getCached('selectedCrewCertification', []),
  );

  const guard = new Guard('crewScheduleV2');
  const dispatch = useDispatch();

  const [selectedPilots, setSelectedPilots] = useState([]);
  const [unselectedPilots, setUnselectedPilots] = useState([]);

  const [selectedBases, setSelectedBases] = useState(
    localCache.getCached('selectedCrewMemberBases', []),
  );

  const getCrewSchedulePilots = (): Pilot[] => {
    return pilots.filter(pilot => pilot.Profile.ShowInCSV2);
  };

  useEffect(() => {
    const filteredPilots = getCrewSchedulePilots();

    // Update selected and unselected options when allOptions or selectedValues changes
    const newSelectedPilots = filteredPilots.filter(opt => selectedCrewMemberIds.includes(opt.ID));
    const newUnselectedPilots = filteredPilots.filter(
      opt => !selectedCrewMemberIds.includes(opt.ID),
    );

    setSelectedPilots(newSelectedPilots);
    setUnselectedPilots(newUnselectedPilots);
  }, [pilots, selectedCrewMemberIds]);

  const showSearchFilter = (inputValue: string, path: CascaderOptions[]) => {
    return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  };

  const setCertifications = (certifications: SingleValueType[]) => {
    setSelectedCrewCertification(certifications);
    localCache.setCached('selectedCrewCertification', certifications);
  };

  const onSelectPilots = (pilotIdArray: string[]) => {
    // Update the certifications
    setCertifications([]);
    // Dispatch action to update selected pilots
    dispatch(scheduleActions.setSelectedCrewMemberIds(pilotIdArray));
    localCache.setCached('selectedCrewMemberIds', pilotIdArray);
    setCrewMemberBases([]);
  };

  const onSelectCrewCertification = (certification: SingleValueType[]) => {
    const filteredPilots = getCrewSchedulePilots();

    // Transform the selected certifications into a more easily comparable format
    const newCertifications = new Set(
      certification.map(c => {
        if (c.length === 1) {
          // AircraftType
          return c[0];
        } else {
          // `${AircraftType}-${PilotPosition}`
          return `${c[0]}-${c[1]}`;
        }
      }),
    );

    const selected = new Set<string>(selectedCrewMemberIds);

    for (const pilot of filteredPilots) {
      let hasMatchingCertification = false;

      for (const cert of pilot.Profile?.PilotAircraftCertifications || []) {
        if (newCertifications.has(cert.AircraftType)) {
          hasMatchingCertification = true;
          break;
        }

        if (newCertifications.has(`${cert.AircraftType}-${cert.PilotPosition}`)) {
          hasMatchingCertification = true;
          break;
        }
      }

      if (hasMatchingCertification) {
        selected.add(pilot.ID);
      } else {
        // Check if the pilot should be removed
        const hasAnySelectedCertification = (pilot.Profile?.PilotAircraftCertifications || []).some(
          cert => {
            if (newCertifications.has(cert.AircraftType)) {
              return true;
            }

            return newCertifications.has(`${cert.AircraftType}-${cert.PilotPosition}`);
          },
        );

        if (!hasAnySelectedCertification) {
          selected.delete(pilot.ID);
        }
      }
    }

    dispatch(scheduleActions.setSelectedCrewMemberIds([...selected]));
    localCache.setCached('selectedCrewMemberIds', [...selected])
    setCertifications(certification);
    setCrewMemberBases([]);
  };

  const setCrewMemberBases = (selectedBases: string[]) => {
    setSelectedBases(selectedBases);
    localCache.setCached('selectedCrewMemberBases', selectedBases);
  };

  const onSelectCrewMemberBases = (baseAirportArray: string[]) => {
    setCrewMemberBases(baseAirportArray);
    setCertifications([]);

    if (baseAirportArray.length === 0) {
      localCache.setCached('selectedCrewMemberIds', [])
      dispatch(scheduleActions.setSelectedCrewMemberIds([]));
      return;
    }

    const filteredPilotIDs = getCrewSchedulePilots()
      .filter(pilot => baseAirportArray.includes(pilot.Profile.BaseAirportCode))
      .map(pilot => pilot.ID);

    localCache.setCached('selectedCrewMemberIds', filteredPilotIDs)
    dispatch(scheduleActions.setSelectedCrewMemberIds(filteredPilotIDs));
  };

  return (
    <>
      <HasAccess access={guard.canAccessFilter(CrewScheduleFilter.CREW_MEMBER)}>
        <Form.Item label="Crew Members" style={{ marginRight: !isDrawerVisible ? '20px' : '', flexGrow: 1, flexBasis: 0 }}>
          <Select
            id={IDs.filters.crewMemberSelector}
            filterOption={(input, option) => {
              if (Array.isArray(option.children)) {
                return (
                  option.children
                    .join('')
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) !== -1
                );
              }
              return (option.children as string).toLowerCase().indexOf(input.toLowerCase()) !== -1;
            }}
            mode="multiple"
            onChange={pilotIdArray => {
              onSelectPilots(pilotIdArray);
            }}
            maxTagCount="responsive"
            placeholder="Select Crew Members"
            allowClear
            value={selectedCrewMemberIds}
            listHeight={320} // 10 items * 32px
            dropdownRender={menu => (
              <div>
                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: '8px' }}>
                  <Button
                    onClick={() => {
                      const pilotIdArray = getCrewSchedulePilots().map(p => p.ID);
                      dispatch(scheduleActions.setSelectedCrewMemberIds(pilotIdArray));
                      localCache.setCached('selectedCrewMemberIds', pilotIdArray);
                    }}
                  >
                    Select all
                  </Button>
                </div>
                {menu}
              </div>
            )}
          >
            {[...selectedPilots, ...unselectedPilots].map(p => (
              <Option key={p.ID} value={p.ID}>
                {p.Profile.FirstName} {p.Profile.LastName}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </HasAccess>

      <HasAccess access={guard.canAccessFilter(CrewScheduleFilter.CREW_CERTIFICATION)}>
        <Form.Item
          label="Crew Certifications"
          style={{ marginRight: !isDrawerVisible ? '20px' : '', flexGrow: 1, flexBasis: 0 }}
        >
          <Cascader
            id={IDs.filters.crewCertificationSelector}
            options={crewCertifications || []}
            expandTrigger="hover"
            maxTagCount="responsive"
            placeholder="Select Crew Certifications"
            multiple
            showSearch={{ filter: showSearchFilter }}
            value={selectedCrewCertification}
            onChange={certification => {
              onSelectCrewCertification(certification);
            }}
            popupClassName={
              crewCertifications && crewCertifications.length >= 10
                ? 'custom-cascader-dropdown'
                : 'custom-cascader-dropdown-auto-height'
            }
          />
        </Form.Item>

        <Form.Item
          label="Crew Member Base"
          style={{ marginRight: !isDrawerVisible ? '20px' : '', flexGrow: 1, flexBasis: 0 }}
        >
          <Select
            id={IDs.filters.crewMemberBaseSelector}
            filterOption={(input, option) => {
              if (Array.isArray(option.children)) {
                return (
                  option.children
                    .join('')
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) !== -1
                );
              }
              return (option.children as string).toLowerCase().indexOf(input.toLowerCase()) !== -1;
            }}
            mode="multiple"
            onChange={baseAirportArray => {
              onSelectCrewMemberBases(baseAirportArray);
            }}
            maxTagCount="responsive"
            allowClear
            placeholder="Select Crew Member Base"
            value={selectedBases}
            listHeight={320}
            dropdownRender={menu => (
              <div>
                <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                  <Button
                    onClick={() => {
                      onSelectCrewMemberBases(crewMemberBases);
                    }}
                  >
                    Select all
                  </Button>
                </div>
                {menu}
              </div>
            )}
          >
            {crewMemberBases &&
              crewMemberBases.map(airport => (
                <Option key={airport} value={airport}>
                  {airport}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </HasAccess>
    </>
  );
};
