import { select, delay } from 'redux-saga/effects';
import {selectIsLoadingMoreEntriesSaga} from "../../selectors-saga";
import {getIsLoadingEntriesQuietly, getIsTimelineLoading} from "../../selectors";


export function* waitToFinishLoading() {
  let elapsedTime = 0;

  while (true) {
    const isLoading = yield* selectIsLoadingMoreEntriesSaga();
    const isTimelineLoading = yield select(getIsTimelineLoading);
    const isLoadingQuietly = yield select(getIsLoadingEntriesQuietly);

    if (!isLoading && !isTimelineLoading && !isLoadingQuietly) {
      return;
    }

    if (elapsedTime > 60000) {
      console.error("Possible deadlock. Waiting to load timed out!");
      return;
    }

    yield delay(10);
    elapsedTime += 10;
  }
}
