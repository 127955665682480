export enum CompanyFieldNames {
  TotalLegs = 'TotalLegs',
  Hours = 'Hours',
  IFRHours = 'IFRHours',
  VFRHours = 'VFRHours',
  IFRApproaches = 'IFRApproaches',
  NightTakeOffs = 'NightTakeOffs',
  NightLandings = 'NightLandings',
  DayTakeOffs = 'DayTakeOffs',
  DayLandings = 'DayLandings',
  SinglePilot = 'SinglePilot',
  TimeOut = 'TimeOut',
  TimeOff = 'TimeOff',
  AircraftLogDate = 'AircraftLogDate',
  AircraftLogTZ = 'AircraftLogTZ',
  TimeOn = 'TimeOn',
  TimeIn = 'TimeIn',
  AirTime = 'AirTime',
  FlightTime = 'FlightTime',
  FlightActualsNotesField = 'FlightActualsNotesField',
  ConfigurationChangeSectionTitle = 'ConfigurationChangeSectionTitle',
  CrewScheduleAircraft = 'CrewScheduleAircraft',
  FlightWatchOnCall = 'FlightWatchOnCall',
  ClientContract = 'ClientContract',
  CompanyClient = 'CompanyClient',
  FlyingDuty = 'FlyingDuty',
  AsAKTP = 'AsAKTP',
  NonrevenueFlightHours = 'NonrevenueFlightHours',
  RevenueFlightHours = 'RevenueFlightHours',
  Holds = 'Holds',
  ContractDates = 'ContractDates',
  AircraftType = 'AircraftType',
  FlightPilotInCommand = 'FlightPilotInCommand',
  FlightSecondInCommand = 'FlightSecondInCommand',
  StartAirport = 'StartAirport',
  EndAirport = 'EndAirport',
  TailNumber = 'TailNumber',
  Registration = 'Registration',
  InitialAttack = 'InitialAttack',
  HobbsTime= 'HobbsTime',
  RetdH2O= 'RetdH2O',
  ExtendedStandby= 'ExtendedStandby',
  SecondMech= 'SecondMech',
  FuelAdded='FuelAdded',
  TRM='TRM'
}

export enum CompanyStatement {
  ServiceabilityChangeStatement = 'ServiceabilityChangeStatement',
  ServiceabilityChangeStatementPart1 = 'ServiceabilityChangeStatementPart1',
  ServiceabilityChangeStatementPart2 = 'ServiceabilityChangeStatementPart2',
  SaveMaintenancePart1 = 'SaveMaintenancePart1',
  SaveMaintenancePart2 = 'SaveMaintenancePart2',
  RemoteMaintenanceStatement = 'RemoteMaintenanceStatement',
  CommitChangesConfirmation = 'CommitChangesConfirmation',
  MaintenanceReleaseVerification = 'MaintenanceReleaseVerification',
  RemoteSessionConfirmChangesModalTitle = 'RemoteSessionConfirmChangesModalTitle',
  RectificationInDocuments = 'RectificationInDocuments',
}
