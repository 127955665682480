import React from 'react';
import { scheduleActions } from '../../redux-saga/actions';
import { PeriodPicker } from '../CrewSchedulingNavigation/PeriodPicker';
import { Button, Divider, Drawer, Form, Layout, Popover } from 'antd';
import ActionsDropdown from '../../helper';
import { CrewSchedulingFilters } from '../CrewSchedulingFilters';
import { useDispatch, useSelector } from 'react-redux';
import { getIsDrawerVisible, getIsFilterDrawerPinned } from '../../redux-saga/selectors';
import { isDisplayFilters } from '../utils';
import { getCompanySettings, getUserRole } from '../../../../../../redux/selectors';
import { PushpinTwoTone } from '@ant-design/icons';
import './filterDrawer.css';

const { Sider } = Layout;

export const FilterDrawer = () => {
  const dispatch = useDispatch();
  const isDrawerVisible = useSelector(getIsDrawerVisible);
  const isFilterPinned = useSelector(getIsFilterDrawerPinned);
  const userRoles = useSelector(getUserRole);
  const companySettings = useSelector(getCompanySettings);

  const togglePin = () => {
    dispatch(scheduleActions.setIsFilterDrawerPinned(!isFilterPinned));
  };

  if (isFilterPinned) {
    return (
      <>
        <Sider width="400px" className="sider">
          <div className="inner-sider">
            <span style={{ fontSize: '18px', fontWeight: '600' }}>Filters</span>
            <Popover content="Click to unpin">
              <Button
                icon={<PushpinTwoTone twoToneColor="#fff" />}
                onClick={togglePin}
                type="primary"
                style={{ borderRadius: '4px' }}
              />
            </Popover>
          </div>
          <div style={{ padding: '20px' }}>
            <PeriodPicker />
            <Divider />
            <ActionsDropdown />
            <Divider />
            {isDisplayFilters(userRoles, companySettings) && (
              <div style={{ marginTop: 16 }}>
                <Form layout="vertical">
                  <CrewSchedulingFilters />
                </Form>
              </div>
            )}
          </div>
        </Sider>
      </>
    );
  }

  return (
    <Drawer
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span style={{ fontSize: '18px', fontWeight: '600' }}>Filters</span>
          <Popover content="Click to pin">
            <Button icon={<PushpinTwoTone />} onClick={togglePin} />
          </Popover>
        </div>
      }
      placement="right"
      onClose={() => dispatch(scheduleActions.setIsDrawerVisible(false))}
      open={isDrawerVisible}
    >
      <PeriodPicker />
      <Divider />
      <ActionsDropdown />
      <Divider />
      {isDisplayFilters(userRoles, companySettings) && (
        <div style={{ marginTop: 16 }}>
          <Form layout="vertical">
            <CrewSchedulingFilters />
          </Form>
        </div>
      )}
    </Drawer>
  );
};
