import { CompanySettings } from '../../../../../common/types/Company';

export const isDisplayFilters = (userRoles: string[], companySettings: CompanySettings) => {
  if (userRoles.includes('admin') || userRoles.includes('subContractor')) {
    return true;
  }

  // for pilot role show all filters
  if (
    userRoles?.includes('crewScheduleViewer') ||
    userRoles?.includes('taggedScheduleViewer') ||
    companySettings?.PilotCanViewAllPilotsCrewSchedule
  ) {
    if (userRoles.includes('pilot') || !userRoles.includes('admin')) {
      return true;
    }
  }

  if (userRoles.includes('viewOnly') && !userRoles.includes('pilot')) {
    return true;
  }

  return false;
};
