import { ReFetchMXAircraftScheduleEntries, scheduleActions } from '../../actions';
import {
  selectMXAircraftScheduleTLEntriesSaga, selectTimelineBufferPeriod,
  selectVisibleTimelinePeriodSaga,
} from '../../selectors-saga';
import { call, put } from 'redux-saga/effects';
import { AircraftTimelineEntryType } from '../../../../../../../common/types/timeline/AircraftTimelineEntryType';
import { selectCompanyIDSaga } from '../../../../../../../common/saga/selectors';
import api from '../../../../../../../services/api';
import { AircraftTimelineEntry } from '../../../../../../../common/types/timeline/AircraftTimelineEntry';
import _ from 'lodash';
import { NEW_ITEM_ID } from '../../../components/Timeline/constants';

export function* reFetchMXAircraftScheduleEntries(action: ReFetchMXAircraftScheduleEntries) {
  const { aircraftIDs } = action.payload;

  const bufferPeriod = yield* selectTimelineBufferPeriod();

  const companyID = yield* selectCompanyIDSaga();

  const baseApiParams = {
    end_time__gt: bufferPeriod.getStart().format(),
    start_time__lt: bufferPeriod.getEnd().format(),
    limit: -1,
    user_ids: [],
  };

  try {
    const {
      data: { Data },
    } = yield call(
      api.get,
      `/v1/companies/${companyID}/timeline/${AircraftTimelineEntryType.MaintenanceAircraftSchedule}`,
      {
        params: baseApiParams,
      },
    );

    const entriesArr = Data as AircraftTimelineEntry[];
    const existedMXEntries = yield* selectMXAircraftScheduleTLEntriesSaga();
    const filteredMXEntries = existedMXEntries.filter(e => {
      if (e.VirtualID === NEW_ITEM_ID) {
        return false;
      }
      if (!aircraftIDs?.length) {
        return false;
      }
      return !aircraftIDs?.includes(e.AssignedAircraftIDs?.[0]);
    });

    const newEntries = _.unionBy(
      filteredMXEntries,
      entriesArr,
      'VirtualID',
    );

    yield put(
      scheduleActions.setState({
        mxAircraftScheduleTimelineEntries: newEntries,
      }),
    );
  } catch (err) {
    console.log(`Error fetching ${AircraftTimelineEntryType.MaintenanceAircraftSchedule} entries: `, err);
  }
}
