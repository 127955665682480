import { all, takeEvery } from 'redux-saga/effects';
import { PusherActionType } from '../../pusher/PusherActions';
import { trackedItemCreated } from './workers/trackedItemCreated';
import { trackedItemUpdated } from './workers/trackedItemUpdated';
import { trackedItemDeleted } from './workers/trackedItemDeleted';

export default function* rootSaga() {
  yield all([
    takeEvery(PusherActionType.TRACKED_ITEM_CREATED, trackedItemCreated),
    takeEvery(PusherActionType.TRACKED_ITEM_UPDATED, trackedItemUpdated),
    takeEvery(PusherActionType.TRACKED_ITEM_DELETED, trackedItemDeleted),
  ])
}
