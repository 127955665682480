import { AnyAction } from 'redux';
import { actionTypes } from './actions';
import { ModalMode } from '../../../../../../../../redux/pages/crew-scheduling-v3/modals/interfaces';
import MODAL_MODE from '../../../../../../common/constants';
import { ScheduleTemplate } from '../../../../../../../../common/types/ScheduleTemplate';

export interface ScheduleTemplateModalState {
  scheduleTemplateModalMode: ModalMode;
  editScheduleTemplate: ScheduleTemplate | null;
}

const initialState: ScheduleTemplateModalState = {
  scheduleTemplateModalMode: { mode: MODAL_MODE.create, open: false },
  editScheduleTemplate: null,
};

export default function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case actionTypes.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
