import { ScheduleTemplateModalState } from './reducers';
import { GenericAction } from '../../../../../../../../common/types/redux/actions';
import { ModalMode } from '../../../../../../../../redux/pages/crew-scheduling-v3/modals/interfaces';
import { ScheduleTemplate } from '../../../../../../../../common/types/ScheduleTemplate';
import { SaveScheduleTemplateRequest, ScheduleRequestForm } from './types';

export const actionTypes = {
  SET_STATE: 'schedule-template-modal/SET_STATE',
  BOOK_SCHEDULE: 'schedule-template-modal/BOOK_SCHEDULE',
  APPROVE_SCHEDULE: 'schedule-template-modal/APPROVE_SCHEDULE',
  REJECT_SCHEDULE: 'schedule-template-modal/REJECT_SCHEDULE',
  DELETE_SCHEDULE: 'schedule-template-modal/DELETE_SCHEDULE',
  SAVE_SCHEDULE_TEMPLATE: 'schedule-template-modal/SAVE_SCHEDULE_TEMPLATE',
};

export interface SetStateAction extends GenericAction {
  payload: Partial<ScheduleTemplateModalState>;
}

export interface SetModalModeAction extends GenericAction {
  payload: { scheduleTemplateModalMode: ModalMode };
}

export interface SetEditScheduleTemplateAction extends GenericAction {
  payload: { editScheduleTemplate: ScheduleTemplate | null };
}

export interface BookScheduleRequestAction extends GenericAction {
  payload: { requestForm: ScheduleRequestForm; scheduleTemplateID: string };
}

export interface ApproveScheduleRequestAction extends GenericAction {
  payload: { scheduleTemplateID: string };
}

export interface DeleteScheduleRequestAction extends GenericAction {
  payload: { scheduleTemplateID: string };
}
export interface SaveScheduleTemplateAction extends GenericAction {
  payload: { scheduleTemplate: SaveScheduleTemplateRequest; scheduleTemplateID: string };
}

export interface RejectScheduleRequestAction extends ApproveScheduleRequestAction {}

export const scheduleTemplateModalActions = {
  setState: (payload: Partial<ScheduleTemplateModalState>): SetStateAction => ({
    type: actionTypes.SET_STATE,
    payload,
  }),
  setScheduleTemplateModalMode: (scheduleTemplateModalMode: ModalMode): SetModalModeAction => ({
    type: actionTypes.SET_STATE,
    payload: { scheduleTemplateModalMode },
  }),
  setEditScheduleTemplate: (
    editScheduleTemplate: ScheduleTemplate | null,
  ): SetEditScheduleTemplateAction => ({
    type: actionTypes.SET_STATE,
    payload: { editScheduleTemplate },
  }),
  bookSchedule: (
    requestForm: ScheduleRequestForm,
    scheduleTemplateID: string,
  ): BookScheduleRequestAction => ({
    type: actionTypes.BOOK_SCHEDULE,
    payload: { requestForm, scheduleTemplateID },
  }),
  approveSchedule: (scheduleTemplateID: string): ApproveScheduleRequestAction => ({
    type: actionTypes.APPROVE_SCHEDULE,
    payload: { scheduleTemplateID },
  }),
  rejectSchedule: (scheduleTemplateID: string): RejectScheduleRequestAction => ({
    type: actionTypes.REJECT_SCHEDULE,
    payload: { scheduleTemplateID },
  }),
  deleteSchedule: (scheduleTemplateID: string): DeleteScheduleRequestAction => ({
    type: actionTypes.DELETE_SCHEDULE,
    payload: { scheduleTemplateID },
  }),
  saveScheduleTemplate: (
    scheduleTemplate: SaveScheduleTemplateRequest,
    scheduleTemplateID: string,
  ): SaveScheduleTemplateAction => ({
    type: actionTypes.SAVE_SCHEDULE_TEMPLATE,
    payload: { scheduleTemplate, scheduleTemplateID },
  }),
};
