import { delay, put, select } from "redux-saga/effects";
import { SetVisibleTimelinePeriodAction, scheduleActions, default as actions } from '../../actions';
import {getIsLoadingEntriesQuietly, getLoadedTimelineBufferPeriod} from "../../selectors";
import { selectVisibleTimelinePeriodSaga } from "../../selectors-saga";

export function* watchSetVisibleTimelinePeriod(action: SetVisibleTimelinePeriodAction) {
  let isStillLoading = yield select(getIsLoadingEntriesQuietly);
  if (isStillLoading && (yield* isBufferPeriodLimitVisible()) ) {
    yield put(scheduleActions.setState({ isLoadingMoreEntries: true, isTimelineLoading: true }));
    while(true) {
      yield delay(10);
      isStillLoading = yield select(getIsLoadingEntriesQuietly);
      if (!isStillLoading) {
        yield put(scheduleActions.setState({ isLoadingMoreEntries: false, isTimelineLoading: false }));
        break;
      }
    }
  }
}

function* isBufferPeriodLimitVisible() {
  const visibleTimelinePeriod = yield* selectVisibleTimelinePeriodSaga();
  const loadedTimelineBufferPeriod = yield select(getLoadedTimelineBufferPeriod);

  if (loadedTimelineBufferPeriod) {
    if (
      // timeline buffer start is visible
      (visibleTimelinePeriod.getStart().isBefore(loadedTimelineBufferPeriod.getStart()) &&
        visibleTimelinePeriod.getEnd().isAfter(loadedTimelineBufferPeriod.getStart())) ||
      // timeline buffer end is visible
      (visibleTimelinePeriod.getStart().isBefore(loadedTimelineBufferPeriod.getEnd()) &&
        visibleTimelinePeriod.getEnd().isAfter(loadedTimelineBufferPeriod.getEnd())) ||
      // visible timeline is bigger than the buffer—resize buffer and fetch with loading bar
      (visibleTimelinePeriod.getStart().isBefore(loadedTimelineBufferPeriod.getStart()) &&
        visibleTimelinePeriod.getEnd().isAfter(loadedTimelineBufferPeriod.getEnd()))
    ) {
      return true;
    }
  }


  return false;

}

