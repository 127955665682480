export const actionTypes = {
  SET_STATE: 'pages/aircraft-log/SET_STATE',
  ADD_LOG_ATTACHMENT_REQEST: 'pages/aircraft-log/ADD_LOG_ATTACHMENT_REQEST',
  ADD_LOG_ATTACHMENT_SUCCESS: 'pages/aircraft-log/ADD_LOG_ATTACHMENT_SUCCESS',
  ADD_LOG_ATTACHMENT_ERROR: 'pages/aircraft-log/ADD_LOG_ATTACHMENT_ERROR',

  DELETE_LOG_ATTACHMENT_REQEST: 'pages/aircraft-log/DELETE_LOG_ATTACHMENT_REQEST',
  DELETE_LOG_ATTACHMENT_SUCCESS: 'pages/aircraft-log/DELETE_LOG_ATTACHMENT_SUCCESS',
  DELETE_LOG_ATTACHMENT_ERROR: 'pages/aircraft-log/DELETE_LOG_ATTACHMENT_ERROR',

  EDIT_LOG_ATTACHMENT_REQEST: 'pages/aircraft-log/EDIT_LOG_ATTACHMENT_REQEST',
  EDIT_LOG_ATTACHMENT_SUCCESS: 'pages/aircraft-log/EDIT_LOG_ATTACHMENT_SUCCESS',
  EDIT_LOG_ATTACHMENT_ERROR: 'pages/aircraft-log/EDIT_LOG_ATTACHMENT_ERROR',

  NO_CHANGES_IN_REMOTE_SESSION: 'pages/aircraft-log/pages/aircraft-log/EDIT_LOG_ATTACHMENT_ERROR',
  DISPLAY_REMOTE_SESSION_ALERT: 'pages/aircraft-log/pages/aircraft-log/DISPLAY_REMOTE_SESSION_ALERT',
  FETCH_LOG_REVIEWS: 'pages/aircraft-log/Review/ReviewModal/FETCH_LOG_REVIEWS',
  FETCH_LOG_REVIEW_TYPES: 'pages/aircraft-log/Review/ReviewModal/FETCH_LOG_REVIEW_TYPES',
};
