import { DeleteScheduleRequestAction, scheduleTemplateModalActions } from '../actions';
import { put } from 'redux-saga/effects';
import api from '../../../../../../../../../services/api';
import MODAL_MODE from '../../../../../../../common/constants';
import { scheduleActions } from '../../../../../redux-saga/actions';

export function* deleteScheduleTemplate(action: DeleteScheduleRequestAction) {
  try {
    const { scheduleTemplateID } = action.payload;
    yield api.delete(`/v1/schedule-template/${scheduleTemplateID}`);

    yield put(
      scheduleTemplateModalActions.setScheduleTemplateModalMode({
        mode: MODAL_MODE.create,
        open: false,
      }),
    );
    yield put(scheduleActions.reFetchAllEntries());
  } catch (e) {
    console.error('book schedule err', e);
  }
}
