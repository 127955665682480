import { all, takeEvery } from 'redux-saga/effects';
import { actionTypes } from './actions';
import { bookSchedule } from './workers/bookSchedule';
import { approveSchedule, rejectSchedule } from './workers/approveRejectSchedule';
import { deleteScheduleTemplate } from './workers/deleteScheduleTemplate';
import { saveScheduleTemplate } from './workers/saveScheduleTemplate';

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.BOOK_SCHEDULE, bookSchedule)]);
  yield all([takeEvery(actionTypes.APPROVE_SCHEDULE, approveSchedule)]);
  yield all([takeEvery(actionTypes.REJECT_SCHEDULE, rejectSchedule)]);
  yield all([takeEvery(actionTypes.DELETE_SCHEDULE, deleteScheduleTemplate)]);
  yield all([takeEvery(actionTypes.SAVE_SCHEDULE_TEMPLATE, saveScheduleTemplate)]);
}
