import actions from './actions';
import { Pilot } from '../../../common/types/pilot';

export interface TrainingItemOverviewInitialState {
  filterName: string;
  showOnlyPilots: boolean;
  showOnlyAMEs: boolean;
  users: Pilot[]
}

const initialState: TrainingItemOverviewInitialState = {
  filterName: null,
  showOnlyAMEs: false,
  showOnlyPilots: false,
  users: null
};

export default function reducers(state: TrainingItemOverviewInitialState = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
