import { GenericAction } from '../../../../../../../../common/types/redux/actions';
import { BulkMaintenanceScheduleModalValuesForm } from '../../../types';
import MODAL_MODE from '../../../../../../common/constants';
import { SaveError } from './types';
import { ModalMode } from '../../../../../../../../redux/pages/crew-scheduling-v3/modals/interfaces';
import { BulkMaintenanceScheduleGroupedByField } from '../Base/types';

const actions = {
  SET_STATE: 'BulkMaintenanceScheduleModal/SET_STATE',
  SET_SAVE_ERROR: 'BulkMaintenanceScheduleModal/SET_SAVE_ERROR',
  SAVE_GROUPED_MX_ENTRY: 'BulkMaintenanceScheduleModal/SAVE_GROUPED_MX_ENTRY',
  DELETE_GROUPED_MX_ENTRY: 'BulkMaintenanceScheduleModal/DELETE_GROUPED_MX_ENTRY',
  SET_MX_AIRCRAFT_MODAL_MODE: 'BulkMaintenanceScheduleModal/SET_MX_AIRCRAFT_MODAL_MODE',
  SET_MX_CONTRACT_MODAL_MODE: 'BulkMaintenanceScheduleModal/SET_MX_CONTRACT_MODAL_MODE',
};

export interface SaveMXGroupedEntryAction extends GenericAction {
  payload: {
    formValues: BulkMaintenanceScheduleModalValuesForm;
    mode: MODAL_MODE;
    groupedBy: BulkMaintenanceScheduleGroupedByField;
  };
}

const saveVirtualMXScheduleContractEntry = (
  formValues: BulkMaintenanceScheduleModalValuesForm,
  mode: MODAL_MODE,
  groupedBy: BulkMaintenanceScheduleGroupedByField,
): SaveMXGroupedEntryAction => {
  return {
    type: actions.SAVE_GROUPED_MX_ENTRY,
    payload: { formValues, mode, groupedBy },
  };
};

const setIsSaving = (isSaving: boolean) => ({
  type: actions.SET_STATE,
  payload: { isSaving },
});

export interface SaveErrorAction extends GenericAction {
  payload: {
    saveError: SaveError | null;
  };
}

const setSaveError = (saveError: SaveError | null): SaveErrorAction => ({
  type: actions.SET_SAVE_ERROR,
  payload: { saveError },
});

export interface DeleteMXGroupedEntryAction extends GenericAction {
  payload: {
    maintenanceScheduleIDs: string[];
    relatedAircraftIDs: string[];
  };
}

const deleteVirtualMXScheduleContractEntry = (
  maintenanceScheduleIDs: string[],
  relatedAircraftIDs: string[],
): DeleteMXGroupedEntryAction => ({
  type: actions.DELETE_GROUPED_MX_ENTRY,
  payload: { maintenanceScheduleIDs, relatedAircraftIDs },
});

const setMXAircraftModalMode = (mxAircraftBulkScheduleModalMode: ModalMode) => ({
  type: actions.SET_MX_AIRCRAFT_MODAL_MODE,
  payload: { mxAircraftBulkScheduleModalMode },
});

const setMXContractModalMode = (mxContractBulkScheduleModalMode: ModalMode) => ({
  type: actions.SET_MX_CONTRACT_MODAL_MODE,
  payload: { mxContractBulkScheduleModalMode },
});

export const bulkMaintenanceModalEntryActions = {
  saveVirtualMXScheduleContractEntry,
  setIsSaving,
  setSaveError,
  deleteVirtualMXScheduleContractEntry,
  setMXAircraftModalMode,
  setMXContractModalMode,
};

export default actions;
