import MODAL_MODE from '../../../../../common/constants';
import { GenericAction } from '../../../../../../../common/types/redux/actions';
import { MTSDataType } from '../type';

const actions = {
  SET_STATE: 'components/mts-schedule/SET_STATE',
  SET_MODAL_OPEN: 'components/mts-schedule/SET_MODAL_OPEN',
  SET_MODAL_CLOSE: 'components/mts-schedule/SET_MODAL_CLOSE',
  SET_EDIT_ENTRY: 'components/mts-schedule/SET_EDIT_ENTRY',
  SET_CURRENT_MTS_ENTRY: 'components/mts-schedule/SET_CURRENT_MTS_ENTRY',
  SET_CURRENT_RELATED_CS_ENTRY_IDS: 'components/mts-schedule/SET_CURRENT_RELATED_CS_ENTRY_IDS',
};

export interface OpenMTSModalAction {
  type: typeof actions.SET_MODAL_OPEN;
  payload: { mode: MODAL_MODE };
}

export interface CloseMTSModalAction {
  type: typeof actions.SET_MODAL_CLOSE;
}

export interface SetCurrentMTSEntryAction extends GenericAction {
  type: typeof actions.SET_CURRENT_MTS_ENTRY;
  payload: { currentMTSEntry: MTSDataType };
}

export interface SetEditEntryAction extends GenericAction {
  type: typeof actions.SET_EDIT_ENTRY;
  payload: { crewScheduleGroupID: string };
}

export interface SetCurrentRelatedCSEntryIDsAction extends GenericAction {
  type: typeof actions.SET_CURRENT_RELATED_CS_ENTRY_IDS;
  payload: { currentRelatedCSEntryIDs: { [key: string]: string[] } };
}

export const setCurrentRelatedCSEntryIDs = (
  currentRelatedCSEntryIDs: { [key: string]: string[] },
): SetCurrentRelatedCSEntryIDsAction => ({
  type: actions.SET_CURRENT_RELATED_CS_ENTRY_IDS,
  payload: { currentRelatedCSEntryIDs },
});

export const openMTSModal = (mode: MODAL_MODE): OpenMTSModalAction => ({
  type: actions.SET_MODAL_OPEN,
  payload: { mode },
});

export const closeMTSModal = (): CloseMTSModalAction => ({
  type: actions.SET_MODAL_CLOSE,
});

export const setCurrentMTSEntry = (currentMTSEntry: MTSDataType): SetCurrentMTSEntryAction => ({
  type: actions.SET_CURRENT_MTS_ENTRY,
  payload: { currentMTSEntry },
});

export const setEditEntry = (crewScheduleGroupID: string): SetEditEntryAction => ({
  type: actions.SET_EDIT_ENTRY,
  payload: { crewScheduleGroupID },
});

export default actions;
