import MODAL_MODE from '../../../pages/data/common/constants';
import { Certificate } from '../../../pages/data/organization/tracked-items/types';

const actions = {
  OPEN_MODAL: 'pages/certificates/OPEN_MODAL',
  CLOSE_MODAL: 'pages/certificates/CLOSE_MODAL',
  SET_CERTIFICATES: 'pages/certificates/SET_CERTIFICATES',
  SET_FILTER_NAME: 'pages/certificates/SET_FILTER_NAME',
  SET_FILTER_REFERENCE: 'pages/certificates/SET_FILTER_REFERENCE',
};

export interface OpenCertificateModalAction {
  type: typeof actions.OPEN_MODAL;
  payload: { mode: MODAL_MODE };
}

export interface CloseCertificateModalAction {
  type: typeof actions.CLOSE_MODAL;
}

export interface SetCertificatesAction {
  type: typeof actions.SET_CERTIFICATES;
  payload: {
    certificates: Certificate[];
  };
}

export interface SetFilterNameAction {
  type: typeof actions.SET_FILTER_NAME;
  payload: {
    filterName: string;
  };
}

export interface SetFilterReferenceAction {
  type: typeof actions.SET_FILTER_REFERENCE;
  payload: {
    filterReference: string;
  };
}

export const setCertificatesAction = (certificates: Certificate[]): SetCertificatesAction => ({
  type: actions.SET_CERTIFICATES,
  payload: { certificates },
});

export const setFilterNameAction = (filterName: string): SetFilterNameAction => ({
  type: actions.SET_FILTER_NAME,
  payload: { filterName },
});

export const setFilterReferenceAction = (filterReference: string): SetFilterReferenceAction => ({
  type: actions.SET_FILTER_REFERENCE,
  payload: { filterReference },
});

export const openCertificateModalAction = (mode: MODAL_MODE): OpenCertificateModalAction => ({
  type: actions.OPEN_MODAL,
  payload: { mode },
});

export const closeCertificateModalAction = (): CloseCertificateModalAction => ({
  type: actions.CLOSE_MODAL,
});

export default actions;
