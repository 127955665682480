export const PUBLIC_ROUTES = ['/user/login', '/user/forgot', '/user/reset-password'];

export const NA = 'N/A';

export const MAX_MOBILE_WIDTH = 1025

export const IDs = {
  email: 'log-in-email',
  password: 'log-in-password',
  crewScheduleTitle: 'crew-schedule-title',
  crewScheduleSelector: 'crew-member-selector',
  crewCertificationSelector: 'crew-certification-selector',
  maintenanceCertificationSelector: 'maintenance-certification-selector',
  crewMemberBaseSelector: 'crew-member-base-selector',
  displayOptionsSelector: 'display-options-selector',
  scheduleGenerator: 'generate-schedule-btn',
  addPilotContract: 'add-pilot-contract',
  addScheduleEntry: 'add-schedule-entry',
  addPlannedDuty: 'add-planned-duty',
  addActualDuty: 'add-actual-duty',
  scheduleDayBtn: 'schedule-day-btn',
  scheduleWeekBtn: 'schedule-week-btn',
  scheduleMonthBtn: 'schedule-month-btn',
  schedule3MonthBtn: 'schedule-3-month-btn',
  scheduleYearBtn: 'schedule-year-btn',
  jumpToDateInput: 'jump-to-date-input',
  versionsBtn: 'versions-btn',
  prevDateBtn: 'prev-date-btn',
  nextDateBtn: 'next-date-btn',
  sortCrewBySelector: 'sort-crew-by-selector',
  sortCrewByFirstNameOption: 'sort-crew-by-first-name-option',
  sortCrewByLastNameOption: 'sort-crew-by-last-name-option',
  sortCrewByCertificationTypeNameOption: 'sort-crew-by-certification-type-option',
  crewSelector: 'crew-selector',
  crewScheduleEntry: 'crew-schedule',
  bulkScheduleEntry: 'aircraft-schedule',
  dutyTimeEntry: 'duty-entry',
  dutyScheduleEntry: 'duty-schedule-entry',
  aircraftContractEntry: 'contract-period',
  pilotContractEntry: 'contract-period',
  selectTimeIcon: 'select-time-icon',
  userIconBtn: 'user-icon-btn',
  logoutBtn: 'log-out-btn',
  validationError: 'ant-form-item-explain-error',
  crewScheduleNotes: 'crew-schedule-notes-popover',
  maintenanceScheduleNotes: 'maintenance-schedule-notes-popover',
  bankIcon: 'anticon-bank',
  showInCsv2: 'ShowInCSV2',
  showInMXSchedule: 'ShowInMXSchedule',
  bulkScheduleModal: {
    assignedAircraftSelector: 'bulk-schedule-assigned-aircraft-selector',
    startAirportSelector: 'bulk-schedule-start-airport-selector',
    endAirportSelector: 'bulk-schedule-end-airport-selector',
    pilotSelector: 'bulk-schedule-pilot-selector',
    dutyStartTimePicker: 'bulk-schedule-duty-start-time-picker',
    dutyEndTimePicker: 'bulk-schedule-duty-end-time-picker',
    notesTextArea: 'bulk-schedule-notes-textarea',
    subpartSelector: 'bulk-schedule-subpart-selector',
    saveBtn: 'save-group-schedule-btn',
    deleteCrewScheduleBtn: 'delete-crew-schedule-btn',
    removeBulkScheduleEntryBtn: 'remove-bulk-schedule-entry-btn',
    clearDateBtn: 'bulk-schedule-clear-date-btn',
  },
  bulkMaintenanceScheduleModal: {
    assignedAircraftSelector: 'bulk-maintenance-schedule-assigned-aircraft-selector',
    maintenanceSelector: 'bulk-maintenance-schedule-maintenance-selector',
    dutyStartTimePicker: 'bulk-maintenance-schedule-duty-start-time-picker',
    dutyEndTimePicker: 'bulk-maintenance-schedule-duty-end-time-picker',
    notesTextArea: 'bulk-maintenance-schedule-notes-textarea',
    saveBtn: 'save-maintenance-group-schedule-btn',
    deleteMaintenanceScheduleBtn: 'delete-maintenance-schedule-btn',
    removeBulkMaintenanceSchedEntryBtn: 'remove-bulk-maintenance-schedule-entry-btn',
    clearDateBtn: 'bulk-maintenance-schedule-clear-date-btn',
  },
  pilotContractModal: {
    pilotContractGenerator: 'pilot-contract-generator',
    pilotSelector: 'pilot-contract-aircraft-selector',
    rangeStartPicker: 'pilot-contract-range-start-picker',
    saveButton: 'pilot-contract-save-btn',
    clearDateIcon: 'pilot-contract-clear-date-icon',
    deleteEntry: 'pilot-contract-delete-entry',
    companyClientSelector: 'pilot-contract-client-selector',
  },
  aircraftContractModal: {
    aircraftContractGenerator: 'aircraft-contract-generator',
    aircraftSelector: 'aircraft-contract-aircraft-selector',
    rangeStartPicker: 'range-start-picker',
    rangeEndPicker: 'range-end-picker',
    saveButton: 'save-btn',
    clearDateIcon: 'clear-date-icon',
    deleteEntry: 'delete-entry',
  },
  crewScheduleModal: {
    scheduleEntryType: 'crew-schedule-entry-type',
    crewScheduleGenerator: 'crew-schedule-generator',
    pilotSelector: 'crew-schedule-pilot-selector',
    dutyStartTimePicker: 'crew-schedule-duty-start-time-picker',
    dutyEndTimePicker: 'crew-schedule-duty-end-time-picker',
    startAirportSelector: 'crew-schedule-start-airport-selector',
    endAirportSelector: 'crew-schedule-end-airport-selector',
    subpartSelector: 'crew-schedule-subpart-selector',
    assignedAircraftSelector: 'crew-schedule-aircraft-selector',
    saveBtn: 'crew-schedule-save-btn',
    notesTextArea: 'crew-schedule-notes-textarea',
    clearDateBtn: 'crew-schedule-clear-btn',
    closeModalBtn: 'close-modal-btn',
    reloadPageBtn: 'reload-page-btn',
    tagSelector: 'schedule-entry-tag-selector',
    clientSelector: 'schedule-entry-client-selector',
    clientContractSelector: 'schedule-entry-client-contract-selector',
    popoverWarning: 'schedule-popover-warning',
  },
  maintenanceScheduleModal: {
    scheduleEntryType: 'maintenance-schedule-entry-type',
    maintenanceScheduleGenerator: 'maintenance-schedule-generator',
    engineerSelector: 'maintenance-schedule-engineer-selector',
    dutyStartTimePicker: 'maintenance-schedule-duty-start-time-picker',
    dutyEndTimePicker: 'maintenance-schedule-duty-end-time-picker',
    assignedAircraftSelector: 'maintenance-schedule-aircraft-selector',
    saveBtn: 'maintenance-schedule-save-btn',
    notesTextArea: 'maintenance-schedule-notes-textarea',
    clearDateBtn: 'maintenance-schedule-clear-btn',
    closeModalBtn: 'close-modal-btn',
    reloadPageBtn: 'reload-page-btn',
    tagSelector: 'maintenance-schedule-entry-tag-selector',
    clientSelector: 'maintenance-schedule-entry-client-selector',
    clientContractSelector: 'maintenance-schedule-entry-client-contract-selector',
    popoverWarning: 'schedule-popover-warning',
  },
  dutyTimeModal: {
    dutyTimePicker: 'duty-time-picker',
    dutyTimeGenerator: 'add-actual-duty',
    pilotSelector: 'duty-time-pilot-selector',
    dutyStartTime: 'duty-time-start-time',
    dutyEndTime: 'duty-time-end-time',
    subpartSelector: 'duty-time-subpart-selector',
    subpartItem: 'ant-select-overflow-item-suffix',
    saveBtn: 'duty-time-save-btn',
    notesTextarea: 'duty-time-notes-textarea',
    flyingDutyDay: 'duty-time-flying-duty-day-switcher',
    onReserve: 'on-reserve-switcher',
    unforeseenExtension: 'unforeseen-extension-switcher',
    ongoing: 'duty-time-ongoing-switcher',
    deleteBtn: 'duty-time-delete-btn',
    clearBtn: 'duty-time-clear-time-range-btn',
    flightDutyTimePicker: 'flight-duty-time-picker',
    popoverWarning: 'duty-time-popover-warning',
    splitDuty: 'split-duty-checker',
  },
  dutyScheduleModal: {
    dutyScheduleGenerator: 'add-planned-duty',
    pilotSelector: 'duty-schedule-pilot-selector',
    dutyStartTime: 'duty-schedule-start-time',
    dutyEndTime: 'duty-schedule-end-time',
    flightDutyEndTime: 'duty-schedule-flight-duty-end-time',
    subpartSelector: 'duty-schedule-subpart-selector',
    subpartValue: 'subpart-value',
    cancelBtn: 'cancel-button',
    closeIcon: 'close-icon',
    saveBtn: 'duty-schedule-save-btn',
    flyingDutyDay: 'duty-schedule-flying-duty-day-switcher',
    notesTextarea: 'duty-schedule-notes-textarea',
    positioningAfterMaxFDP: 'duty-schedule-positioning-after-max-fdp-switcher',
    onReserve: 'duty-schedule-on-reserve-switcher',
    deleteBtn: 'duty-schedule-delete-btn',
    clearBtn: 'duty-schedule-clear-btn',
    popoverWarning: 'duty-schedule-popover-warning',
  },
  clientContractPeriodModal: {
    rangePicker: 'client-contract-range-picker',
    saveButton: 'client-contract-save-btn',
    deleteEntry: 'client-contract-delete-entry',
  },
  filters: {
    crewMemberSelector: 'crew-member-selector',
    maintenanceMemberSelector: 'maintenance-member-selector',
    crewCertificationSelector: 'crew-certification-selector',
    maintenanceCertificationSelector: 'maintenance-certification-selector',
    crewMemberBaseSelector: 'crew-member-base-selector',
    maintenanceMemberBaseSelector: 'maintenance-member-base-selector',
    aircraftSelector: 'aircraft-selector',
    aircraftTypeSelector: 'aircraft-type-selector',
    displayOptionsSelector: 'display-options-selector',
    sortCrew: 'sort-crew-selector',
    sortMaintenance: 'sort-maintenance-selector',
    displayCrew: 'display-crew-checkbox',
    displayAircraft: 'display-aircraft-checkbox',
    tagsSelector: 'tags-selector',
    clientSelector: 'client-selector',
    clientContractSelector: 'client-contract-selector',
  },
  dateSelectors: {
    todayBtn: 'ant-picker-today-btn',
    daySelector: 'day-selector',
    weekSelector: 'week-selector',
    monthSelector: 'month-selector',
    threeMonthSelector: 'three-month-selector',
    yearSelector: 'year-selector',
    prevDateBtn: 'prev-date-btn',
    nextDateBtn: 'next-date-btn',
    jumpToDate: 'ant-picker-today-btn',
    clearJumpToDateInput: 'clear-jump-to-date-input',
  },
  versioning: {
    versionsBtn: 'versions-btn',
    switchToDraftBtn: 'switch-to-draft-btn',
    viewVersionBtn: 'view-version-btn',
    publishCurrentDraftTab: 'publish-current-draft-tab-btn',
    versionName: 'version-name-input',
    versionNotes: 'version-notes-textarea',
    userSelector: 'user-selector',
    startTimePicker: 'version-range-start-time',
    endTimePicker: 'version-range-end-time',
    publishVersionBtn: 'publish-version-btn',
    closeVersions: 'close-versions-btn',
    publishedVersionsTab: 'published-versions-tab-btn',
    reviewChanges: 'review-changes-btn',
    mergeSandbox: 'merge-sandbox-btn',
    sandboxTab: 'sandbox-tab-btn',
    createSandbox: 'create-sandbox-btn',
    viewSandbox: 'view-sandbox-btn',
    cloneSandbox: 'clone-sandbox-btn',
    confirmClone: 'confirm-create-clone-btn',
    currentlyViewing: 'currently-viewing-version',
  },
  detailedFlightTimes: {
    addBtn: 'add-detailed-flight-time-entry-btn',
    IFRCheck: 'ifr-checkbox',
    timeOn: 'timeOn',
    timeOff: 'timeOff',
    airTime: 'airTime',
    flightTime: 'flightTime',
    totalLegs: 'total-legs-input',
    flightHours: 'flight-hours-input',
    IFRHours: 'ifr-hours-input',
    holds: 'holds-input',
    VFRHours: 'vfr-hours-input',
    insertBefore: 'insert-before-btn',
    deleteRow: 'delete-detailed-flight-time-row-btn',
    aircraftSelector: 'detailed-flight-times-aircraft-selector',
    addTab: 'add-tab-detailed-flight-times',
    tableRow: 'table-row',
    tabs: 'ant-tabs-tab-btn',
    activeTabPane: 'ant-tabs-tabpane-active',
    addTabDutyTime: 'ant-tabs-nav-add',
    errorMsg: 'ant-alert',
    removeTabIcon: 'remove-tab-icon',
  },
  timeline: {
    contextDeleteCrewScheduleEntry: 'context-delete-crew-schedule-entry',
    contextDeleteBulkScheduleEntry: 'context-delete-bulk-schedule-entry',
    contextDeleteBulkMXScheduleEntry: 'context-delete-bulk-mx-schedule-entry',
    contextDeleteDutyScheduleEntry: 'context-delete-duty-schedule-entry',
    contextDeleteMaintenanceEntry: 'context-delete-maintenance-entry',
    contextBulkDeleteCrewScheduleEntry: 'context-bulk-delete-crew-schedule-entry',
    contextBulkDeleteMXScheduleEntry: 'context-bulk-delete-mx-schedule-entry',
    contextBulkDeleteBulkScheduleEntry: 'context-bulk-delete-bulk-schedule-entry',
    contextBulkDeleteDutyScheduleEntry: 'context-bulk-delete-duty-schedule-entry',
    contextCopyScheduleEntry: 'context-copy-schedule-entry',
    contextCutScheduleEntry: 'context-cut-schedule-entry',
    contextBulkCopyScheduleEntry: 'context-bulk-copy-schedule-entry',
    contextBulkCopyMXScheduleEntry: 'context-bulk-copy-mx-schedule-entry',
    contextBulkCutScheduleEntry: 'context-bulk-cut-schedule-entry',
    contextBulkCutMXScheduleEntry: 'context-bulk-cut-mx-schedule-entry',
    contextCopyDutyScheduleEntry: 'context-copy-duty-schedule-entry',
    contextCopyMaintenanceEntry: 'context-copy-maintenance-entry',
    contextCutDutyScheduleEntry: 'context-cut-duty-schedule-entry',
    contextCutMaintenanceEntry: 'context-cut-maintenance-entry',
    contextBulkCopyDutyScheduleEntry: 'context-bulk-copy-duty-schedule-entry',
    contextBulkCutDutyScheduleEntry: 'context-bulk-cut-duty-schedule-entry',
    contextDeleteMeasuringTape: 'context-delete-measuring-tape',
    contextDeleteAircraftMeasuringTape: 'context-delete-aircraft-measuring-tape',
    pasteItem: 'ant-dropdown-menu-item',
    timeline: 'schedule-entry-timeline',
    sidebar: 'rct-sidebar-row',
    dateHeader: 'rct-dateHeader-primary',
  },
  crewSchedulingMobile: {
    addEntry: 'adm-button-shape-rounded',
    buttons: 'adm-button',
    pilotSelector: 'adm-list-item-content-main',
    startTimePicker: 'start-time-picker',
    endTimePicker: 'end-time-picker',
    startAirportSelectorCrewSchedule: 'start-airport-selector',
    endAirportSelectorCrewSchedule: 'end-airport-selector',
    subpartSelector: 'rc_select_2',
    subpartSelectorCrewSchedule: 'rc_select_10',
    aircraftSelector: 'rc_select_9',
    flyingDutyDayPicker: 'duty-day-picker',
    saveEntry: 'save-entry',
    goBack: 'go-back-btn',
    notesTextArea: 'notes-textarea',
    positioningAfterMaxFDP: 'pos-after-max-fdp-switcher',
    deleteEntry: 'delete-entry',
    editEntry: 'edit-entry',
    addFlightBtn: 'add-flight-btn',
    flightTimesList: 'flight-times-list',
    hoursPicker: 'hours-picker',
    hiddenStartTimeInput: 'hidden-start-time-input',
    hiddenEndTimeInput: 'hidden-end-time-input',
    detailedFlightTimeOffInput: 'time-off-input',
    detailedFlightTimeOnInput: 'time-on-input',
    dashboardEntry: 'EntryListItem_EntryListItem__2v6L5',
    inputElement: 'adm-input-element',
    switchers: 'adm-switch-inner',
    detailedFlightTimeInputs: 'ATNumInput_ATNumInput__disabledNumInput__1C7Vl',
    addTab: 'adm-tabs-tab',
    tab: 'adm-tabs-tab',
  },
  settingsPage: {
    clientsTab: 'clients',
    tagsTab: 'tags',
    addClient: 'add-client-btn',
    clientNameInput: 'client-name-input',
  },
  companyLibrary: {
    entitySelector: 'entity-selector',
    pilotSelector: 'pilot-selector',
    aircraftSelector: 'aircraft-selector',
    documentSelector: 'document-selector',
    expirationDatePicker: 'expiration-date-picker',
    expirationDate: 'expiration-date',
    expirationTag: 'expiration-tag',
    relatedEntityTypeFilter: 'related-entity-type-filter',
    filterByEntity: 'filter-by-entity',
    pilotEntitySelector: 'pilot-entity-selector',
    aircraftEntitySelector: 'aircraft-entity-selector',
    aircraftLogEntitySelector: 'aircraft-log-entity-selector',
    companyDocumentEntitySelector: 'company-document-entity-selector',
  },
};

export const ERRORS = {
  subpartSelector: 'Applicable Regulation(s) required',
  pilotSelectorDutySchedule: 'Select a pilot',
  pilotSelectorDutyTime: 'Select the pilot',
  timeRangeSelector: {
    enterEndDate: 'Enter an end date',
    startDateOutOfRange: 'Start date year is out of acceptable range.',
    endDateOutOfRange: 'End date year is out of acceptable range.',
    endDateMustBeAfterStartDate: 'Duty end must be after duty start',
    startDateIsRequired: 'Start date is required.',
  },
  overlapping: 'Entries are overlapping',
};

export const ACTIONS = {
  COPY: 'Copy',
  EDIT: 'Edit',
  CUT: 'Cut',
  PASTE: 'Paste',
};

export const versions = {
  DRAFT: 'Draft',
  V1: 'v1',
  SANDBOX: "autotest.sandbox sandbox's Sandbox-1",
};

export const travelScheduleType = 'Travel';
export const alertScheduleType = 'Alert';
export const travelScheduleTypeColor = '#A020F0';
export const alertScheduleTypeColor = '#000000';
