import { put } from 'redux-saga/effects';
import LocalCache from '../../../../../../local-storage/local-storage';
import { scheduleActions } from '../../actions';
import { TimelineType } from '../../../types/timeline';
import { selectUserRoleSaga, selectUserSaga } from '../../../../../../../common/saga/selectors';

export function* setSelectedTimelinesFromCache() {
  const user = yield* selectUserSaga();
  const localCache = new LocalCache('crewSchedulingV2', user);
  const userRoles = yield* selectUserRoleSaga();

  const selected = []
  if(userRoles.includes('subContractor')){
    selected.push(TimelineType.Aircraft)
  } else {
    selected.push( TimelineType.AircraftGroup,
      TimelineType.Aircraft,
      TimelineType.Crew,)

  }
  yield put(
    scheduleActions.setSelectedTimelines(
      localCache.getCached('selectedTimelines', selected)
    ),
  );
}
