import { put, call } from 'redux-saga/effects';
import {
  selectMXAircraftScheduleTLEntriesSaga,
} from '../../selectors-saga';
import { DeleteMXFromAircraftAction, scheduleActions } from '../../actions';
import {waitToFinishLoading} from "../timeline/waitToFinishLoading";

export function* handleDeleteMXFromAircraft({
  payload,
}: DeleteMXFromAircraftAction
) {
  yield put(scheduleActions.setState({operationsOngoing: true}));

  yield call(waitToFinishLoading);
  const existingEntries = yield* selectMXAircraftScheduleTLEntriesSaga();

  const updatedEntries = existingEntries.map((entry) => {
    const payloadStartTime = new Date(payload.deletedMXEntry?.StartTime).getTime();
    const entryStartTime = new Date(entry.StartTime).getTime();
    const payloadEndTime = new Date(payload.deletedMXEntry?.EndTime).getTime();
    const entryEndTime = new Date(entry.EndTime).getTime();
    if (entry.AssignedAircraftIDs.some((id) => payload.assignedAircraftIDs?.includes(id)) && entryStartTime === payloadStartTime && entryEndTime === payloadEndTime) {
      return {
        ...entry,
        AssignedUserIDs: entry.AssignedUserIDs.filter((id) => id != payload.deletedMXEntry?.UserID),
      }
    }
    return entry;
  });

  const filteredUpdatedEntries = updatedEntries.filter((entry) => entry.AssignedUserIDs.length != 0);

  yield put(scheduleActions.setState({ mxAircraftScheduleTimelineEntries: filteredUpdatedEntries }));
  yield put(scheduleActions.setState({operationsOngoing: false}));
}
