import { PusherActionType, PusherReduxAction } from '../../../pusher/PusherActions';
import { put, select } from 'redux-saga/effects';
import { getCertificateGroupsState } from '../../certificate-groups/selectors';
import { setCertificateGroupsAction } from '../../certificate-groups/actions';
import { CertificateGroup } from '../../../../pages/data/organization/tracked-items/types';

export function* certificateDeletedWorker(
  action: PusherReduxAction<PusherActionType.CERTIFICATE_DELETED>,
) {
  const { CertificateGroupIDs } = action.payload;

  const { certificateGroups } = yield select(getCertificateGroupsState);

  const updatedCertificateGroups = certificateGroups?.filter(
    (cg: CertificateGroup) => !CertificateGroupIDs?.includes(cg.ID),
  );

  yield put(setCertificateGroupsAction(updatedCertificateGroups));
}
