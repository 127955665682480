import {
    bulkMaintenanceModalEntryActions,
  DeleteMXGroupedEntryAction,
} from '../actions';
import { call, put } from 'redux-saga/effects';
import { scheduleActions } from '../../../../../redux-saga/actions';
import api from '../../../../../../../../../services/api';
import { selectCompanyIDSaga } from '../../../../../../../../../common/saga/selectors';
import MODAL_MODE from '../../../../../../../common/constants';

export function* handleDeleteMXGroupedEntry(action: DeleteMXGroupedEntryAction) {
  const companyID = yield* selectCompanyIDSaga();
  const { maintenanceScheduleIDs, relatedAircraftIDs } = action.payload;

  const payload = {
    MaintenanceScheduleEntryIds: maintenanceScheduleIDs,
  };

  try {
    yield call(api.post, `/v1/companies/${companyID}/maintenance-crew-schedule/bulk-delete`, payload);
    yield put(bulkMaintenanceModalEntryActions.setMXContractModalMode({ open: false, mode: MODAL_MODE.create }));
    yield put(bulkMaintenanceModalEntryActions.setMXAircraftModalMode({ open: false, mode: MODAL_MODE.create }));
    yield put(scheduleActions.reFetchMXAircraftScheduleEntries(relatedAircraftIDs || []));
    yield put(scheduleActions.setEditMXAircraftScheduleEntry({}));
  } catch (err) {
    console.log(err);
    yield put(
      bulkMaintenanceModalEntryActions.setSaveError({
        title: 'Could Not Save Maintenance Schedule Entry',
        message:
          'Please ensure this maintenance personnel is not already scheduled for this time. If this issue persists, contact support.',
      }),
    );
  } finally {
  }
}
