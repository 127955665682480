import { all, call, put } from 'redux-saga/effects';
import { scheduleActions } from '../../actions';
import { selectSelectedTimelinePeriodSaga } from '../../selectors-saga';
import { fetchTimelineEntries } from './fetchTimelineEntries';
import { CrewTimelineEntryType } from '../../../../../../../common/types/timeline/CrewTimelineEntryType';
import { AircraftTimelineEntryType } from '../../../../../../../common/types/timeline/AircraftTimelineEntryType';
import { selectCompanySettingsSaga } from '../../../../../../../common/saga/selectors';
import { ContractTimelineEntryType } from '../../../../../../../common/types/timeline/ContractTimelineEntryType';
import { MaintenanceTimelineEntryType } from '../../../../../../../common/types/timeline/MaintenanceTimelineEntryType';

export function* fetchAllEntries() {
  yield put(scheduleActions.setState({ isShowTimelineLoaded: true, allEntryTypesLoaded: false, isLoadingEntriesQuietly: true }));

  const timePeriod = yield* selectSelectedTimelinePeriodSaga();

  yield call(loadAll, timePeriod.getStart().format(), timePeriod.getEnd().format());
  yield put(scheduleActions.setState({
    allEntryTypesLoaded: true,
    isShowTimelineLoaded: false,
    isLoadingEntriesQuietly: false,
    loadedTimelineBufferPeriod: timePeriod
  }));

}

export function* loadAll(from: string, to: string) {
  const companySettings = yield* selectCompanySettingsSaga();

  const calls = [
    call(fetchTimelineEntries, from, to, CrewTimelineEntryType.PilotContract),
    call(fetchTimelineEntries, from, to, CrewTimelineEntryType.DutyTimes),
    call(fetchTimelineEntries, from, to, CrewTimelineEntryType.DutySchedule),
    call(fetchTimelineEntries, from, to, CrewTimelineEntryType.CrewSchedule),
    call(fetchTimelineEntries, from, to, AircraftTimelineEntryType.AircraftSchedule),
    call(fetchTimelineEntries, from, to, AircraftTimelineEntryType.MaintenanceAircraftSchedule),
    call(fetchTimelineEntries, from, to, AircraftTimelineEntryType.AircraftContract),
    call(fetchTimelineEntries, from, to, MaintenanceTimelineEntryType.MaintenanceCrew),
  ];

  if (companySettings.ContractSchedule) {
    calls.push(
      call(
        fetchTimelineEntries,
        from,
        to,
        ContractTimelineEntryType.ClientContractPeriod,
      ),
    );
  }

  if (companySettings.ScheduleTemplates) {
    calls.push(
      call(fetchTimelineEntries, from, to, AircraftTimelineEntryType.ScheduleTemplate),
    );
  }

  yield all(calls);
}
