import { setQualificationsAction, setQualificationUserRelationAction } from '../actions';
import { put, select } from 'redux-saga/effects';
import { getQualificationsState } from '../selectors';
import {
  Certificate,
  CertificateGroup,
  Qualification,
  QualificationUserRelation,
} from '../../../../pages/data/organization/tracked-items/types';
import { PusherActionType, PusherReduxAction } from '../../../pusher/PusherActions';
import { getCertificateState } from '../../certificates/selectors';
import { getCertificateGroupsState } from '../../certificate-groups/selectors';

export function* qualificationValidatedWorker(
  action: PusherReduxAction<PusherActionType.QUALIFICATION_VALIDATED>,
) {
  const {
    QualificationID,
    IsValid,
    QualificationCertificateGroupIds,
    QualificationCertificateIds,
    Name,
    UserID
  } = action.payload;
  const { qualifications, qualificationUserRelations } = yield select(getQualificationsState);
  const { certificates } = yield select(getCertificateState);
  const { certificateGroups } = yield select(getCertificateGroupsState);

  const updatedRelations = qualificationUserRelations?.map((ur: QualificationUserRelation) =>
    QualificationID === ur.QualificationID && ur.UserID === UserID
      ? {
        ...ur,
        IsValid,
      }
      : ur,
  );

  const updatedQualifications = qualifications?.map((q: Qualification) =>
    q.ID === QualificationID
      ? {
          ...q,
          Name,
          Certificates: certificates?.filter((c: Certificate) =>
            QualificationCertificateIds?.includes(c.ID),
          ),
          CertificateGroups: certificateGroups?.filter((cg: CertificateGroup) =>
            QualificationCertificateGroupIds?.includes(cg.ID),
          ),
        }
      : q,
  );

  yield put(setQualificationUserRelationAction(updatedRelations))
  yield put(setQualificationsAction(updatedQualifications));
}

export function* qualificationsValidatedWorker(
  action: PusherReduxAction<PusherActionType.QUALIFICATIONS_VALIDATED>,
) {
  const {
    QualificationsMap,
    QualificationIDs,
    QualificationsCertificateGroupIds,
    QualificationsCertificateIds,
    UserID,
  } = action.payload;

  const { qualifications, qualificationUserRelations } = yield select(getQualificationsState);

  const { certificates } = yield select(getCertificateState);
  const { certificateGroups } = yield select(getCertificateGroupsState);

  const updatedRelations = qualificationUserRelations?.map((ur: QualificationUserRelation) =>
    QualificationIDs?.includes(ur.QualificationID) && ur.UserID === UserID
      ? {
          ...ur,
          IsValid: QualificationsMap[ur.QualificationID],
        }
      : ur,
  );

  const updatedQualifications = qualifications?.map((q: Qualification) => {
    if (QualificationIDs?.includes(q.ID)) {
      return {
        ...q,
        Certificates: certificates?.filter((c: Certificate) =>
          QualificationsCertificateIds[q.ID]?.includes(c.ID),
        ),
        CertificateGroups: certificateGroups?.filter((cg: CertificateGroup) =>
          QualificationsCertificateGroupIds[q.ID]?.includes(cg.ID),
        ),
      };
    }
    return q;
  });

  yield put(setQualificationUserRelationAction(updatedRelations))
  yield put(setQualificationsAction(updatedQualifications));
}
