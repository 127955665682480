import {
  ApproveScheduleRequestAction,
  RejectScheduleRequestAction,
  scheduleTemplateModalActions,
} from '../actions';
import api from '../../../../../../../../../services/api';
import { put } from 'redux-saga/effects';
import MODAL_MODE from '../../../../../../../common/constants';
import { scheduleActions } from '../../../../../redux-saga/actions';

export function* approveSchedule(action: ApproveScheduleRequestAction) {
  yield scheduleAction(true, action.payload.scheduleTemplateID);
}

export function* rejectSchedule(action: RejectScheduleRequestAction) {
  yield scheduleAction(false, action.payload.scheduleTemplateID);
}

function* scheduleAction(isApprove: boolean, scheduleID: string) {
  try {
    const action = isApprove ? 'approve' : 'reject';
    const res = yield api.post(`/v1/schedule-template/booking/${scheduleID}/${action}`, {});
    yield put(scheduleActions.reFetchAllEntries());
    yield put(
      scheduleTemplateModalActions.setScheduleTemplateModalMode({
        mode: MODAL_MODE.create,
        open: false,
      }),
    );
  } catch (err) {
    console.error('approve/reject schedule err', err);
  }
}
