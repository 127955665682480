import actions from './actions';
import { ModalMode } from '../../../../../../../redux/pages/crew-scheduling-v3/modals/interfaces';
import MODAL_MODE from '../../../../../common/constants';
import { MTSDataType } from '../type';

export interface MTSScheduleState {
  modalMode: ModalMode;
  isLoading: boolean;
  currentMTSEntry: MTSDataType;
  currentRelatedCSEntryIDs: { [key: string]: string[] };
}

export const initialState: MTSScheduleState = {
  modalMode: { open: false, mode: MODAL_MODE.create },
  isLoading: false,
  currentMTSEntry: {notes: '', scenario: '', aircraftData: {}},
  currentRelatedCSEntryIDs: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
    case actions.SET_CURRENT_MTS_ENTRY:
    case actions.SET_CURRENT_RELATED_CS_ENTRY_IDS:
      return {
        ...state,
        ...action.payload,
      };
    case actions.SET_MODAL_OPEN:
      return { ...state, modalMode: { open: true, mode: action.payload.mode } };
    case actions.SET_MODAL_CLOSE:
      return { ...state, modalMode: { open: false, mode: MODAL_MODE.create } };
    default:
      return state;
  }
}
