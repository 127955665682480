import { all, call, put, takeEvery } from 'redux-saga/effects';
import api from '../../../services/api';
import actionTypes from './actions';
import commonActions from '../../common/actions';
import { uploadFileWorker } from '../../../pages/data/organization/company-library/redux-saga/workers/uploadFileWorker';
import { deleteFileWorker } from '../../../pages/data/organization/company-library/redux-saga/workers/deleteFileWorker';

export function* ADD_FILE_REQUEST({ payload }) {
  const { file, description, name, aircraftId, expirationDate, effectiveDate } = payload;
  const relatedEntity = [{ Type: 'aircraft', ID: aircraftId }];
  const formdata = new FormData();

  try {
    const res = yield call(uploadFileWorker, {
      payload: {
        currentFileID: null,
        file,
        fileClientName: name,
        notes: description,
        relatedEntities: relatedEntity,
        expirationDate,
        effectiveDate
      },
    });

    formdata.append('file', file);
    formdata.append(
      'json',
      JSON.stringify({
        Description: description,
        Name: name,
        FlightID: aircraftId,
        File: res?.data?.Data,
      }),
    );

    const response = yield call(
      api.post,
      `/v1/aircraft/${payload.aircraftId}/documents/${payload.documentId}`,
      formdata,
    );

    if (response.status === 200) {
      yield put({
        type: actionTypes.ADD_DOCUMENT_SUCCESS,
        payload: { newDocument: response.data, confirmSuccess: true },
      });
    }
  } catch (error) {
    const errorData = error?.response?.data;
    yield put({ type: actionTypes.ADD_DOCUMENT_ERROR });
    yield put(
      commonActions.setNotifire({
        type: 'error',
        message: `Error ${errorData?.Code}`,
        description: errorData?.Message,
      }),
    );
  }
}

export function* DELETE_DOCUMENT_REQUEST({ payload }) {
  try {
    const response = yield call(
      api.delete,
      `/v1/aircraft/${payload.aircraftId}/documents/${payload.documentId}`,
    );

    yield call(deleteFileWorker, { payload: { fileID: payload.relatedFile.ID } });
    if (response.status === 204) {
      yield put({
        type: actionTypes.DELETE_DOCUMENT_SUCCESS,
        payload: { isDocumentDeleted: true, documentId: payload.documentId },
      });
    }
  } catch (error) {
    const errorData = error?.response?.data;
    yield put({ type: actionTypes.DELETE_DOCUMENT_ERROR });
    yield put(
      commonActions.setNotifire({
        type: 'error',
        message: `Error ${errorData?.Code}`,
        description: errorData?.Message,
      }),
    );
  }
}

export function* EDIT_DOCUMENT_REQUEST({ payload }) {
  const { description, name, aircraftId, relatedFile, expirationDate, effectiveDate } = payload;
  const relatedEntity = [{ Type: 'aircraft', ID: aircraftId }];

  try {
    const res = yield call(uploadFileWorker, {
      payload: {
        currentFileID: relatedFile.ID,
        file: null,
        fileClientName: name,
        notes: description,
        relatedEntities: relatedEntity,
        expirationDate,
        effectiveDate
      },
    });

    const data = {
      Name: name,
      Description: description,
      File: res?.data?.Data,
    };
    const response = yield call(
      api.patch,
      `/v1/aircraft/${aircraftId}/documents/${payload.documentId}/meta`,
      data,
    );

    if (response.status === 200) {
      yield put({
        type: actionTypes.EDIT_DOCUMENT_SUCCESS,
        payload: { isDocumentEdited: true, updatedDocument: response.data.Data },
      });
    }
  } catch (error) {
    const errorData = error?.response?.data;
    yield put({ type: actionTypes.EDIT_DOCUMENT_ERROR });
    yield put(
      commonActions.setNotifire({
        type: 'error',
        message: `Error ${errorData?.Code}`,
        description: errorData?.Message,
      }),
    );
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actionTypes.ADD_FILE_REQUEST, ADD_FILE_REQUEST),
    takeEvery(actionTypes.DELETE_DOCUMENT_REQUEST, DELETE_DOCUMENT_REQUEST),
    takeEvery(actionTypes.EDIT_DOCUMENT_REQUEST, EDIT_DOCUMENT_REQUEST),
  ]);
}
