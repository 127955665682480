import { ModalMode } from '../crew-scheduling-v3/modals/interfaces';
import MODAL_MODE from '../../../pages/data/common/constants';
import actions from './actions';
import {
  Qualification,
  QualificationUserRelation,
} from '../../../pages/data/organization/tracked-items/types';

export interface QualificationModalInitialState {
  qualifications: Qualification[];
  qualificationUserRelations: QualificationUserRelation[];
  modalMode: ModalMode;
  filterName: string;
  filterQualifications: Qualification[];
  qualificationStructure: Record<string, any[]>;
  expandedQualificationId: string;
}

const initialState: QualificationModalInitialState = {
  modalMode: { open: false, mode: MODAL_MODE.create },
  qualifications: [],
  qualificationUserRelations: [],
  filterName: null,
  filterQualifications: [],
  qualificationStructure: {},
  expandedQualificationId: null,
};

export default function reducers(state: QualificationModalInitialState = initialState, action) {
  switch (action.type) {
    case actions.OPEN_MODAL:
      return { ...state, modalMode: { open: true, mode: action.payload.mode } };
    case actions.CLOSE_MODAL:
      return { ...state, modalMode: { open: false, mode: MODAL_MODE.create } };
    case actions.SET_QUALIFICATIONS:
    case actions.SET_FILTER_QUALIFICATIONS:
    case actions.SET_QUALIFICATION_USER_RELATIONS:
    case actions.SET_FILTER_NAME:
    case actions.SET_EXPANDED_QUALIFICATION_ID:
    case actions.SET_QUALIFICATIONS_STRUCTURE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
