import { AnyAction } from 'redux';
import { PreferredVersion } from '../../../../../../../common/types/preferredVersoon';
import { PublishedVersion } from '../../../../../../../common/types/publishedVersion';
import actions from './actions';
import { RecentlyPublishedEntry } from '../../../../../../../common/types/timeline/RecentlyPublishedEntry';
import { CompanyScheduleVersion } from '../../../../../../../common/types/scheduleVersion';
import { Sandbox } from '../../../../../../../common/types/sandbox';

export interface VersioningState {
  unpublishedEntries: string[];
  isVersionSwitching: boolean;
  preferredVersion: PreferredVersion;
  previousPreferredVersion: PreferredVersion;
  publishedVersions: PublishedVersion[];
  publishedVersionsRaw: {
    Data: PublishedVersion[];
    Count: number;
    Total: number;
    Page: number;
  };
  recentlyPublishedEntries: RecentlyPublishedEntry[];
  isSyncing: boolean;
  versionSwitching: boolean;
  reviewChangesModalVisibility: boolean;
  shareSandboxModalVisibility: boolean;
  versionChangeLogModalVisibility: boolean
  satelliteVisibility: boolean;
  currentSandboxVersion: number | null;
  latestSandboxVersion: number | null;
  companyScheduleVersion: CompanyScheduleVersion | null;
  sandboxList: Sandbox[]
}

const initialState: VersioningState = {
  unpublishedEntries: [],
  isVersionSwitching: false,
  preferredVersion: 'current',
  previousPreferredVersion: 'current',
  publishedVersions: [],
  publishedVersionsRaw: {
    Data: [],
    Count: 0,
    Total: 0,
    Page: 0,
  },
  recentlyPublishedEntries: [],
  isSyncing: false,
  versionSwitching: false,
  satelliteVisibility: false,
  reviewChangesModalVisibility: false,
  versionChangeLogModalVisibility: false,
  currentSandboxVersion: null,
  latestSandboxVersion: null,
  companyScheduleVersion: null,
  shareSandboxModalVisibility: false,
  sandboxList: []
};

export default function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case actions.SET_STATE:
    case actions.SET_COMPANY_SCHEDULE_VERSION:
    case actions.SET_RECENTLY_PUBLISHED_ENTRIES:
      return { ...state, ...action.payload };
    case actions.ADD_PUBLISHED_VERSION:
      if (state.publishedVersions === null) {
        return { ...state, publishedVersions: [action.payload] };
      }
      return { ...state, publishedVersions: [action.payload, ...state.publishedVersions] };
    default:
      return state;
  }
}
