export const INTERVAL_TYPES = { MONTH: 'MONTH', YEAR: 'YEAR', DEFAULT_YEAR_INTERVAL: '1st DAY OF 13th MONTH' };

export type IntervalType = typeof INTERVAL_TYPES[keyof typeof INTERVAL_TYPES];
export type Interval = {
  [key in IntervalType]?: number[];
};

export const STATUS_OPTIONS = [
  { value: 'Complete', label: 'Complete' },
  { value: 'Incomplete', label: 'Incomplete' },
  { value: 'Rejected', label: 'Rejected' },
];

export const COMPLETE_STATUS = 'Complete';
export const INCOMPLETE_STATUS = 'Incomplete';
export const PENDING_STATUS = 'Pending';
export const REJECTED_STATUS = 'Rejected';
export const EXPIRED_STATUS = 'Expired';

export type STATUS =
  | typeof COMPLETE_STATUS
  | typeof INCOMPLETE_STATUS
  | typeof PENDING_STATUS
  | typeof REJECTED_STATUS
  | typeof EXPIRED_STATUS;

export const PENDING_STATUS_STRUCT = { value: PENDING_STATUS, label: 'Pending review' };
export const EXPIRED_STATUS_STRUCT = { value: EXPIRED_STATUS, label: EXPIRED_STATUS };

export const INTERVAL_TYPE_OPTIONS = [
  { label: 'MONTH', value: 'MONTH' },
  { label: 'YEAR', value: 'YEAR' },
  { label: '1st DAY OF 13th MONTH', value: '1st DAY OF 13th MONTH' },
];
export const MONTH_INTERVAL = 'MONTH';
export const YEAR_INTERVAL = 'YEAR';
export const DEFAULT_YEAR_INTERVAL = '1st DAY OF 13th MONTH';
