import { call, put, select } from 'redux-saga/effects';
import { getSandboxList } from '../selectors';
import api from '../../../../../../../../services/api';
import { getUser } from '../../../../../../../../redux/selectors';
import actions, { versioningActions } from '../actions';
import scheduleActions from '../../../../redux-saga/actions';

export function* HANDLE_SHARED_SANDBOX_UPDATE(action) {
  const { sandboxID, companyID } = action.payload;
  const sandboxList = yield select(getSandboxList);
  const currentUser = yield select(getUser);
  const sharedSandboxes = yield call(
    api.get,
    `/v1/companies/${companyID}/crew-schedule-sandbox/shared-sandboxes`,
    {
      params: { user_id: currentUser?.id },
    },
  );

  const newSandboxes = sharedSandboxes?.data?.filter(
    sharedSandbox =>
      !sandboxList.some(sandbox => sandbox.ID === sharedSandbox.CrewScheduleSandbox.ID),
  );

  const sandboxesToRemove = sandboxList.filter(
    sandbox =>
      sandbox.ID === sandboxID &&
      sandbox.UserID !== currentUser.id &&
      !sharedSandboxes?.data?.some(
        sharedSandbox => sharedSandbox.CrewScheduleSandbox.ID === sandboxID,
      ),
  );

  let updatedSandboxList = sandboxList;

  // Add new sandboxes if any
  if (newSandboxes.length > 0) {
    updatedSandboxList = [
      ...updatedSandboxList,
      ...newSandboxes.map(cs => {
        cs.CrewScheduleSandbox.IsShared = true;
        cs.CrewScheduleSandbox.IsOwnSchedule = cs.IsOwnSchedule;
        return cs.CrewScheduleSandbox;
      }),
    ];
  }

  if (sandboxesToRemove.length > 0) {
    updatedSandboxList = updatedSandboxList.filter(
      sandbox => !sandboxesToRemove.some(toRemove => toRemove.ID === sandbox.ID),
    );
  }

  if (
    newSandboxes.length > 0 ||
    sandboxesToRemove.length > 0 ||
    updatedSandboxList.length !== sandboxList.length
  ) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        sandboxList: updatedSandboxList,
      },
    });

    const currentViewingVersion = localStorage.getItem('currentViewingVersion');
    if (sandboxesToRemove.find(s => s.Name === currentViewingVersion)) {
      yield put(versioningActions.switchToPreferredVersion('latest'));
    }
  } else if (updatedSandboxList.length === sandboxList.length && sharedSandboxes?.data?.length > 0) {

    const updatedSandboxDetails = sandboxList
      .filter(sl => sharedSandboxes.data.map(s => s.SandboxID).includes(sl.ID))
      .map(us => {
        const matchingSharedSandbox = sharedSandboxes.data.find(s => s.SandboxID === us.ID);
        us.IsOwnSchedule = matchingSharedSandbox ? matchingSharedSandbox.IsOwnSchedule : false;
        return us;
      });

    updatedSandboxList = updatedSandboxList.map(s => {
      const matchingDetails = updatedSandboxDetails.find(ud => ud.ID === s.ID);
      if (matchingDetails) {
        return {
          ...s,
          IsOwnSchedule: matchingDetails.IsOwnSchedule,
        };
      }
      return s;
    });

    yield put({
      type: actions.SET_STATE,
      payload: {
        sandboxList: updatedSandboxList,
      },
    });
  }

  if (sharedSandboxes?.data.length > 0) {
    const currentSandbox = sharedSandboxes?.data.filter(
      s => s.CrewScheduleSandbox.ID === sandboxID,
    );
    if (currentSandbox.length === 1) {
      const entry = currentSandbox.map(s => {
        s.CrewScheduleSandbox.IsShared = true;
        s.CrewScheduleSandbox.IsOwnSchedule = s.IsOwnSchedule;
        return s.CrewScheduleSandbox;
      })[0];

      yield put({
        type: scheduleActions.SET_STATE,
        payload: {
          currentSharedSandbox: entry,
        },
      });

      localStorage.setItem('currentSandbox', JSON.stringify(entry));
    }
  }
}
