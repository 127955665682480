import { all, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import scheduleActions from '../../../redux-saga/actions';
import { switchToPreferredVersion } from './workers/switchToPreferredVersion';
import { publishVersion } from './workers/publishVersion';
import { loadPublishedVersionWorker } from './workers/loadPublishedVersion';
import { onSchedulePageLoadedWorker } from './workers/onSchedulePageLoadedWorker';
import { PusherActionType } from '../../../../../../../redux/pusher/PusherActions';
import { HANDLE_SHARED_SANDBOX_UPDATE } from './workers/sharedSandboxUpdate';

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SWITCH_TO_PREFERRED_VERSION, switchToPreferredVersion),
    takeEvery(actions.PUBLISH_VERSION, publishVersion),
    takeEvery(actions.LOAD_PUBLISHED_VERSIONS, loadPublishedVersionWorker),
    takeEvery(scheduleActions.ON_PAGE_LOADED, onSchedulePageLoadedWorker),
    takeEvery(PusherActionType.SHARED_SANDBOX_UPDATE, HANDLE_SHARED_SANDBOX_UPDATE),
  ]);
}
