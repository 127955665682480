import { SaveScheduleTemplateAction, scheduleTemplateModalActions } from '../actions';
import { put } from 'redux-saga/effects';
import api from '../../../../../../../../../services/api';
import MODAL_MODE from '../../../../../../../common/constants';
import { scheduleActions } from '../../../../../redux-saga/actions';
import { SaveScheduleTemplateRequest } from '../types';

export function* saveScheduleTemplate(action: SaveScheduleTemplateAction) {
  try {
    const { scheduleTemplateID } = action.payload;
    const request: SaveScheduleTemplateRequest = {
      ...action.payload.scheduleTemplate,
    };
    yield api.put(`/v1/schedule-template/${scheduleTemplateID}`, request);

    yield put(
      scheduleTemplateModalActions.setScheduleTemplateModalMode({
        mode: MODAL_MODE.create,
        open: false,
      }),
    );
    yield put(scheduleActions.reFetchAllEntries());
  } catch (e) {
    console.error('save schedule err', e);
  }
}
