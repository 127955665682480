import React, { useEffect } from 'react';
import { BackTop, Layout, notification } from 'antd';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'redux/common/actions';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import TopBar from 'components/layout-components/TopBar';
import Menu from 'components/layout-components/Menu';
import Footer from 'components/layout-components/Footer';
import { getCompanySettings, getUser } from 'redux/selectors';
import { getNotifire, getSettings } from '../../redux/selectors';
import { FilterDrawer } from '../../pages/data/organization/crew-scheduling-v3/components/FilterDrawer/FilterDrawer';
import { getIsNoFilterDrawer } from '../../pages/data/organization/crew-scheduling-v3/redux-saga/selectors';

const MainLayout = withRouter(props => {
  const { children } = props;
  const dispatch = useDispatch();
  const settings = useSelector(getSettings);
  const noFilterDrawer = useSelector(getIsNoFilterDrawer);
  const { isBorderless, isSquaredBorders, isFixedWidth, isMenuShadow } = settings;
  const notifire = useSelector(getNotifire);
  const companySettings = useSelector(getCompanySettings);

  const user = useSelector(getUser);

  const renderNotifire = data => {
    notification[data.type]({
      message: data.message,
      description: data?.description,
      onClose: () => dispatch(Actions.clearNotifire()),
    });
  };

  useEffect(() => {
    if (notifire) {
      renderNotifire(notifire);
    }
  }, [notifire]);

  return (
    <Layout
      className={classNames({
        settings__borderLess: isBorderless,
        settings__squaredBorders: isSquaredBorders,
        settings__fixedWidth: isFixedWidth,
        settings__menuShadow: isMenuShadow,
      })}
    >
      <BackTop />
      <Menu />
      <Layout>
        {!isMobile && user.Company?.Settings?.NewSidebar === false && (
          <Layout.Header>
            <TopBar />
          </Layout.Header>
        )}
        <Layout.Content
          style={{
            height: companySettings?.DisableVerticalTimelineScrolling
              ? 'calc(100vh - 64px)'
              : '100%',
            overflow: companySettings?.DisableVerticalTimelineScrolling ? 'auto' : 'unset',
            position: 'relative',
          }}
        >
          {/* <Breadcrumbs /> */}
          <div className="utils__content">{children}</div>
        </Layout.Content>
        {!isMobile && (
          <Layout.Footer>
            <Footer />
          </Layout.Footer>
        )}
      </Layout>
      {!noFilterDrawer && <FilterDrawer />}
    </Layout>
  );
});

export default MainLayout;
