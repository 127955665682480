import MODAL_MODE from '../../../../../pages/data/common/constants';
import { LogReview, ReviewType } from '../logReview';
import { GenericAction } from '../../../../../common/types/redux/actions';
import { actionTypes } from '../../../../../redux/pages/aircraft-log/actions';

export const actions = {
  SET_STATE: 'crew-scheduling-v3/ReviewModal/SET_STATE',
  OPEN_MODAL: 'crew-scheduling-v3/ReviewModal/OPEN_MODAL',
  CLOSE_MODAL: 'crew-scheduling-v3/ReviewModal/CLOSE_MODAL',
  SET_REVIEW_LIST: 'crew-scheduling-v3/ReviewModal/SET_REVIEW_LIST',
  SET_BATCH_REVIEW_LIST: 'crew-scheduling-v3/ReviewModal/SET_BATCH_REVIEW_LIST',
  SET_REVIEW_TYPES: 'crew-scheduling-v3/ReviewModal/SET_REVIEW_TYPES',
  SET_CURRENT_REVIEW: 'crew-scheduling-v3/ReviewModal/SET_CURRENT_REVIEW',
  GET_REVIEW_LIST: 'crew-scheduling-v3/ReviewModal/GET_REVIEW_LIST',
  GET_REVIEW_TYPES: 'crew-scheduling-v3/ReviewModal/GET_REVIEW_TYPES',
};

export interface OpenReviewModalAction {
  type: typeof actions.OPEN_MODAL;
  payload: { mode: MODAL_MODE };
}

export const openReviewModalAction = (
  mode: MODAL_MODE,
): OpenReviewModalAction => ({
  type: actions.OPEN_MODAL,
  payload: { mode },
});

export const closeReviewModalAction = () => {
    return {
    type: actions.CLOSE_MODAL,
  };
};

export interface fetchReviews extends GenericAction {
  payload: { aircraftLogID: string }
}

export const fetchReviews = (aircraftLogID: string): fetchReviews => {
  return {
    type: actionTypes.FETCH_LOG_REVIEWS,
    payload: { aircraftLogID },
  };
};

export interface fetchReviewTypes extends GenericAction {
  payload: { companyID: string }
}

export const fetchReviewTypes = (companyID: string): fetchReviewTypes => {
  return {
    type: actionTypes.FETCH_LOG_REVIEW_TYPES,
    payload: { companyID },
  };
};

export const setStateAction = payload => ({
  type: actions.SET_STATE,
  payload,
});

export const getReviewList = (state: any): LogReview[] => {
  return state?.pages?.reviewModal?.logReviewList || [];
};

export const getReviewTypes = (state: any): ReviewType[] => {
  return state?.pages?.reviewModal?.reviewTypes || [];
};

export const getCurrentReview = (state: any): LogReview => {
  return state?.pages?.reviewModal?.currentReview || null;
};

export const setCurrentReview = (currentReview: LogReview) => {
  return {
    type: actions.SET_CURRENT_REVIEW,
    payload: {
      currentReview,
    },
  };
};

export type SetReviewList = {
  type: typeof actions.SET_REVIEW_LIST;
  payload: {
    logReviewList: LogReview[];
  };
};


export const setReviewList = (logReviewList: LogReview[]): SetReviewList => {
  return {
    type: actions.SET_REVIEW_LIST,
    payload: {
      logReviewList,
    },
  };
};


export const setReviewTypes = (reviewTypes: ReviewType[]): SetReviewTypes => {
  return {
    type: actions.SET_REVIEW_TYPES,
    payload: {
      reviewTypes,
    },
  };
};

export type SetReviewTypes = {
  type: typeof actions.SET_REVIEW_TYPES;
  payload: {
    reviewTypes: ReviewType[];
  };
};

