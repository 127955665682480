import { ModalMode } from '../crew-scheduling-v3/modals/interfaces';
import MODAL_MODE from '../../../pages/data/common/constants';
import actions from './actions';
import { Certificate } from '../../../pages/data/organization/tracked-items/types';

export interface CertificateModalInitialState {
  modalMode: ModalMode;
  certificates: Certificate[]
  filterName: string
  filterReference: string
}

const initialState: CertificateModalInitialState = {
  modalMode: { open: false, mode: MODAL_MODE.create },
  certificates: [],
  filterName: null,
  filterReference: null
};

export default function reducers(state: CertificateModalInitialState = initialState, action) {
  switch (action.type) {
    case actions.OPEN_MODAL:
      return { ...state, modalMode: { open: true, mode: action.payload.mode } };
    case actions.CLOSE_MODAL:
      return { ...state, modalMode: { open: false, mode: MODAL_MODE.create } };
    case actions.SET_CERTIFICATES:
    case actions.SET_FILTER_REFERENCE:
    case actions.SET_FILTER_NAME:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
