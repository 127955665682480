const getTimeString = totalMinutes => {
  let timeString = '';
  const totalDays = totalMinutes / (24 * 60);
  const totalHours = Math.floor(totalMinutes / 60);
  if (totalMinutes != null && totalMinutes >= 0) {
    const days = Math.floor(totalDays);
    const hours = Math.round(totalHours % 24);
    const minutes = Math.round(totalMinutes % 60);

    if (days >= 2) {
      // display day count once total hours is greater than 48
      timeString += `${days} ${days === 1 ? 'day' : 'days'} `;
    }

    if (days >= 2 && hours > 0) {
      timeString += `${hours} ${hours === 1 ? 'hr' : 'hrs'} `;
    } else if (days < 2 && totalHours > 0) {
      timeString += `${totalHours} ${totalHours === 1 ? 'hr' : 'hrs'} `;
    }

    if (days < 2 && minutes > 0) {
      // show minutes if number of days is less than 2
      timeString += `${minutes} min`;
    }
  } else {
    // console.error("Total minutes passed in the function is null")
    return '';
  }
  return timeString;
};

export default getTimeString;
