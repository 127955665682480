import { call, put, delay } from 'redux-saga/effects';
import { scheduleActions, SetTimelineBufferPeriodAction } from '../../actions';
import { loadAll } from "../timelineEntries/fetchAllEntries";

export function* watchTimelineBufferResize(action: SetTimelineBufferPeriodAction) {
  yield delay(100); // for debouncing purposes

  const { timelineBufferPeriod } = action.payload;
  yield put(scheduleActions.setState({ isLoadingEntriesQuietly: true }));


  const [from, to] = [
    timelineBufferPeriod?.
      getStart()?.
      startOf('day').
      format(),
    timelineBufferPeriod?.
      getEnd()?.
      endOf('day').
      format(),
  ];

  yield call(loadAll, from, to);
  yield put(scheduleActions.setState({ isLoadingEntriesQuietly: false, loadedTimelineBufferPeriod: timelineBufferPeriod }));
}
