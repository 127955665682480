import React from 'react';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { getCompanyClients, getIsDefaultCompanyClientsLoaded } from '../../../redux/selectors';
const { Option } = Select;

interface CompanyClientSelectorProps {
  value?: string[];
  id?: string;
  onChange?: (value: string[]) => void;
  disabled?: boolean;
  allowClear?: boolean;
  showSearch?: boolean;
  mode: 'multiple' | 'tags' | 'single';
  placeholder: string;
}

export const CompanyClientSelector: React.FC<CompanyClientSelectorProps> = ({
  value,
  id,
  onChange,
  disabled = false,
  mode = 'multiple',
  placeholder = 'Select the client',
  allowClear = true,
  showSearch = true,
}) => {
  const companyClients = useSelector(getCompanyClients);
  const isDefaultCompanyClientsLoaded = useSelector(getIsDefaultCompanyClientsLoaded);

  return (
    <Select
      id={id}
      {...(mode !== 'single' ? { mode: mode } : {})}
      disabled={disabled}
      placeholder={placeholder}
      loading={!isDefaultCompanyClientsLoaded}
      filterOption={(input, option) => {
        return typeof option.children === 'string'
          ? (option.children as string).toLowerCase().indexOf(input.toLowerCase()) !== -1
          : false;
      }}
      onChange={clientIds => {
        onChange && onChange(clientIds);
      }}
      value={value}
      allowClear={allowClear}
      showSearch={showSearch}
      listHeight={320}
    >
      {companyClients.map(c => (
        <Option key={c.ID} value={c.ID}>
          {c.Name}
        </Option>
      ))}
    </Select>
  );
};
