import { createSelector } from 'reselect';
import { Company } from '../../common/types/Company';
import { UserState } from './reducers';
import { CompanyUser } from '../../common/types/pilot';

export const selectCompanySettings = createSelector(
  (state: any) => state.user.Company as Company,
  Company => Company.Settings,
);

export const selectCompanyID = createSelector(
  (state: any) => state.user.Company as Company,
  Company => Company.ID as Company['ID'],
);

export const selectUser = createSelector(
  (state: any) => state.user,
  user => user as UserState,
);

export const selectCompanyUser = createSelector(
  (state: any) => state.user,
  user => user?.CompanyUsers?.[0] as CompanyUser | null,
);

export const getUserRole = createSelector(
  (state: any) => state.user,
  (user: UserState) => user.CompanyUsers?.[0]?.Roles,
);

export const getAuthUserID = createSelector(
  (state: any) => state.user,
  (user: UserState) => user.id,
);
