import { HandleMenuItemClickAction, scheduleActions } from '../../actions';
import { MenuItemType } from '../../../types/MenuItemTypes';
import { call, put, select } from 'redux-saga/effects';
import { handleDeleteEntry } from './handleDeleteEntry';
import { AircraftTimelineItem } from '../../../components/Timeline/AircraftTimeline/generator';
import { selectUserSaga } from '../../../../../../../common/saga/selectors';
import LocalCache from '../../../../../../local-storage/local-storage';
import { CrewTimelineItem } from '../../../components/Timeline/CrewTimeline/generator';
import { AircraftTimelineEntryType } from '../../../../../../../common/types/timeline/AircraftTimelineEntryType';
import { ContractTimelineItem } from '../../../components/Timeline/ContractTimeline/generator';
import { CommonInitState } from '../../../../../../../redux/common/reducers';
import { getCommon, getCompanyID } from '../../../../../../../redux/selectors';
import { AircraftSelectorType } from '../../../../../../../redux/common/types';
import { ContractTimelineEntryType } from '../../../../../../../common/types/timeline/ContractTimelineEntryType';
import { MaintenanceTimelineItem } from '../../../components/Timeline/MaintenanceTimeline/generator';
import { handleUnassignEntry } from './handleUnassignEntry';
import { handleBulkDelete } from './handlePasteTimelineItems';
import { TimelineType } from '../../../types/timeline';
import { getAircraftScheduleTimelineEntries } from '../../selectors';
import { fetchAircraftScheduleEntry, fetchEntry } from '../timelineEntries/fetchEntry';
import { AircraftSchedule } from '../../../../../../../common/types/aircraftSchedule';
import api from '../../../../../../../services/api';
import { CrewTimelineEntryType } from '../../../../../../../common/types/timeline/CrewTimelineEntryType';
import { message } from 'antd';
import {waitToFinishLoading} from "../timeline/waitToFinishLoading";

export function* handleMenuItemClickWorker(action: HandleMenuItemClickAction) {
  yield put(scheduleActions.setState({operationsOngoing: true}));

  yield call(waitToFinishLoading);

  const { item, selectedItems, menuItem, timelineType } = action.payload;
  const companyID = yield select(getCompanyID);

  if (menuItem === MenuItemType.Delete) {
    yield call(handleDeleteEntry, item, timelineType);
  } else if (menuItem === MenuItemType.Edit || menuItem === MenuItemType.View) {
    yield put(scheduleActions.handleTimelineItemDoubleClick(item, timelineType));
  } else if (menuItem === MenuItemType.Cut) {
    yield put(
      scheduleActions.setCutItem(
        item as CrewTimelineItem | AircraftTimelineItem | MaintenanceTimelineItem,
      ),
    );
    yield put(scheduleActions.setCopyItem(null));
    yield put(scheduleActions.setBulkCopyItems(null));
    yield put(scheduleActions.setBulkCutItems(null));
  } else if (menuItem === MenuItemType.Copy) {
    yield put(
      scheduleActions.setCopyItem(
        item as CrewTimelineItem | AircraftTimelineItem | MaintenanceTimelineItem,
      ),
    );
    yield put(scheduleActions.setCutItem(null));
    yield put(scheduleActions.setBulkCopyItems(null));
    yield put(scheduleActions.setBulkCutItems(null));
  } else if (menuItem === MenuItemType.BulkCut && selectedItems && selectedItems.length > 0) {
    yield put(
      scheduleActions.setBulkCutItems(selectedItems as (CrewTimelineItem | AircraftTimelineItem)[]),
    );
    yield put(scheduleActions.setBulkCopyItems([]));
    yield put(scheduleActions.setCopyItem(null));
    yield put(scheduleActions.setCutItem(null));
  } else if (menuItem === MenuItemType.BulkCopy && selectedItems && selectedItems.length > 0) {
    yield put(
      scheduleActions.setBulkCopyItems(
        selectedItems as (CrewTimelineItem | AircraftTimelineItem)[],
      ),
    );
    yield put(scheduleActions.setBulkCutItems([]));
    yield put(scheduleActions.setCopyItem(null));
    yield put(scheduleActions.setCutItem(null));
  } else if (menuItem === MenuItemType.BulkDelete && selectedItems && selectedItems.length > 0) {
    yield call(handleBulkDelete, selectedItems, timelineType);
    timelineType === TimelineType.Crew
      ? yield put(scheduleActions.setSelectedItems([]))
      : yield put(scheduleActions.setSelectedAircraftItems([]));
  } else if (menuItem === MenuItemType.ShowAssociatedCrew) {
    const user = yield* selectUserSaga();
    const localCache = new LocalCache('crewSchedulingV2', user);
    let assignedUserIDs: string[] = [];
    if (item.entryType === AircraftTimelineEntryType.AircraftSchedule) {
      const entry = (item as AircraftTimelineItem).aircraftEntry;
      assignedUserIDs = entry.AssignedUserIDs;
    } else if (item.entryType === ContractTimelineEntryType.PilotSchedule) {
      const entry = item as ContractTimelineItem;
      if (entry.userID) {
        assignedUserIDs = [entry.userID];
      } else if (entry.userIDs) {
        assignedUserIDs = entry.userIDs;
      }
    }

    yield put(scheduleActions.setSelectedCrewMemberIds(assignedUserIDs));

    localCache.setCached('selectedCrewMemberIds', assignedUserIDs);
  } else if (menuItem === MenuItemType.ShowAssociatedGroupEntries) {
    const user = yield* selectUserSaga();
    const localCache = new LocalCache('crewSchedulingV2', user);
    let assignedUserIDs: string[] = [];
    const aircraftEntries = yield select(getAircraftScheduleTimelineEntries);
    if (item.entryType === AircraftTimelineEntryType.AircraftSchedule) {
      const entry = (item as AircraftTimelineItem).aircraftEntry;
      aircraftEntries
        .filter(a => a.CrewScheduleGroupID === entry.CrewScheduleGroupID)
        .forEach(a => {
          assignedUserIDs = [...assignedUserIDs, ...a.AssignedUserIDs];
        });
    }

    yield put(scheduleActions.setSelectedCrewMemberIds(assignedUserIDs));
    localCache.setCached('selectedCrewMemberIds', assignedUserIDs);
  } else if (menuItem === MenuItemType.RemoveFromGroup) {
    if (item.entryType === AircraftTimelineEntryType.AircraftSchedule) {
      try {
        yield put(scheduleActions.setTimelineLoading(true))
        let fullASEntry = (yield fetchAircraftScheduleEntry(
          item.entryType,
          item,
          false,
        )) as AircraftSchedule;

        for (const entry of fullASEntry.RelatedCrewScheduleEntries) {
          const fullCSEntry = yield fetchEntry(CrewTimelineEntryType.CrewSchedule, entry.ID, false);

          fullCSEntry.CrewScheduleGroupID = null;
          yield call(
            api.patch,
            `/v1/companies/${companyID}/crewschedule/update/${fullCSEntry.ID}`,
            fullCSEntry,
          );
        }
        message.success('Removed aircraft entry from the group');
      } catch (e) {
        console.error(e);
        message.error('Failed to remove aircraft entry from the group');
      } finally {
        yield put(scheduleActions.setTimelineLoading(false))
      }
    }
  } else if (menuItem === MenuItemType.ShowAssociatedAircraft) {
    let assignedAircraftIDs = [];

    if (item.entryType === ContractTimelineEntryType.AircraftContract) {
      const entry = item as ContractTimelineItem;
      if (entry.aircraftID) {
        assignedAircraftIDs = [entry.aircraftID];
      } else if (entry.aircraftIDs) {
        assignedAircraftIDs = entry.aircraftIDs;
      }
      yield put(scheduleActions.setSelectedAircraftIdentifiers(assignedAircraftIDs));
    } else {
      const aircraftIDs = (item as ContractTimelineItem).aircraftIDs;
      if (aircraftIDs) {
        assignedAircraftIDs = aircraftIDs;
      }
    }
    const commonState: CommonInitState = yield select(getCommon);
    const aircraftTails = commonState.aircraftList
      .filter(({ ID }) => assignedAircraftIDs.includes(ID))
      .map(aircraft => {
        if (commonState.aircraftSelectorType === AircraftSelectorType.Registration) {
          return aircraft.Registration;
        }
        return aircraft.TailNumber;
      });
    yield put(scheduleActions.setSelectedAircraftIdentifiers(aircraftTails));
  }
  if (menuItem === MenuItemType.UnassignContract || menuItem === MenuItemType.UnassignAircraft) {
    if (isValidTimelineItem(item)) {
      yield call(handleUnassignEntry, item, menuItem);
    }
  }

  yield put(scheduleActions.setState({operationsOngoing: false}));
}

function isValidTimelineItem(
  item: any,
): item is CrewTimelineItem | AircraftTimelineItem | ContractTimelineItem {
  return (
    'entryType' in item &&
    (item.entryType === ContractTimelineEntryType.AircraftContract ||
      item.entryType === ContractTimelineEntryType.PilotSchedule)
  );
}
