import MODAL_MODE from '../../../pages/data/common/constants';
import { ModalMode } from '../crew-scheduling-v3/modals/interfaces';
import actions from './actions';
import { Certificate, TrainingItem } from '../../../pages/data/organization/tracked-items/types';
import { RcFile } from 'antd/lib/upload/interface';
import { UploadFile } from 'antd';
import { INCOMPLETE_STATUS } from '../../../pages/data/organization/tracked-items/constants';

export interface TrainingItemModalInitialState {
  modalMode: ModalMode;
  isFilterDrawerVisible: boolean;
  isFilterDrawerIconVisible: boolean;
  filterCertificateIDs: string[];
  filterAircraftTypes: string[];
  filterUserID: string;
  filterSelectedStatus: string;
  filterDueDate: string[];
  filterCompletionDate: string[];
  filterCreationDate: string[];
  filterEffectiveDate: string[];
  filterQualificationIDs: string[];
  selectedTrackedItemID: string;
  trackedItemPreviousVersion: string;
  trackedItemNextVersion: string;
  defaultCertificate: Certificate;
  selectedInterval: string;
  selectedIntervalType: string;
  selectedIntervalTypeOptions: { label: string; value: string }[];
  intervalOptions: { label: number; value: string }[];
  selectedCertificateID: string;
  completedAt: string;
  completedByID: string;
  completedBy: string;
  rejectedAt: string;
  rejectedByID: string;
  rejectedBy: string;
  trackedItemDueDate: string;
  previousTrackedItemDueDate: string;
  selectedTrackedItemStatus: string;
  defaultSelectedItemStatus: string;
  instructorID: string;
  duration: string;
  result: string;
  effectiveDate: string;
  meansOfCompliance: string;
  selectedAircraft: string[];
  selectedUserID: string;
  notes: string;
  submittedAt: string;
  submittedBy: string;
  defaultFile: UploadFile[];
  trackedItemsLoading: boolean;
  trackedItems: TrainingItem[];
  file: RcFile[];
  selectedUser: string;
  customCompletionDate: string;
  previousTrackedItemVersionsTableOpen: boolean;
  selectedFiles: string[];
  pagination: { current: number; pageSize: number; total: number };
  sorter: { field: string; order: string };
  editCertificateDefaultView: boolean;
  createdAt: string;
  createdBy: string;
  updatedBy: string;
  largePreviewOpen: boolean;
  isDeletionComplete: boolean;
}

const initialState: TrainingItemModalInitialState = {
  modalMode: { open: false, mode: MODAL_MODE.create },
  isFilterDrawerVisible: false,
  isFilterDrawerIconVisible: false,
  filterCertificateIDs: [],
  filterUserID: null,
  filterSelectedStatus: null,
  filterDueDate: null,
  filterCompletionDate: null,
  filterCreationDate: null,
  filterEffectiveDate: null,
  filterQualificationIDs: [],
  filterAircraftTypes: null,
  selectedTrackedItemID: null,
  trackedItemPreviousVersion: null,
  trackedItemNextVersion: null,
  defaultCertificate: null,
  selectedInterval: null,
  selectedIntervalType: null,
  selectedIntervalTypeOptions: null,
  intervalOptions: null,
  selectedCertificateID: null,
  completedAt: null,
  completedByID: null,
  rejectedAt: null,
  rejectedByID: null,
  trackedItemDueDate: null,
  previousTrackedItemDueDate: null,
  selectedTrackedItemStatus: INCOMPLETE_STATUS,
  defaultSelectedItemStatus: null,
  instructorID: null,
  duration: null,
  result: null,
  effectiveDate: null,
  meansOfCompliance: null,
  selectedAircraft: null,
  selectedUserID: null,
  notes: null,
  submittedAt: null,
  submittedBy: null,
  defaultFile: null,
  completedBy: null,
  rejectedBy: null,
  trackedItemsLoading: false,
  trackedItems: [],
  file: null,
  selectedUser: null,
  customCompletionDate: null,
  previousTrackedItemVersionsTableOpen: false,
  selectedFiles: [],
  pagination: { current: 1, pageSize: 10, total: 0 },
  sorter: { field: null, order: null },
  editCertificateDefaultView: true,
  createdAt: null,
  createdBy: null,
  updatedBy: null,
  isDeletionComplete: true,
  largePreviewOpen: false,
};

export default function reducers(state: TrainingItemModalInitialState = initialState, action) {
  switch (action.type) {
    case actions.OPEN_MODAL:
      return { ...state, modalMode: { open: true, mode: action.payload.mode } };
    case actions.CLOSE_MODAL:
      return { ...state, modalMode: { open: false, mode: MODAL_MODE.create } };
    case actions.SET_STATE:
    case actions.SET_IS_FILTER_DRAWER_VISIBLE:
    case actions.SET_IS_FILTER_DRAWER_ICON_VISIBLE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
