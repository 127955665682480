import {
  PusherActionType,
  PusherReduxAction,
} from '../../../../../../../../redux/pusher/PusherActions';
import { fetchEntry } from '../../timelineEntries/fetchEntry';
import { CrewTimelineEntryType } from '../../../../../../../../common/types/timeline/CrewTimelineEntryType';
import { scheduleActions } from '../../../actions';
import { put, select } from 'redux-saga/effects';
import {
  selectCrewScheduleTimelineEntriesSaga,
  selectPreferredVersionSaga,
} from '../../../selectors-saga';
import { CrewScheduleTimelineEntry } from '../../../../../../../../common/types/timeline/CrewScheduleTimelineEntry';
import { getRouter } from '../../../../../../../../redux/selectors';
import { RouterState } from 'connected-react-router';
import { isIgnoreUpdate } from '../helpers';

export function* handleCrewScheduleEntryUpdate(
  action: PusherReduxAction<PusherActionType.CREW_SCHEDULE_ENTRY_UPDATED>,
) {
  const { location } = (yield select(getRouter)) as RouterState;
  if (!location.pathname.includes('crew-scheduling-v3')) {
    return;
  }

  try {
    const { payload } = action;

    const { CrewScheduleSandboxID } = payload;
    const preferredVersion = yield* selectPreferredVersionSaga();
    if (isIgnoreUpdate(preferredVersion, CrewScheduleSandboxID)) {
      return;
    }

    const entry = (yield fetchEntry(
      CrewTimelineEntryType.CrewSchedule,
      payload.ID,
      true,
    )) as CrewScheduleTimelineEntry;

    const existedEntries = yield* selectCrewScheduleTimelineEntriesSaga();
    const entryToDelete = existedEntries.find(e => e.ID === payload.ID)
    let updatedAircraftIDs: string[] = [];
    const updatedEntries = existedEntries.map(crewScheduleEntry => {
      if (crewScheduleEntry.ID === entry.ID) {
        updatedAircraftIDs = updatedAircraftIDs.concat(entry.AssignedAircraftIDs);
        return entry;
      }
      return crewScheduleEntry;
    });

    yield put(scheduleActions.setState({ crewScheduleTimelineEntries: updatedEntries }));
    yield put(scheduleActions.deleteCrewFromAircraft({ assignedAircraftIDs: entryToDelete?.AssignedAircraftIDs, deletedCrewEntry: entryToDelete }))
    yield put(scheduleActions.reFetchAircraftScheduleEntries(updatedAircraftIDs));
  } finally {
  }
}
