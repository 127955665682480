import {
  PusherActionType,
  PusherReduxAction,
} from '../../../../../../../redux/pusher/PusherActions';
import { put, select } from 'redux-saga/effects';
import { getRouter } from '../../../../../../../redux/selectors';
import { RouterState } from 'connected-react-router';
import { scheduleActions } from '../../actions';
export function* handleScheduleTemplateUpdate(
  action: PusherReduxAction<PusherActionType.SCHEDULE_TEMPLATE_UPDATED>,
) {
  const { location } = (yield select(getRouter)) as RouterState;
  if (!location.pathname.includes('crew-scheduling-v3')) {
    return;
  }

  yield put(scheduleActions.reFetchAllEntries());
}
