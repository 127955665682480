import React from 'react';
import { DatePicker, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentView, getIsDrawerVisible, getIsFilterDrawerPinned, getJumpToDate } from '../../redux-saga/selectors';
import { scheduleActions } from '../../redux-saga/actions';
import { TimelineView } from '../../types/timeline';
import { ButtonSelectable } from './ButtonSelectable';
import { IDs } from '../../../../../../constants';

export const PeriodPicker = () => {
  const dispatch = useDispatch();
  const jumpToDate = useSelector(getJumpToDate);
  const currentView = useSelector(getCurrentView);
  const isDrawerVisible = useSelector(getIsDrawerVisible);
  const isFilterPinned = useSelector(getIsFilterDrawerPinned);

  // TODO: init me
  const loading = false;

  return (
    <>
      <Row style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="ant-btn-group" style={{ marginBottom: '12px', marginRight: '12px' }}>
          <ButtonSelectable
            id={IDs.dateSelectors.daySelector}
            disabled={loading}
            onClick={() => {
              dispatch(scheduleActions.switchToView(TimelineView.Day));
            }}
            selected={currentView === TimelineView.Day}
          >
            Day
          </ButtonSelectable>

          <ButtonSelectable
            id={IDs.dateSelectors.weekSelector}
            disabled={loading}
            onClick={() => {
              dispatch(scheduleActions.switchToView(TimelineView.Week));
            }}
            selected={currentView === TimelineView.Week}
          >
            Week
          </ButtonSelectable>

          <ButtonSelectable
            id={IDs.dateSelectors.monthSelector}
            disabled={loading}
            onClick={() => {
              dispatch(scheduleActions.switchToView(TimelineView.Month));
            }}
            selected={currentView === TimelineView.Month}
          >
            Month
          </ButtonSelectable>

          <ButtonSelectable
            id={IDs.dateSelectors.threeMonthSelector}
            disabled={loading}
            onClick={() => {
              dispatch(scheduleActions.switchToView(TimelineView.ThreeMonths));
            }}
            selected={currentView === TimelineView.ThreeMonths}
          >
            3-Month
          </ButtonSelectable>

          <ButtonSelectable
            id={IDs.dateSelectors.yearSelector}
            disabled={loading}
            onClick={() => {
              dispatch(scheduleActions.switchToView(TimelineView.Year));
            }}
            selected={currentView === TimelineView.Year}
          >
            Year
          </ButtonSelectable>
        </div>

        {!isDrawerVisible && (
          <div>
            <DatePicker
              id={IDs.dateSelectors.jumpToDate}
              placeholder="Jump to date"
              value={jumpToDate}
              onChange={date => {
                dispatch(scheduleActions.jumpToDate(date));
              }}
            />
          </div>
        )}
      </Row>
      {isDrawerVisible && (
        <div>
          <DatePicker
            id={IDs.dateSelectors.jumpToDate}
            placeholder="Jump to date"
            value={jumpToDate}
            onChange={date => {
              dispatch(scheduleActions.jumpToDate(date));
              if (!isFilterPinned) {
                dispatch(scheduleActions.setIsDrawerVisible(false))
              }
            }}
            style={{ width: "100%" }}
          />
        </div>
      )}
    </>
  );
};
