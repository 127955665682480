import React, { useEffect, useState } from 'react';
import { Button, Cascader, Form, Select } from 'antd';
import HasAccess from '../../../../../permissions/guard-access-component';
import Guard from '../../../../../permissions/crew-schedule-v2-guard';
import { useDispatch, useSelector } from 'react-redux';
import LocalCache from '../../../../../local-storage/local-storage';
import { CascaderOptions, SingleValueType } from '../types';
import {
  getMaintenance,
  getMaintenanceCertifications,
  getMaintenanceMemberBases,
  getSelectedMaintenanceMemberIDs,
} from '../../redux-saga/selectors';
import { scheduleActions } from '../../redux-saga/actions';
import { IDs } from '../../../../../../constants';
import { CrewScheduleFilter } from '../../../../../../common/constants/filters';
import { MaintenanceUser } from 'common/types/maintenance';

const { Option } = Select;

interface Props {}

export const MaintenanceSelector: React.FC<Props> = ({}) => {
  const maintenanceCertifications = useSelector(getMaintenanceCertifications);
  const selectedMaintenanceMemberIds = useSelector(getSelectedMaintenanceMemberIDs);
  const maintenanceMemberBases = useSelector(getMaintenanceMemberBases);
  const maintenanceCrew = useSelector(getMaintenance);

  const localCache = new LocalCache('crewSchedulingV2');

  const [selectedMaintenanceCertification, setSelectedMaintenanceCertification] = useState(
    localCache.getCached('selectedMaintenanceCertification', []),
  );

  const guard = new Guard('crewScheduleV2');
  const dispatch = useDispatch();

  const [selectedMaintenance, setSelectedMaintenance] = useState([]);
  const [unselectedMaintenance, setUnselectedMaintenance] = useState([]);

  const [selectedBases, setSelectedBases] = useState(
    localCache.getCached('selectedMaintenanceMemberBases', []),
  );

  const getMaintenanceScheduleMaintenanceCrew = (): MaintenanceUser[] => {
    return maintenanceCrew.filter(maintenance => maintenance.Profile.ShowInMXSchedule);
  };

  useEffect(() => {
    const filteredMaintenance = getMaintenanceScheduleMaintenanceCrew();
    // Update selected and unselected options when allOptions or selectedValues changes
    const newSelectedMaintenance = filteredMaintenance.filter(opt =>
      selectedMaintenanceMemberIds.includes(opt.ID),
    );
    const newUnselectedMaintenance = filteredMaintenance.filter(
      opt => !selectedMaintenanceMemberIds.includes(opt.ID),
    );

    setSelectedMaintenance(newSelectedMaintenance);
    setUnselectedMaintenance(newUnselectedMaintenance);
  }, [maintenanceCrew, selectedMaintenanceMemberIds]);

  const showSearchFilter = (inputValue: string, path: CascaderOptions[]) => {
    return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  };

  const setCertifications = (certifications: SingleValueType[]) => {
    setSelectedMaintenanceCertification(certifications);
    localCache.setCached('selectedMaintenanceCertification', certifications);
  };

  const onSelectMaintenance = (maintenanceIdArray: string[]) => {
    // Update the certifications
    setCertifications([]);
    // Dispatch action to update selected maintenance
    dispatch(scheduleActions.setSelectedMaintenanceMemberIds(maintenanceIdArray));
    localCache.setCached('selectedMaintenanceMemberIds', maintenanceIdArray);
    setMaintenanceMemberBases([]);
  };

  const onSelectMaintenanceCertification = (certification: SingleValueType[]) => {
    const filteredMaintenance = getMaintenanceScheduleMaintenanceCrew();
    // Transform the selected certifications into a more easily comparable format
    const newCertifications = new Set(
      certification.map(c => {
        if (c.length === 1) {
          // AircraftType
          return c[0];
        } else {
          return `${c[0]}-${c[1]}`;
        }
      }),
    );

    const selected = new Set<string>(selectedMaintenanceMemberIds);

    for (const maintenance of filteredMaintenance) {
      let hasMatchingCertification = false;

      for (const cert of maintenance.Profile?.AMEAircraftCertifications || []) {
        console.log(newCertifications, 'newCertifications');
        if (newCertifications.has(cert.AircraftType)) {
          hasMatchingCertification = true;
          break;
        }

        if (newCertifications.has(`${cert.AircraftType}-${cert.Permissions[0]}`)) {
          hasMatchingCertification = true;
          break;
        }
      }

      if (hasMatchingCertification) {
        selected.add(maintenance.ID);
      } else {
        // Check if the maintenance should be removed
        const hasAnySelectedCertification = (
          maintenance.Profile?.AMEAircraftCertifications || []
        ).some(cert => {
          if (newCertifications.has(cert.AircraftType)) {
            return true;
          }

          return newCertifications.has(`${cert.AircraftType}-${cert.Permissions[0]}`);
        });

        if (!hasAnySelectedCertification) {
          selected.delete(maintenance.ID);
        }
      }
    }

    dispatch(scheduleActions.setSelectedMaintenanceMemberIds([...selected]));
    setCertifications(certification);
    setMaintenanceMemberBases([]);
  };

  const setMaintenanceMemberBases = (selectedBases: string[]) => {
    setSelectedBases(selectedBases);
    localCache.setCached('selectedMaintenanceMemberBases', selectedBases);
  };

  const onSelectMaintenanceMemberBases = (baseAirportArray: string[]) => {
    setMaintenanceMemberBases(baseAirportArray);
    setCertifications([]);

    if (baseAirportArray.length === 0) {
      dispatch(scheduleActions.setSelectedMaintenanceMemberIds([]));
      return;
    }

    const filteredMaintenanceIDs = getMaintenanceScheduleMaintenanceCrew()
      .filter(maintenance => baseAirportArray.includes(maintenance.Profile.BaseAirportCode))
      .map(maintenance => maintenance.ID);

    dispatch(scheduleActions.setSelectedMaintenanceMemberIds(filteredMaintenanceIDs));
  };

  return (
    <>
      <HasAccess access={guard.canAccessFilter(CrewScheduleFilter.MAINTENANCE_MEMBER)}>
        <Form.Item
          label="AME Members"
          style={{ marginRight: '20px', flexGrow: 1, flexBasis: 0 }}
        >
          <Select
            id={IDs.filters.maintenanceMemberSelector}
            filterOption={(input, option) => {
              if (Array.isArray(option.children)) {
                return (
                  option.children
                    .join('')
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) !== -1
                );
              }
              return (option.children as string).toLowerCase().indexOf(input.toLowerCase()) !== -1;
            }}
            mode="multiple"
            onChange={maintenanceIdArray => {
              onSelectMaintenance(maintenanceIdArray);
            }}
            maxTagCount="responsive"
            placeholder="Select AME Members"
            allowClear
            value={selectedMaintenanceMemberIds}
            dropdownRender={menu => (
              <div>
                <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                  <Button
                    onClick={() => {
                      const maintenanceIdArray = getMaintenanceScheduleMaintenanceCrew().map(
                        m => m.ID,
                      );
                      dispatch(scheduleActions.setSelectedMaintenanceMemberIds(maintenanceIdArray));
                      localCache.setCached('selectedMaintenanceMemberIds', maintenanceIdArray);
                    }}
                  >
                    Select all
                  </Button>
                </div>
                {menu}
              </div>
            )}
          >
            {[...selectedMaintenance, ...unselectedMaintenance].map(p => (
              <Option key={p.ID} value={p.ID}>
                {p.Profile.FirstName} {p.Profile.LastName}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </HasAccess>

      <HasAccess access={guard.canAccessFilter(CrewScheduleFilter.MAINTENANCE_CERTIFICATION)}>
        <Form.Item
          label="AME Certifications"
          style={{ marginRight: '20px', flexGrow: 1, flexBasis: 0 }}
        >
          <Cascader
            id={IDs.filters.maintenanceCertificationSelector}
            options={maintenanceCertifications || []}
            expandTrigger="hover"
            maxTagCount="responsive"
            placeholder="Select AME Certifications"
            multiple
            showSearch={{ filter: showSearchFilter }}
            value={selectedMaintenanceCertification}
            onChange={certification => {
              onSelectMaintenanceCertification(certification);
            }}
          />
        </Form.Item>

        <Form.Item
          label="AME Member Base"
          style={{ marginRight: '20px', flexGrow: 1, flexBasis: 0 }}
        >
          <Select
            id={IDs.filters.maintenanceMemberBaseSelector}
            filterOption={(input, option) => {
              if (Array.isArray(option.children)) {
                return (
                  option.children
                    .join('')
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) !== -1
                );
              }
              return (option.children as string).toLowerCase().indexOf(input.toLowerCase()) !== -1;
            }}
            mode="multiple"
            onChange={baseAirportArray => {
              onSelectMaintenanceMemberBases(baseAirportArray);
            }}
            maxTagCount="responsive"
            allowClear
            placeholder="Select AME Member Base"
            value={selectedBases}
            dropdownRender={menu => (
              <div>
                <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                  <Button
                    onClick={() => {
                      onSelectMaintenanceMemberBases(maintenanceMemberBases);
                    }}
                  >
                    Select all
                  </Button>
                </div>
                {menu}
              </div>
            )}
          >
            {maintenanceMemberBases &&
              maintenanceMemberBases.map(airport => (
                <Option key={airport} value={airport}>
                  {airport}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </HasAccess>
    </>
  );
};
