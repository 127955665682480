import { TablePaginationConfig } from 'antd';
import { FileStruct, RelatedEntity, RelatedEntityType, Folder } from '../types/file';
import actions from './actions';
import { AircraftLogMinimal } from '../../../../../common/types/aircraftLog';

export interface FileFilters {
  fileName: string;
  relatedEntityTypes: RelatedEntityType[];
  relatedEntities: RelatedEntity[];
}

export interface CompanyLibraryState {
  isLoadingList: boolean;
  loadMoreAvailable: boolean;
  fileList: FileStruct[];
  folderList: Folder[];
  pagination: TablePaginationConfig;
  allRelations: RelatedEntity[];
  allRelationsLoaded: boolean;
  relatedLogs: AircraftLogMinimal[];
  filters: FileFilters;
  uploadModalFilters: FileFilters
  selectedFile: FileStruct | null;
  isFolderModalOpen: boolean;
  isDetailsModalOpen: boolean;
  currentFolderID: string;
  currentDetailsFolderID: string;
  detailsFolderList: Folder[];
  detailsFolderListLoader: boolean;
  selectedFoldersStack: Folder[];
}

export const initialState: CompanyLibraryState = {
  isLoadingList: false,
  fileList: [],
  folderList: [],
  pagination: {
    total: 0,
    current: 1,
    pageSize: 50,
  },
  loadMoreAvailable: false,
  allRelationsLoaded: false,
  allRelations: [],
  relatedLogs: [],
  filters: {
    fileName: '',
    relatedEntityTypes: [],
    relatedEntities: [],
  },
  uploadModalFilters: {
    fileName: '',
    relatedEntityTypes: [],
    relatedEntities: [],
  },
  selectedFile: null,
  isFolderModalOpen: false,
  isDetailsModalOpen: false,
  currentFolderID: null,
  currentDetailsFolderID: null,
  detailsFolderList: [],
  detailsFolderListLoader: false,
  selectedFoldersStack: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
    case actions.SET_ALL_RELATIONS:
    case actions.SET_SELECTED_FILE:
      return { ...state, ...action.payload };
    case actions.SET_FILE_LIST:
      return {
        ...state,
        fileList: action.payload.list,
        loadMoreAvailable: action.payload.loadMoreAvailable,
        // isLoading: false,
      };
    case actions.SET_FOLDER_LIST:
      return {
        ...state,
        folderList: action.payload.list,
        loadMoreAvailable: action.payload.loadMoreAvailable,
      };
    case actions.FILE_NAME_CHANGE_FILTER:
    case actions.RELATED_ENTITY_TYPES_CHANGE_FILTER:
    case actions.RELATED_ENTITIES_CHANGE_FILTER:
      const filters = { ...state.filters, ...action.payload };
      return { ...state, filters };
    case actions.RELATED_ENTITIES_CHANGE_UPLOAD_MODAL_FILTER:
      const modalFilters = { ...state.filters, ...action.payload };
      return { ...state, modalFilters };
    case actions.OPEN_NEW_FOLDER_MODAL:
      return { ...state, isFolderModalOpen: true };
    case actions.CLOSE_NEW_FOLDER_MODAL:
      return { ...state, isFolderModalOpen: false };
    case actions.OPEN_DETAILS_MODAL:
      return { ...state, isDetailsModalOpen: true };
    case actions.CLOSE_DETAILS_MODAL:
      return { ...state, isDetailsModalOpen: false };
    case actions.SET_CURRENT_FOLDER_ID:
      return { ...state, ...action.payload};
    case actions.SET_CURRENT_DETAILS_FOLDER_ID:
      return { ...state, ...action.payload};
    case actions.SET_DETAILS_FOLDER_LIST:
      return { ...state, ...action.payload};
    default:
      return state;
  }
}
