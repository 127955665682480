import { bulkMaintenanceModalEntryActions, SaveMXGroupedEntryAction } from '../actions';
import { put } from 'redux-saga/effects';
import { scheduleActions } from '../../../../../redux-saga/actions';
import api from '../../../../../../../../../services/api';
import { selectCompanyIDSaga } from '../../../../../../../../../common/saga/selectors';
import MODAL_MODE from '../../../../../../../common/constants';
import { normalizeDateSeconds } from '../../../../../../../../../common/helpers/daysCalc';
import moment from 'moment-timezone';
import { BulkMaintenanceScheduleModalValues } from '../../../../types';
import { UpdateMaintenanceScheduleBulkRequest } from '../types';
import { BulkMaintenanceScheduleGroupedByField } from '../../Base/types';

export function* handleSaveMXGroupedEntry(action: SaveMXGroupedEntryAction) {
  const { formValues, mode, groupedBy } = action.payload;

  const companyID = yield* selectCompanyIDSaga();

  yield put(bulkMaintenanceModalEntryActions.setSaveError(null));

  if (formValues.MaintenanceShifts.length > 0) {
    const requests = [];
    const aircraftToFetch = [];

    formValues.MaintenanceShifts.forEach(shift => {
      const payload = serializeEntry(shift);

      if (payload.AircraftID) {
        aircraftToFetch.push(payload.AircraftID);
      }

      if (shift?.AssignedAircraftID) {
        aircraftToFetch.push(shift.AssignedAircraftID);
      }

      requests.push(api.patch(`/v1/companies/${companyID}/maintenance-crew-schedule/bulk-update`, payload));
    });

    if (requests) {
      try {
        yield put(bulkMaintenanceModalEntryActions.setIsSaving(true));
        yield Promise.all(requests);
        if (groupedBy === BulkMaintenanceScheduleGroupedByField.Aircraft) {
          yield put(
            bulkMaintenanceModalEntryActions.setMXAircraftModalMode({ open: false, mode: MODAL_MODE.create }),
          );
        } else if (groupedBy === BulkMaintenanceScheduleGroupedByField.ClientContract) {
          yield put(
            bulkMaintenanceModalEntryActions.setMXContractModalMode({ open: false, mode: MODAL_MODE.create }),
          );
        }

        yield put(scheduleActions.reFetchMXAircraftScheduleEntries(aircraftToFetch));
        yield put(scheduleActions.setEditMXAircraftScheduleEntry({}));
      } catch (err) {
        console.log(err);
        yield put(
          bulkMaintenanceModalEntryActions.setSaveError({
            title: 'Could Not Save Maintenance Schedule Entry',
            message:
              'Please ensure this maintenance personnel is not already scheduled for this time. If this issue persists, contact support.',
          }),
        );
      } finally {
        yield put(bulkMaintenanceModalEntryActions.setIsSaving(false));
      }
    }
  }
}

const serializeEntry = (values: BulkMaintenanceScheduleModalValues): UpdateMaintenanceScheduleBulkRequest => {
  const endTime = normalizeDateSeconds(moment(values.SchedulePeriod?.[1]));

  return {
    Notes: values.Notes,
    UserIDs: values.UserIDs,
    MaintenanceScheduleEntryIds: values.MaintenanceScheduleEntryIDs,
    StartTime: moment(values.SchedulePeriod?.[0])
      .seconds(0)
      .format(),
    EndTime: endTime.format(),
    AircraftID: values.AssignedAircraftID,
    CompanyScheduleTypeID: values.CompanyScheduleEntryTypeID,
    ClientContractID: values.ClientContractID,
  };
};
