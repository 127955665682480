import { setState, UploadFileAction } from '../actions';
import api from '../../../../../../services/api';
import { call, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import * as Sentry from '@sentry/react';
import { notification } from 'antd';
import { RelatedEntityType } from '../../types/file';

export function* uploadFileWorker(action: UploadFileAction) {
  const { file, fileClientName, notes, currentFileID, relatedEntities, expirationDate, effectiveDate, currentFolderID } = action.payload;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('FileClientName', fileClientName);
  formData.append('Notes', notes);
  formData.append('ExpirationDate', expirationDate);
  formData.append('EffectiveDate', effectiveDate);
  formData.append("CurrentFolderId", currentFolderID)
  if (!relatedEntities[0].Type) {
    formData.append('RelatedEntities', null);
  } else if (relatedEntities[0].Type === RelatedEntityType.CompanyDocument) {
    relatedEntities[0].ID = null;
    formData.append('RelatedEntities', JSON.stringify(relatedEntities));
  } else {
    formData.append('RelatedEntities', JSON.stringify(relatedEntities));
  }
  yield put(setState({ isLoadingList: true }));

  try {
    let response: AxiosResponse;
    if (currentFileID) {
      if (file) {
        response = yield call(api.patch, `/v1/company-library/${currentFileID}`, formData);
      } else {
        response = yield call(api.patch, `/v1/company-library/details/${currentFileID}`, formData);
      }
    } else {
      response = yield call(api.post, `/v1/company-library`, formData);
    }

    if (response.status === 200 && currentFileID) {
      const updatedFile = response.data.Data;
      yield put(setState({ selectedFile: updatedFile }));
    }
    yield call(updateRelations);
    return response;
  } catch (error) {
    Sentry.captureMessage(`Error uploading file: ${error}`);
    notification.error({
      message: 'Error',
      description: 'There was an error uploading the file. Please try again or contact support.',
    });
  } finally {
    yield put(setState({ isLoadingList: false }));
  }
}

export function* updateRelations() {
  const relationsResponse = yield call(api.get, `/v1/company-library/relations`);
  if (relationsResponse.status === 200) {
    let updatedRelations = [];
    if (relationsResponse.data.Data !== null) {
      updatedRelations = relationsResponse.data.Data;
    }
    yield put(setState({ allRelations: updatedRelations }));
  }
}
