import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { getIsDefaultCompanyClientsLoaded } from '../../../redux/selectors';
import { getClientContracts } from '../../../redux/common/selectors';
import { RelatedEntity } from '../../../pages/data/organization/company-library/types/file';

const { Option } = Select;

interface Props {
  value?: string | string[];
  id?: string;
  onChange?: (value: string | string[]) => void;
  disabled?: boolean;
  allowClear?: boolean;
  showSearch?: boolean;
  mode: 'multiple' | 'tags' | 'single';
  placeholder?: string;
  className?: string;
  style?: React.CSSProperties;
  defaultValue?: RelatedEntity[];
  filterBy?: string[] | undefined;
}

export const ClientContractSelector: React.FC<Props> = ({
  value,
  id,
  onChange,
  disabled = false,
  mode = 'single',
  placeholder,
  allowClear = true,
  showSearch = true,
  className,
  style,
  defaultValue,
  filterBy,
}) => {
  const clientContracts = useSelector(getClientContracts);
  const isDefaultCompanyClientsLoaded = useSelector(getIsDefaultCompanyClientsLoaded);
  const [internalValue, setInternalValue] = useState<string | string[] | null>(null);

  useEffect(() => {
    if (defaultValue && defaultValue.length > 0 && clientContracts && clientContracts.length > 0) {
      const filteredContract = clientContracts.filter(c => c.ID === defaultValue[0].ID);
      if (filteredContract.length > 0) {
        if (onChange) {
          onChange(filteredContract[0].ID);
        }
        setInternalValue(filteredContract[0].ID);
      }
    }
  }, [defaultValue, clientContracts]);

  useEffect(() => {
    if (!defaultValue) {
      setInternalValue(value);
    }
  }, [value]);

  const handleChange = (value: string) => {
    setInternalValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  const handleClear = () => {
    setInternalValue(null);
    if (onChange) {
      onChange(null);
    }
  };

  return (
    <Select
      style={style}
      id={id}
      className={className}
      maxTagCount="responsive"
      {...(mode !== 'single' ? { mode: mode } : {})}
      disabled={disabled}
      {...(placeholder && { placeholder })}
      loading={!isDefaultCompanyClientsLoaded}
      filterOption={(input, option) => {
        return typeof option.children === 'string'
          ? (option.children as string).toLowerCase().indexOf(input.toLowerCase()) !== -1
          : false;
      }}
      onChange={handleChange}
      onClear={handleClear}
      value={internalValue === null ? [] : internalValue}
      allowClear={allowClear}
      showSearch={showSearch}
      listHeight={320}
    >
      {clientContracts
        .filter(c => (filterBy ? filterBy.includes(c.ID) : true))
        .map(c => (
          <Option key={c.ID} value={c.ID}>
            {c.Name}
          </Option>
        ))}
    </Select>
  );
};
