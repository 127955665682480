const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  SET_USER: 'user/SET_USER',
  SET_USER_REVIEWER_TYPES: 'user/SET_USER_REVIEWER_TYPES',
  SET_COMPANY: 'user/SET_COMPANY',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOAD_CURRENT_COMPANY: 'user/LOAD_CURRENT_COMPANY',
  LOGOUT: 'user/LOGOUT',
};

export default actions;
