import MODAL_MODE from '../../../pages/data/common/constants';
import { GenericAction } from '../../../common/types/redux/actions';
import { TrainingItemModalInitialState } from './reducers';

const actions = {
  SET_STATE: 'pages/training-items/SET_STATE',
  OPEN_MODAL: 'pages/training-items/OPEN_MODAL',
  CLOSE_MODAL: 'pages/training-items/CLOSE_MODAL',
  SET_IS_FILTER_DRAWER_VISIBLE: 'pages/training-items/SET_IS_FILTER_DRAWER_VISIBLE',
  SET_IS_FILTER_DRAWER_ICON_VISIBLE: 'pages/training-items/SET_IS_FILTER_DRAWER_ICON_VISIBLE',
};

export interface SetStateAction extends GenericAction {
  payload: Partial<TrainingItemModalInitialState>;
}

export interface OpenTrainingItemModalAction {
  type: typeof actions.OPEN_MODAL;
  payload: { mode: MODAL_MODE };
}

export interface CloseTrainingItemModalAction {
  type: typeof actions.CLOSE_MODAL;
}

export interface IsFilterDrawerVisibleAction {
  type: typeof actions.SET_IS_FILTER_DRAWER_VISIBLE;
  payload: { isFilterDrawerVisible: boolean };
}

export interface IsFilterDrawerIconVisibleAction {
  type: typeof actions.SET_IS_FILTER_DRAWER_ICON_VISIBLE;
  payload: { isFilterDrawerIconVisible: boolean };
}

export const setState = (payload: Partial<TrainingItemModalInitialState>): SetStateAction => ({
  type: actions.SET_STATE,
  payload,
});

export const openTrainingItemModalAction = (mode: MODAL_MODE): OpenTrainingItemModalAction => ({
  type: actions.OPEN_MODAL,
  payload: { mode },
});

export const closeTrainingItemModalAction = (): CloseTrainingItemModalAction => ({
  type: actions.CLOSE_MODAL,
});

export const setIsFilterDrawerVisible = (
  isFilterDrawerVisible: boolean,
): IsFilterDrawerVisibleAction => ({
  type: actions.SET_IS_FILTER_DRAWER_VISIBLE,
  payload: { isFilterDrawerVisible },
});

export const setIsFilterDrawerIcon = (
  isFilterDrawerIconVisible: boolean,
): IsFilterDrawerIconVisibleAction => ({
  type: actions.SET_IS_FILTER_DRAWER_ICON_VISIBLE,
  payload: { isFilterDrawerIconVisible },
});

export default actions;
